import React from "react";
import { CustomOrderProps } from "../type";
import memoize from "@RHCommerceDev/utils/memoize";
import { usePageContent } from "@RHCommerceDev/custom-providers/LocationProvider";

const CustomOrder: React.FC<CustomOrderProps> = ({ onClick, className }) => {
  const { pageContent } = usePageContent();
  return (
    <span
      id={"component-pdp-cta_custom_order"}
      data-testid={"component-pdp-cta_custom_order"}
      className={`underline underline-offset-[2px] cursor-pointer ${className}`}
      aria-label={pageContent?.Click_to_view_order}
      onClick={onClick}
    >
      {pageContent?.Custom_Order}
    </span>
  );
};

export default memoize(CustomOrder);
