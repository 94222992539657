import React, { FC } from "react";
import loadable from "@loadable/component";
import AEMWrapper from "aem/ComponentWrapper";
import { AEMComponentProps } from "aem/types";

export type RHSPAIncludeProps = AEMComponentProps & {
  pagePath?: string | "";
};

const RHSPAInclude = loadable(
  () => import(/* webpackChunkName: "component-rh-spa-include" */ "."),
  {
    fallback: <></>
  }
);

export const AEMRHSPAInclude: FC<RHSPAIncludeProps> = props => {
  return <RHSPAInclude pagePath={""} {...props} />;
};

export default AEMWrapper(
  {
    type: "rh/components/content/spa-include",
    config: {
      emptyLabel: "SPA Include Component",

      isEmpty: function (props: any) {
        return !props || !props.pagePath || props.pagePath.trim().length < 1;
      }
    }
  },
  AEMRHSPAInclude
);
