import React, { FC, useEffect, useRef, useState, memo, useMemo } from "react";
import { Helmet } from "react-helmet";
import { PromoProps } from "component-promo";
import { Theme, useMediaQuery } from "utils/material-ui-core";
import { queryGetWistias } from "graphql-client/queries/get-wistia";
import { useQuery } from "@apollo/client";
import analyticsLoader from "analytics/loader";
import { memoryStorage } from "utils/analytics/storage";
import { processEnvServer } from "hooks/useSsrHooks";
declare global {
  interface Window {
    _wq: Array<any>;
    _wq_disabled_pipedream: boolean;
  }
}
// import { usePageContentNoProxy } from "customProviders/LocationProvider";
// import { SPACES_WISTIA_ID } from "component-promo/constants";

export type WistiaProps = PromoProps & {
  /**
   * If set to true, the video will play as soon as it's ready.
   *
   * @see https://wistia.com/support/developers/embed-options#autoplay
   */
  autoPlay?: boolean;
  /**
   * This option allows videos to autoplay in a muted state in contexts where normal autoplay is blocked or not supported (e.g. iOS, Safari 11+, Chrome 66+).
   *
   * Possible values are:
   * - true: The video will default to autoplaying silently.
   * - allow: The video will default to normal autoplay, with silent autoplay as a fallback if needed.
   * - false: The video will not autoplay silently.
   *
   * @see https://wistia.com/support/developers/embed-options#silentautoplay
   */
  silentAutoPlay?: boolean | "allow";
  isResponsive?: boolean;
  onEnd?: () => void;
  wistiaIdSmDown?: string;
  wistiaIdIpad?: string;
  isVisible?: string;
  getCurrentTime?: (currentTime: number) => void;
  backgroundColor?: string;
};

const getAspectRatioFromMeta = (metaVideos, wistiaId) => {
  if (!metaVideos || !wistiaId) return "auto";
  const selected = metaVideos.find(
    metaVideo => metaVideo.wistiaId === wistiaId
  );

  const selectedAspectRatio = selected?.aspectRatio?.replace?.(/[^0-9.]/g, "");
  return selectedAspectRatio;
};

export const Wistia: FC<WistiaProps> = ({
  autoPlay,
  data,
  isResponsive = true,
  silentAutoPlay,
  wistiaIdSmDown,
  wistiaIdIpad,
  isQuadImpl = false,
  onEnd,
  getCurrentTime,
  customAspectWidth,
  customAspectHeight
}) => {
  const isIpadLandscape = useMediaQuery<Theme>(
    theme => theme.breakpoints.between(1365, 1600),
    { noSsr: true }
  );
  const upSm = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"), {
    noSsr: true
  });

  const chooseWistiaId = useMemo(() => {
    /* for SSR they are only 2 options 0-11199 we use smdown and 1200 up is desktop  */
    if (isIpadLandscape) {
      return wistiaIdIpad || data?.dynamicText?.wistia;
    } else if (upSm) {
      return data?.dynamicText?.wistia;
    } else return wistiaIdSmDown || data?.dynamicText?.wistia;
  }, [isIpadLandscape, upSm]);

  const getPreloads = useMemo(() => {
    const preloads: { media: string; wistiaId: string }[] = [];
    if (wistiaIdIpad) {
      preloads.push({
        wistiaId: wistiaIdIpad,
        media: "(min-width: 1366px) and (max-width: 1600px)"
      });
    }
    if (wistiaIdSmDown) {
      preloads.push({ wistiaId: wistiaIdSmDown, media: "(max-width: 767px)" });
    }
    if (data?.dynamicText?.wistia) {
      /* cant have 2 preload with the same source wo we are picking the 768 until 1365 instead of bigger than 1601*/
      /*  preloads.push({
        wistiaId: data?.dynamicText?.wistia,
        media: "(min-width: 1601px)"
      }); */
      preloads.push({
        wistiaId: data?.dynamicText?.wistia,
        media: "(min-width: 768px) and (max-width: 1365px)"
      });
    }
    return preloads;
  }, [data, wistiaIdSmDown, wistiaIdIpad]);

  const wistiaIds = [wistiaIdIpad, data?.dynamicText?.wistia, wistiaIdSmDown]
    .filter(Boolean)
    .join(",");

  const wistiaId =
    //remove isQuadImpl when we test wistia with new requirement to have mobile under md instead of sm
    isQuadImpl ? data.id : chooseWistiaId;
  const { data: wistiaResponse = {} as Query } = useQuery<Query>(
    queryGetWistias,
    {
      variables: {
        WistiasInput: {
          wistiaId: wistiaIds
        }
      }
    }
  );
  const { getWistias } = (wistiaResponse as any) || {};

  const aspectRatio = getAspectRatioFromMeta(getWistias, wistiaId); // getWistias?.aspectRatio || "16 / 9";
  // const { pageContent = {} } = usePageContentNoProxy() || {};
  const [videoPlayer, setVideoPlayer] = useState(null) as any;
  // const wistiaJson =
  //   pageContent && pageContent.wistiaJson
  //     ? pageContent.wistiaJson
  //     : getWistia?.wistiaJson || null;
  // console.warn("🚀 ~ wistiaJson:", wistiaJson);
  // const thumbnailUrl = useMemo(() => {
  //   //const thumbnailUrlRegex =
  //   //  /(https:\/\/embed-ssl.wistia.com\/deliveries.{1,100}).png/g;
  //   const mediaUrlRegex =
  //     /(https:\/\/embed-ssl.wistia.com\/deliveries.{1,100}).bin/g;
  //   //const thumbnailUrlMatches = wistiaJson
  //   //  ? JSON.stringify(wistiaJson).match(thumbnailUrlRegex)
  //   //  : null;
  //   const mediaUrlMatches = wistiaJson
  //     ? JSON.stringify(wistiaJson).match(mediaUrlRegex)
  //     : null;
  //   /*const thumbnailUrlMatch = thumbnailUrlMatches
  //       ? thumbnailUrlMatches[0]
  //       : mediaUrlMatches
  //       ? mediaUrlMatches[0].replace(".bin", ".jpg?video_still_time=0")
  //       : null;
  //     */
  //   const img = mediaUrlMatches
  //     ? mediaUrlMatches[0].replace(".bin", ".jpg?video_still_time=0")
  //     : null;
  //   console.warn("🚀 ~ thumbnailUrl ~ img:", img);

  //   return img;
  // }, [wistiaJson]);
  // const embeddedThumb = pageContent ? pageContent.wistiaThumbnail : null;
  // console.warn("🚀 ~ embeddedThumb:", embeddedThumb);
  // const ph = "https://placehold.co/160x90/FF0000/FFFFFF/png"; //"https://hd.wallpaperswide.com/thumbs/one_world_trade_center_new_york_city-t2.jpg"
  // const wistiaThumb = ph ? ph : embeddedThumb ? embeddedThumb : thumbnailUrl;
  // console.warn("🚀 ~ wistiaThumb:", wistiaThumb);
  // /* getting unpredictable results from wistia */

  // const dynamicAspectRatio = useMemo(() => {
  //   if(!wistiaJson) return "auto"
  //   try{
  //     // const aspectRegex = /\"aspect_ratio\\":.{1,5}/g //line black below
  //     const aspectRegex = /\"aspect_ratio\\":.{1,10}/g
  //     const [matchString] = wistiaJson.match(aspectRegex)
  //     const [aspect] = matchString.split(":").toReversed()
  //     return aspect;
  //   }catch(e){
  //     return "auto"
  //   }

  //   // const widthRegex = /\"width.{1,3}\":.{1,4}/g;
  //   // const heightRegex = /\"height.{1,3}\":.{1,4}/g;
  //   // const widthMatches = wistiaJson ? wistiaJson.match(widthRegex) : null;
  //   // const heightMatches = wistiaJson ? wistiaJson.match(heightRegex) : null;
  //   // const dynamicWidth = widthMatches
  //   //   ? widthMatches[0].replace(/[^0-9]/g, "")
  //   //   : null;
  //   // const dynamicHeight = heightMatches
  //   //   ? heightMatches[0].replace(/[^0-9]/g, "")
  //   //   : null;
  //   // return dynamicWidth && dynamicHeight
  //   //   ? Number(dynamicWidth) / Number(dynamicHeight)
  //   //   : null;
  // }, [wistiaJson]);

  // const customAspectRatio = customAspectWidth && customAspectHeight ? `${customAspectWidth} / ${customAspectHeight}` : null

  //   //   ? Number(customAspectWidth) / Number(customAspectHeight)
  //   //   : null;

  // const  aspect = customAspectRatio
  //   ? customAspectRatio
  //   : dynamicAspectRatio
  //   ? dynamicAspectRatio
  //   // : "16 / 9 ";
  //   : "0";

  // // const aspect = dyna  // customAspectRatio ? customAspectRatio : 16 / 9;
  // console.warn("🚀 ~ aspect:", aspect);
  // const padding = 100 / aspect;
  // console.warn("🚀 ~ padding:", padding);
  const videoWrapperRef = useRef(null);
  // const height =
  //   (upMd || upXl) && data.dynamicText.layout !== "fullwidth"
  //     ? 1000 / aspect
  //     : window.innerWidth / aspect;

  useEffect(() => {
    if (!window._wq_disabled_pipedream) {
      window._wq_disabled_pipedream = true;
      window._wq = window._wq || [];
      window._wq.push(function (W) {
        W.consent(false);
      });
    }
  }, []);

  useEffect(() => {
    let shouldUpdate = true;
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          //in the viewport, play it
          if (videoPlayer) {
            if (
              videoPlayer.state() === "paused" ||
              (autoPlay && videoPlayer.state() === "beforeplay")
            ) {
              videoPlayer.play();
            }
          }
        } else {
          //out of the viewport, pause it and restart
          if (videoPlayer) {
            if (videoPlayer.state() === "playing") {
              videoPlayer.pause();
            }
            videoPlayer.time(0);
          }
        }
      });
    });
    if (!!wistiaId) {
      const win = window as any;
      win._wq = win._wq || [];
      const hasWistiaId = win._wq.some(obj => {
        return obj.id === wistiaId;
      });
      const loadWistia = () => {
        if (!hasWistiaId) {
          win._wq.push({
            id: wistiaId,
            options: {
              //autoPlay: autoPlay ?? true,
              silentAutoPlay: silentAutoPlay ?? "allow",
              // stillUrl: thumbnailUrl,
              preload: "all"
            },
            onReady: (video: any) => {
              if (!video) {
                return;
              }
              if (shouldUpdate && video !== videoPlayer) {
                setVideoPlayer(video);
              }
              if (videoWrapperRef.current) {
                observer.observe(videoWrapperRef.current);
              }

              const isFilmVideo =
                !processEnvServer &&
                memoryStorage.getItem("isModalOpen") &&
                document?.querySelector("[data-analytics-id='rh-modal']");

              video.bind("play", () => {
                if (!processEnvServer && isFilmVideo && video.inViewport()) {
                  analyticsLoader(a =>
                    a.emitAnalyticsEvent(
                      document.querySelector("#spa-root > *")! as HTMLElement,
                      a.EVENTS.VIDEO_START.INT_TYPE,
                      {
                        title: video?.name()
                      }
                    )
                  );
                }
              });

              let milestonesReached = new Set<number>();
              let videoCompleted = false;
              video.bind("timechange", (time: number) => {
                if (!processEnvServer && isFilmVideo && video.inViewport()) {
                  const duration = video?.duration();
                  const percentage = Math.floor((time / duration) * 100);
                  const milestones = [10, 25, 50, 75, 100];

                  const milestoneToTrack = milestones.find(
                    milestone =>
                      percentage >= milestone &&
                      !milestonesReached.has(milestone)
                  );

                  if (
                    milestoneToTrack !== undefined &&
                    isFilmVideo &&
                    !videoCompleted
                  ) {
                    if (milestoneToTrack === 100) {
                      videoCompleted = true;
                      analyticsLoader(a =>
                        a.emitAnalyticsEvent(
                          document.querySelector(
                            "#spa-root > *"
                          )! as HTMLElement,
                          a.EVENTS.VIDEO_COMPLETE.INT_TYPE,
                          {
                            title: video?.name()
                          }
                        )
                      );
                    } else {
                      analyticsLoader(a =>
                        a.emitAnalyticsEvent(
                          document.querySelector(
                            "#spa-root > *"
                          )! as HTMLElement,
                          a.EVENTS.VIDEO_IN_PROGRESS.INT_TYPE,
                          {
                            percentage: milestoneToTrack,
                            title: video?.name()
                          }
                        )
                      );
                      milestonesReached.add(milestoneToTrack);
                    }
                  }
                }
              });

              if (onEnd) {
                video.bind("end", onEnd);
                if (
                  !processEnvServer &&
                  isFilmVideo &&
                  video.inViewport() &&
                  !videoCompleted
                ) {
                  videoCompleted = true;
                  analyticsLoader(a =>
                    a.emitAnalyticsEvent(
                      document.querySelector("#spa-root > *")! as HTMLElement,
                      a.EVENTS.VIDEO_COMPLETE.INT_TYPE,
                      {
                        title: video?.name()
                      }
                    )
                  );
                }
              }
              //Wistia fires event every second of video. we call get current time with this event.
              if (getCurrentTime) {
                video.bind("secondchange", () => {
                  const currentTime = video.time();
                  getCurrentTime(currentTime);
                });
              }
            }
          });
        }
      };
      loadWistia();
    }
    return () => {
      shouldUpdate = false;
      if (videoWrapperRef.current) {
        observer.unobserve(videoWrapperRef.current);
      }
    };
  }, [wistiaId, videoWrapperRef]);
  return (
    <>
      {/* {wistiaJson && ( */}
      <>
        {/* <Helmet>
            <script>{wistiaJson}</script>
          </Helmet> */}
        {/* { rederClient && <Helmet> <script id="wistia_script" src="https://fast.wistia.com/assets/external/E-v1.js" async ></script> </Helmet> } */}
        <Helmet>
          <script
            id="wistia_script"
            src="https://fast.wistia.com/assets/external/E-v1.js"
            async
            {...({ fetchpriority: "high" } as any)}
          ></script>
        </Helmet>
        {/* <Helmet>
          <link
            rel="preload"
            href="https://fast.wistia.com/embed/medias/aex11m9h09.json"
            as="fetch"
            media="(max-width: 767px)"
          />
        </Helmet> */}
        {getPreloads.map(preload => {
          return (
            <Helmet key={preload.media}>
              <link
                rel="preload"
                href={`https://fast.wistia.com/embed/medias/${preload.wistiaId}.json`}
                as="fetch"
                media={preload.media}
                crossOrigin="anonymous"
              />
            </Helmet>
          );
        })}
        {/*  {thumbnailUrl && !embeddedThumb && (
            <Helmet>
              <link rel="preload" href={thumbnailUrl} as="image" />
            </Helmet>
          )} */}
      </>

      <>
        <div
          ref={videoWrapperRef}
          className="wistia_responsive_padding"
          style={{
            backgroundColor: "black",
            aspectRatio: aspectRatio,
            position: "relative",
            marginBottom: "-3px"
          }}
        >
          <div
            className="wistia_responsive_wrapper"
            style={{
              height: "100%",
              left: 0,
              position: "absolute",
              top: 0,
              width: "100%",
              // backgroundImage:
              //   wistiaId !== SPACES_WISTIA_ID
              //     ? `url(${wistiaThumb})`
              //     : "none",
              backgroundSize: "contain"
            }}
          >
            <div
              key={wistiaId}
              className={`wistia_embed wistia_async_${wistiaId} seo=false videoFoam=true`}
              style={{ height: "100%", width: "100%" }}
            ></div>
          </div>
        </div>
        {/* <button
          style={{ position: "relative", zIndex: 3000 }}
          onClick={() => setRenderClient(c => !c)}
        >
          toggle Video
        </button> */}
      </>
    </>
  );
};

Wistia.defaultProps = {
  autoPlay: true,
  data: {
    dynamicText: {
      wistia: ""
    }
  },
  silentAutoPlay: "allow"
} as Partial<WistiaProps>;

export default memo(Wistia);
