import React from "react";
import {
  makeStyles,
  createStyles,
  Grid,
  useTheme,
  ButtonBase
} from "utils/material-ui-core";
import LargeArrowIcon from "icon-large-arrow";
import { sanitizeArrowClassName } from "./sanitizeArrowClassName";
import { StyleProps } from "./types";
import yn from "yn";
import LargeArrowIconV2 from "icons/LargeArrowIconV2";

import { getGlobal } from "@RHCommerceDev/utils/Globals/getGlobal";

const env = getGlobal("_env");
const featureCgCarousel = yn(env.FEATURE_CG_CAROUSEL);

const useStyles = (props: StyleProps) =>
  makeStyles(theme => {
    const { arrowsVisible = "", arrowsBackgroundColor } = props;

    return createStyles({
      carouselArrowGrid: {
        opacity: ["never", "hover-only"].includes(arrowsVisible)
          ? "0 !important"
          : "1 !important",
        "&:hover": {
          opacity: arrowsVisible === "never" ? 0 : 1
        }
      },
      carouselArrow: {
        backgroundColor: arrowsBackgroundColor
      }
    });
  });

export const PreviousArrow = props => {
  const {
    onClick = () => {},
    carouselArrowGridStyles,
    carouselArrowStyles,
    className = "",
    allowDisabledClass = false,
    arrowZoomImage,
    icon,
    imageCarousels,
    arrowsColor,
    arrowsBackgroundColor,
    col,
    handlePrev = () => {},
    prevArrowAccessibilityProps,
    arrowsSize
  } = props;
  const classes = useStyles(props)();
  const theme = useTheme();

  const conditionalPadding = featureCgCarousel ? "p-0" : "p-[16px]";
  const isLarge = arrowsSize === "large";
  const isLargeAem = arrowsSize === "large-AEM";

  return (
    <Grid
      container
      className={`${
        classes.carouselArrowGrid
      } !transition-opacity !duration-500 !ease-in-out !w-auto absolute top-0 bottom-0 ${conditionalPadding} z-10 cursor-pointer arrow-container ${sanitizeArrowClassName(
        className,
        allowDisabledClass
      )}`}
      style={{
        left: arrowZoomImage ? "0" : imageCarousels ? "-20px" : 0,
        top: imageCarousels ? "-10px" : 0,
        padding: arrowZoomImage ? "0" : imageCarousels ? "20px 0" : "",
        ...carouselArrowGridStyles,
        transform: arrowZoomImage ? "rotate(90deg)" : "",
        position: arrowZoomImage ? "inherit" : "",
        zIndex: arrowZoomImage ? 0 : "",
        width: "auto !important",
        margin: "0 auto !important",
        opacity: 0.8
      }}
      alignItems="center"
      justify="center"
      onClick={() => {}}
    >
      <ButtonBase
        disableRipple
        className={
          "[&:focus-visible]:outline-[#015fcc] [&:focus-visible]:outline-[1px] [&:focus-visible]:outline"
        }
        style={{ transform: imageCarousels ? "scale(0.3)" : "" }}
        onClick={e => {
          e?.preventDefault();

          onClick && onClick();
          handlePrev();
          onClick && onClick();
        }}
        {...prevArrowAccessibilityProps}
      >
        {icon == "yes" ? (
          <LargeArrowIconV2
            className={`${classes.carouselArrow} lg:!text-[68px] ${
              isLargeAem ? "!text-[40px]" : "!text-[68px]"
            } ${
              isLargeAem ? "!h-[56px]" : isLarge ? "!h-[68px]" : ""
            } !w-[32px] arrow-icon`}
            style={{
              ...carouselArrowStyles,

              backgroundColor: arrowsBackgroundColor || "transparent"
            }}
            fill={arrowsBackgroundColor ? "none" : "white"}
            stroke={arrowsColor ?? theme?.palette?.common?.black}
            data-analytics-id="previous"
          />
        ) : (
          <LargeArrowIcon
            className={`${classes.carouselArrow} lg:!text-[68px] ${
              isLargeAem ? "!text-[40px]" : "!text-[68px]"
            } ${
              isLargeAem ? "!h-[56px]" : isLarge ? "!h-[68px]" : ""
            } !w-[32px] arrow-icon`}
            style={{
              ...carouselArrowStyles,
              backgroundColor: arrowsBackgroundColor || "transparent !important"
            }}
            fill={arrowsBackgroundColor ? "none" : "white"}
            stroke={arrowsColor ?? theme?.palette?.common?.black}
            data-analytics-id="previous"
            col={col}
          />
        )}
      </ButtonBase>
    </Grid>
  );
};
export default PreviousArrow;
