import type { FC, ReactNode } from "react";
import React, { useState, useMemo } from "react";
import RHDivider from "component-rh-divider";
import {
  Button,
  ClickAwayListener,
  useTheme,
  FormControlLabel,
  FormLabel,
  Grid,
  RadioGroup,
  Theme,
  Typography,
  useMediaQuery
} from "utils/material-ui-core";
import RHRadio from "component-rh-radio";
import Check from "icon-checkmark";
import classNames from "classnames";
import {
  countries,
  languages,
  measurements,
  localIdentifierToLanguageCodeMapper
} from "resources/countries-config.json";
import { useEnv } from "hooks/useEnv";
import yn from "yn";
import { useStyles } from "component-rh-shipping-selections/style";

import {
  LANGUAGE,
  MEASUREMENT,
  SAVE,
  SHIP_TO_THE_COUNTRY
} from "utils/constants";
import memoize from "utils/memoize";
import useUserPreferences from "hooks/useUserPreferences";
import RHSpinner from "component-rh-spinner";
import RHRFlagIcon from "icons/RHRFlagIcon";
import { useTypographyStyles } from "hooks/useTypographyStyles";
export interface RHShippingSelectionsProps {
  setIsOpen?: (open: boolean) => void;
  onClose?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
  onSave?: () => void;
  isGlobalNavigation?: boolean;
  popperType?: boolean;
  fontColor?: any;
  activeShippingSelector?: string | null;
  error?: ReactNode;
}

export const RHShippingSelections: FC<RHShippingSelectionsProps> = ({
  setIsOpen,
  onClose,
  onSave,
  isGlobalNavigation,
  popperType,
  fontColor = "#000000",
  activeShippingSelector,
  error
}) => {
  const env = useEnv();
  const classes = useStyles();
  const typographyStyles = useTypographyStyles({
    keys: ["uppercaseText"]
  });
  const theme = useTheme();
  const xsDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("xs"));
  const [loading, setLoading] = useState(false);

  const languageFeatureEnabled = yn(
    env.FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE
  );
  const FEATURE_INTERNATIONAL_MEASUREMENT = yn(
    env.FEATURE_INTERNATIONAL_MEASUREMENT
  );
  const FEATURE_EU_EXPANSION_DE_LANG = yn(env.FEATURE_EU_EXPANSION_DE_LANG);
  const FEATURE_SUPPRESS_UK = yn(env.FEATURE_SUPPRESS_UK);
  const FEATURE_EU_EXPANSION_DE = yn(env.FEATURE_EU_EXPANSION_DE);
  const FEATURE_EU_EXPANSION_BE = yn(env.FEATURE_EU_EXPANSION_BE);
  const FEATURE_EU_EXPANSION_ES = yn(env.FEATURE_EU_EXPANSION_ES);
  const FEATURE_EU_EXPANSION_FR = yn(env.FEATURE_EU_EXPANSION_FR);

  const {
    setCountry,
    setLanguage,
    setMeasurement,
    country,
    language,
    measurement,
    previousState,
    isDirty,
    handleSaveCookies
  } = useUserPreferences(() => {
    setLoading(false);
    if (isGlobalNavigation && !!onClose) {
      onClose({ isSaving: true }, "backdropClick");
      return;
    }
    if (isGlobalNavigation && !!setIsOpen) {
      setIsOpen(false);
    }
    if (popperType || !isGlobalNavigation) {
      !!setIsOpen && setIsOpen(false);
    }
  });

  const handleClickAway = () => {
    if ((!!setIsOpen || popperType) && !loading) {
      setLanguage(previousState.language);
      setMeasurement(previousState.measurement);
      setCountry(previousState.country);
      setIsOpen?.(false);
    }
  };

  const onSetCountry = selectedCountry => {
    const countryValue = countries?.[selectedCountry]?.defaultValues;
    setLanguage(countryValue.language);
    setMeasurement(countryValue.measurement);
    setCountry(countryValue.country);
  };
  const countryMode =
    (isGlobalNavigation && activeShippingSelector === "country") || popperType;
  const measurementMode =
    (isGlobalNavigation && activeShippingSelector === "measurement") ||
    popperType;
  const languageMode =
    (isGlobalNavigation && activeShippingSelector === "language") || popperType;

  const dropdownOptions = useMemo(() => {
    if (!FEATURE_EU_EXPANSION_DE_LANG) {
      const filteresAvailableLanguages =
        countries?.DE?.availableLanguages?.filter(
          lang => localIdentifierToLanguageCodeMapper?.[lang] !== "de"
        );
      const defaultLanguage = filteresAvailableLanguages?.[0];
      countries.DE.availableLanguages = filteresAvailableLanguages;
      countries.DE.defaultValues.language = defaultLanguage;
    }

    let countriesResult = Object.values(countries);
    const availableLanguages = countries?.[country]?.availableLanguages;

    let languagesResult = languages?.filter(lang =>
      availableLanguages?.includes(lang?.value)
    );
    languagesResult = languagesResult?.sort(
      (lang1, lang2) =>
        availableLanguages?.indexOf(lang1?.value) -
        availableLanguages?.indexOf(lang2?.value)
    );

    if (FEATURE_SUPPRESS_UK) {
      countriesResult = countriesResult?.filter(
        country => country.value !== "GB"
      );
      languagesResult = languagesResult.filter(
        language => language.value !== "en-GB"
      );
    }

    if (!FEATURE_EU_EXPANSION_DE) {
      countriesResult = countriesResult.filter(
        country => country.value !== "DE"
      );
      languagesResult = languagesResult.filter(
        language => language.value !== "de-DE"
      );
    }

    if (!FEATURE_EU_EXPANSION_BE) {
      countriesResult = countriesResult.filter(
        country => country.value !== "BE"
      );
      languagesResult = languagesResult.filter(
        language => language.value !== "nl-BE"
      );
    }
    if (!FEATURE_EU_EXPANSION_ES) {
      countriesResult = countriesResult.filter(
        country => country.value !== "ES"
      );

      languagesResult = languagesResult.filter(
        language => language.value !== "es-ES"
      );
    }
    if (!FEATURE_EU_EXPANSION_FR) {
      countriesResult = countriesResult.filter(
        country => country.value !== "FR"
      );

      languagesResult = languagesResult.filter(
        language => language.value !== "fr-FR"
      );
    }

    return { countries: countriesResult, languages: languagesResult };
  }, [country]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Grid
        id={"component-rh-shipping-selections"}
        container
        direction="column"
        wrap="nowrap"
        className={`
          ${
            isGlobalNavigation || popperType
              ? `!w-full h-full shadow-none ${classes.shippingSelectionGlobalWrapper}`
              : ` py-[22.4px] px-[32px] !w-[215px] ${classes.shippingSelectionWrapper}`
          }
            ${
              loading
                ? "opacity-60 pointer-events-none"
                : "opacity-100 pointer-events-auto"
            }
            `}
      >
        {loading && <RHSpinner style={{ zIndex: 999 }} />}
        {yn(env.FEATURE_INTERNATIONAL) && (
          <>
            {countryMode && (
              <>
                <FormLabel className="" component="legend">
                  <Grid item xs={6} component="span">
                    <Typography
                      variant={xsDown ? "caption" : "h4"}
                      className={classNames("!text-black", {
                        [classes.mobileSelectorTitle]: xsDown
                      })}
                    >
                      {SHIP_TO_THE_COUNTRY}
                    </Typography>
                  </Grid>
                </FormLabel>
                <RadioGroup
                  value={country}
                  name="radio-buttons-group"
                  onChange={event => onSetCountry(event.target.value)}
                  className={`!mb-12 !flex-nowrap`}
                >
                  {/* constant countries will be consumed from qraphql */}
                  {dropdownOptions?.countries?.map(country => (
                    <Grid
                      container
                      alignItems="center"
                      item
                      className={classes.radioItem}
                      xs={12}
                      key={country?.value}
                    >
                      <RHRFlagIcon
                        country={country.value}
                        className={"!w-4 !h-[10px]"}
                      />
                      <FormControlLabel
                        className="!ml-2 text-black"
                        value={country.value}
                        control={
                          <RHRadio
                            color="primary"
                            icon={null}
                            checkedIcon={
                              <Check
                                className={
                                  "left-[1px] text-black absolute !text-[11px]"
                                }
                              />
                            }
                            className={"!p-0 !ml-[6px] !mb-[2.4px]"}
                          />
                        }
                        label={
                          <Typography
                            variant={xsDown ? "h4" : "caption"}
                            className={classNames({
                              [classes.rhrTextDecoration]: true,
                              [classes.mobileSelectorLabel]: xsDown
                            })}
                          >
                            {country?.label.toUpperCase()}
                          </Typography>
                        }
                        labelPlacement="start"
                      />
                    </Grid>
                  ))}
                </RadioGroup>
              </>
            )}
          </>
        )}
        {/* Measurement */}
        {/* FEATURE_SUPPRESS_UK => flag added to suppress UK for March 31 launch*/}
        {measurementMode && FEATURE_INTERNATIONAL_MEASUREMENT && (
          <>
            <RHDivider
              style={{
                borderColor: "#FFFFFF",
                width: "100%",
                marginBottom: theme.spacing(2),
                height: 2
              }}
            />
            {!isGlobalNavigation && (
              <FormLabel component="legend">
                <Grid item xs={6} component="span">
                  <Typography
                    variant={xsDown ? "caption" : "h4"}
                    className={classNames({
                      [classes.mobileSelectorTitle]: xsDown,
                      [classes.rhrTextDecoration]: true
                    })}
                  >
                    {MEASUREMENT}
                  </Typography>
                </Grid>
              </FormLabel>
            )}
            <RadioGroup
              value={measurement}
              name="radio-buttons-group"
              onChange={event => setMeasurement(event.target.value)}
              className={`!mb-12 !flex-nowrap`}
            >
              {measurements.map(measurement => (
                <Grid
                  container
                  alignItems="center"
                  item
                  className={classes.radioItem}
                  xs={12}
                  key={measurement?.value}
                >
                  <FormControlLabel
                    key={measurement.value}
                    value={measurement.value}
                    className={"flex-row-reverse !ml-0"}
                    control={
                      <RHRadio
                        color="primary"
                        icon={null}
                        checkedIcon={
                          <Check
                            className={
                              "left-[1px] text-black absolute !text-[11px]"
                            }
                          />
                        }
                        className={"!p-0 !ml-[6px] !mb-[2.4px]"}
                      />
                    }
                    label={
                      <Typography
                        variant={xsDown ? "h4" : "caption"}
                        className={classNames({
                          [classes.rhrTextDecoration]: true,
                          [classes.mobileSelectorLabel]: xsDown,
                          [typographyStyles.uppercaseText]: true
                        })}
                      >
                        {measurement?.label}{" "}
                      </Typography>
                    }
                  />
                </Grid>
              ))}
            </RadioGroup>
          </>
        )}

        {/* Language */}
        {env.FEATURE_INTERNATIONAL && languageFeatureEnabled && (
          <>
            {languageMode && (
              <>
                <RHDivider
                  style={{
                    borderColor: "#FFFFFF",
                    width: "100%",
                    marginBottom: theme.spacing(2),
                    height: 2
                  }}
                />
                {!isGlobalNavigation && (
                  <FormLabel component="legend">
                    <Grid item xs={6} component="span">
                      <Typography
                        variant={xsDown ? "caption" : "h4"}
                        className={classNames({
                          [classes.mobileSelectorTitle]: xsDown,
                          [classes.rhrTextDecoration]: true
                        })}
                      >
                        {LANGUAGE}
                      </Typography>
                    </Grid>
                  </FormLabel>
                )}
                <RadioGroup
                  value={language}
                  name="radio-buttons-group"
                  onChange={event => setLanguage(event.target.value)}
                  className={`!mb-12 !flex-nowrap`}
                >
                  {dropdownOptions.languages.map(language => (
                    <Grid
                      container
                      alignItems="center"
                      item
                      className={classes.radioItem}
                      xs={12}
                      key={language?.value}
                    >
                      <FormControlLabel
                        key={language.value}
                        value={language.value}
                        className={"flex-row-reverse !ml-0"}
                        control={
                          <RHRadio
                            color="primary"
                            icon={null}
                            checkedIcon={
                              <Check
                                className={
                                  "left-[1px] text-black absolute !text-[11px]"
                                }
                              />
                            }
                            className={"!p-0 !ml-[6px] !mb-[2.4px]"}
                          />
                        }
                        label={
                          <Typography
                            variant={xsDown ? "h4" : "caption"}
                            className={classNames({
                              [classes.rhrTextDecoration]: true,
                              [classes.mobileSelectorLabel]: xsDown
                            })}
                          >
                            {language?.label.toUpperCase()}
                          </Typography>
                        }
                      />
                    </Grid>
                  ))}
                </RadioGroup>
              </>
            )}
          </>
        )}

        {error ? error : null}

        <Grid
          item
          xs={12}
          className={`${xsDown ? "absolute bottom-0 left-0 w-full p-8" : ""}`}
        >
          {isDirty && (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setLoading(true);
                const obj = countryMode
                  ? {
                      country: country
                    }
                  : {};
                handleSaveCookies(obj);
                onSave?.();
              }}
              size="large"
              className={"py-4 px-[82px] w-[200px] h-12"}
              disabled={loading}
            >
              <Typography variant="button" className={classes.saveBtn}>
                {SAVE}
              </Typography>
            </Button>
          )}
        </Grid>
      </Grid>
    </ClickAwayListener>
  );
};
export default memoize(RHShippingSelections);
