import React, { Fragment } from "react";
import memoize from "utils/memoize";

const Arrows = ({
  scrollPrev,
  scrollNext,
  prevClassName = "",
  nextClassName = "",
  index = 1,
  slides = 0
}) => {
  return (
    <Fragment>
      {index !== 1 && (
        <button
          className={`embla__prev m-0 p-0 inline-flex absolute\ group-hover/item:visible opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-transparent border-0 cursor-pointer ${prevClassName} -left-5 top-[46%]`}
          onClick={scrollPrev}
          aria-label="previous"
        >
          <svg
            focusable="false"
            width="24"
            height="26"
            viewBox="0 0 24 26"
            aria-hidden="true"
            data-analytics-id="previous"
            fill="none"
          >
            <path d="M18 25L6 13L18 1" stroke="black" stroke-width="1.5" />
          </svg>
        </button>
      )}
      {index !== slides && (
        <button
          className={`embla__next m-0 p-0 inline-flex absolute   group-hover/item:visible opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-transparent border-0 cursor-pointer ${nextClassName} -right-5 top-[46%]`}
          onClick={scrollNext}
          aria-label="next"
        >
          <svg
            focusable="false"
            width="24"
            height="26"
            viewBox="0 0 24 26"
            aria-hidden="true"
            data-analytics-id="next"
            fill="none"
          >
            <path d="M6 1L18 13L6 25" stroke="black" stroke-width="1.5" />
          </svg>
        </button>
      )}
    </Fragment>
  );
};

export default memoize(Arrows);
