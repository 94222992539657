import AEMWrapper from "aem/ComponentWrapper";
import React, { FC } from "react";
import { Utils } from "@adobe/aem-react-editable-components";
import meta from "./aem-meta";
import { RHAnchorProps } from ".";
import loadable from "@loadable/component";
const RHAnchor = loadable(
  () => import(/* webpackChunkName: "component-rh-anchor" */ "."),
  {
    fallback: <></>
  }
);

const RHAnchorAEM: FC<RHAnchorProps> = ({ anchorId }) => {
  return (
    <>
      <RHAnchor anchorId={anchorId} isEditor={Utils.isInEditor()}></RHAnchor>
    </>
  );
};

export default AEMWrapper(meta, RHAnchorAEM);
