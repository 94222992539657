import loadable from "@loadable/component";
import AEMWrapper from "aem/ComponentWrapper";
import ContainerWrapper from "concierge-alias-aem/ContainerWrapper";
import React from "react";
import meta from "./aem-meta";

const Component = loadable(
  () =>
    import(
      /* webpackChunkName: "component-rh-animation-timelines/aem-component" */ "./aem-component"
    ),
  {
    fallback: <></>
  }
);

export default AEMWrapper(meta, Component, {
  wrapper: ContainerWrapper
});
