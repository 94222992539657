import { PropTypes } from "aem/ComponentWrapper";

export default {
  title: "RHR Guest House Video",
  type: "rh/components/content/rhr-guest-house-video",
  isContainer: true,
  config: {
    emptyLabel: "RHR Guest House Video",
    isEmpty: ({}: PropTypes) => true
  }
};
