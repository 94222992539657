import React, { CSSProperties, FC, useMemo, useCallback } from "react";
import classNames from "classnames";
import {
  Typography,
  Grid,
  createStyles,
  makeStyles,
  Theme,
  useMediaQuery,
  ButtonBase
} from "utils/material-ui-core";
import {
  countries,
  countryNameMapper,
  currencySymbolToCurrencyCodeMapper
} from "resources/countries-config.json";
import useTypographyStyles from "hooks/useTypographyStyles";
import Price from "./Price";
import memoize from "utils/memoize";
import getCountryFromUrl from "utils/getCountryFromUrl";
import {
  MEMBER_PRICE_TAG,
  SALE_PRICE_TAG,
  REGULAR_PRICE_TAG,
  BREAKPOINT_SM,
  BREAKPOINT_MD
} from "utils/constants";
import PriceLabel from "./PriceLabel";
import {
  convertProductPriceRangeDisplayToPriceRange,
  PickProductPriceRangeDisplay
} from "./utils/convertProductPriceRangeDisplay";
import { parseUserContextCookie } from "utils/parseUserContextCookie";
import { getCookie } from "utils/cookies";
import { useIsoCookies } from "hooks/useIsoCookies";
import { useEnv } from "hooks/useEnv";
import { formatCurrencyWithoutCS } from "utils/currencyHelpers";
import { usePageContent } from "customProviders/LocationProvider";
import useParams from "hooks/useParams";
import { isSSRToggledWithClientRender } from "utils/ssrHelpers";
import yn from "yn";
import { useRhUserAtomValue } from "hooks/atoms";

export interface PriceRangeDisplayProps {
  data: PickProductPriceRangeDisplay &
    Pick<
      ProductPriceRangeDisplay,
      | "overridePriceLabel"
      | "hasOnlyOneSku"
      | "currencyLabel"
      | "currencySymbol"
      | "priceMessage"
      | "priceMessagePrice"
    >;
  isSkuPrice?: number;
  onSale?: boolean;
  variant?: "small" | "default" | "medium";
  /** @deprecated not used anymore. color should always be body2 blk  */
  color?: string;
  /** @deprecated not used anymore. colorSale should always be body2 blk  */
  colorSale?: string;
  topLabel?: string;
  display?: "inline" | "block";
  currency?: Maybe<string>;
  showSaleMessage?: boolean;
  metaProductComponent?: boolean;
  emptyProduct?: boolean;
  memberPrice?: number;
  discountAmount?: Maybe<number>;
  skulSalePrice?: SkuSalePrice;
  isMetaProduct?: boolean;
  nextGenDrivenOnSale?: boolean;
  onViewSelectItemsOnSaleClick?: () => void;
  skuOnSalePercentage?: number;
  isFromRegistry?: boolean;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      "& p": {
        lineHeight: theme.typography.pxToRem(18)
      }
    },
    regularPriceNumber: {
      color: "#666666 !important"
    },
    regularPriceText: {
      color: "#666666 !important"
    },
    cartRegularPriceNumber: {
      fontFamily: "RHSans, Thin !important",
      fontSize: "11px !important"
    },
    cartRegularPriceText: {
      fontFamily: "RHSans, Thin !important",
      fontSize: "11px !important"
    },
    withoutCrossOutRegularText: {
      // color: "#000000 !important",
      color: "#666666 !important"
    },
    crossOutRegularText: {
      // color: "#898886 !important",
      color: "#666666 !important"
    },
    cartSalePriceNumber: {
      fontFamily: "RHSans, Thin !important",
      fontSize: "11px !important"
      // color: "#CA2022 !important"
    },
    cartSalePriceText: {
      fontFamily: "RHSans, Thin !important",
      fontSize: "11px !important"
      // color: "#CA2022 !important"
    },
    cartMemberPriceNumber: {
      fontFamily: "RHSans, Roman !important",
      fontSize: "11px !important"
      // fontFamily: "Baron Sans !important",
      // fontFamily: "RHSans-Roman, Baron Sans !important"
    },
    cartMemberPriceText: {
      fontFamily: "RHSans, Roman !important",
      fontSize: "11px !important"
    },
    finalSaleNumber: {
      color: "#CA2022 !important"
    },
    finalSaleText: {
      color: "#CA2022 !important"
    },
    priceRangeWrapper: {
      marginBottom: "36px",
      "& *": {
        color: "#000 !important",
        fontFamily: "RHSans !important",
        fontSize: "11px !important",
        fontStyle: "normal",
        fontWeight: "400 !important",
        lineHeight: "13.2px !important",
        letterSpacing: "0.44px !important"
      },
      "& #regularPrice *": {
        fontWeight: "100 !important"
      },
      [theme.breakpoints.down(BREAKPOINT_SM)]: {
        marginBottom: "16px"
      },
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        "& *": {
          fontSize: "13px !important",
          lineHeight: "15.6px !important",
          letterSpacing: "0.52px !important"
        },
        "& #regularPrice *": {
          fontWeight: "200 !important"
        }
      }
    },
    nowrap: {
      whiteSpace: "nowrap"
    }
  })
);

export interface UniversalPriceDisplay {
  amount: number;
  label: string;
  strikethrough?: boolean | null; // probably going to deprecate this key
  style?: React.CSSProperties; // probably going to deprecate this key
  className?: string; // probably going to deprecate this key
  isCartPage?: boolean;
  discountAmount?: Maybe<number>;
  originalMemberPrice?: Maybe<number>;
}

interface MemberPriceDisplay extends UniversalPriceDisplay {
  showMemberPrice?: boolean;
}

export interface PriceRange {
  regular: UniversalPriceDisplay;
  sale?: UniversalPriceDisplay;
  finalSale?: UniversalPriceDisplay;
  member?: MemberPriceDisplay;
  contract?: UniversalPriceDisplay;
  trade?: UniversalPriceDisplay;
  lowestSkuMemberPrice?: UniversalPriceDisplay;
  lowestSkuSalePrice?: UniversalPriceDisplay;
  lowestSkuRegularPrice?: UniversalPriceDisplay;
}

export interface UniversalPriceRangeDisplayProps
  extends Omit<PriceRangeDisplayProps, "data"> {
  isCartPage?: boolean;
  topLabel?: string;
  range: PriceRange;
  /** @deprecated use currency */
  currencyLabel?: string;
  /** @deprecated use currency */
  currencySymbol?: string;
  showCents?: boolean;
  priceMessage?: string;
  /** @deprecated unused */
  priceMessagePrice?: number;
  quantity?: number;
  /** Click event on regular price */
  onClick?: () => void | null;
}

/**
 * Universal Price Range Display
 *    - the idea here is to pass in an Object (PriceRange) of maybe keys regular, sale, finalSale, member, trade and/or contract
 *    - When one is null, the label/price will not show.
 *    - !!! This is how we can control what is displayed with more of a data-driven approached. (This really helps with troubleshooting display and styles)
 * @param prop.topLabel string "Starting At" text for example
 * @param {*} prop.range Object typeof PriceRange
 *
 * ... there are more props
 * @returns
 */
export const UniversalPriceRangeDisplay: FC<
  UniversalPriceRangeDisplayProps
> = ({
  topLabel,
  range: { regular, sale, finalSale, member, trade, contract } = {},
  currencyLabel,
  currencySymbol,
  currency,
  showCents = false,
  variant,
  priceMessage,
  discountAmount,
  priceMessagePrice,
  isCartPage,
  isFromRegistry = false,
  quantity = 1,
  onClick
}) => {
  const env = useEnv();
  const isFEATURE_ADDON_MESSAGING = yn(env.FEATURE_ADDON_MESSAGING);
  const typographyClasses = useTypographyStyles({
    keys: [
      "priceRange",
      "priceRangeSmall",
      "priceRangeBold",
      "priceRangeSale",
      "priceRangeMember",
      "priceRangeCaptionSmall",
      "rhBaseBody2",
      "rhBaseBody1"
    ]
  });
  const localClasses = useStyles();
  const genericProps = {
    currencyLabel,
    currencySymbol,
    currency,
    showCents
  };
  const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));

  const showMemberColumn = countries?.[getCountryFromUrl() || "US"]?.member;

  const regularClassNames = classNames(
    typographyClasses.priceRange,
    { [typographyClasses.priceRangeSale]: false },
    variant === "small" && !mdUp ? typographyClasses.priceRangeSmall : "",
    typographyClasses.rhBaseBody1
  );
  const saleClassNames = classNames({
    [typographyClasses.priceRangeSmall]: variant === "small" && !mdUp,
    [typographyClasses.rhBaseBody1]: true,
    [typographyClasses.priceRangeSale]: true
  });

  const memberClassNames = classNames(typographyClasses.priceRangeMember, {
    [typographyClasses.priceRangeSmall]: variant === "small" && !mdUp,
    [typographyClasses.priceRangeSale]: false,
    [typographyClasses.rhBaseBody2]: true
  });

  const formatCurrencyPrice = price => {
    let currencyCode = Boolean(env.FEATURE_INTERNATIONAL)
      ? countryNameMapper[getCountryFromUrl() || "US"]
      : currencySymbol && currencySymbolToCurrencyCodeMapper?.[currencySymbol!];
    const p = formatCurrencyWithoutCS(price, currencyCode, false);
    return p;
  };

  const { pageContent } = usePageContent();

  return (
    <Grid
      container
      spacing={0}
      className={localClasses.container}
      id="price-range-display"
    >
      {!isCartPage && (
        <Grid item xs={12}>
          <Typography
            className={classNames(typographyClasses.priceRange, {
              [typographyClasses.priceRangeSmall]: variant === "small" && !mdUp
            })}
            style={{
              textTransform: "none" // stop the capitalization
            }}
          >
            {topLabel || pageContent?.FROM_TOP_LABEL}
          </Typography>
        </Grid>
      )}

      {isCartPage || isFromRegistry ? (
        <Grid item xs={12} container>
          {finalSale || sale ? (
            isCartPage ? (
              <>
                {member &&
                  member.showMemberPrice &&
                  showMemberColumn &&
                  showMemberColumn && (
                    <Grid item xs={12}>
                      <Price
                        className={`${memberClassNames} ${localClasses.cartMemberPriceNumber}`}
                        style={{
                          ...member.style,
                          color: "#CA2022",
                          fontWeight: 400
                        }}
                        data-testid="price-for-member-1"
                        display="inline"
                        {...genericProps}
                      >
                        {currency
                          ? (member.discountAmount || 0) * quantity
                          : formatCurrencyPrice(
                              (member.discountAmount || 0) * quantity
                            )}
                      </Price>{" "}
                      <PriceLabel
                        className={`${memberClassNames} ${localClasses.cartMemberPriceText}`}
                        style={{
                          ...member.style,
                          fontWeight: 400,
                          color: "#CA2022"
                        }}
                        display="inline"
                      >
                        {member.label || pageContent?.MEMBER_PRICE_TAG}
                      </PriceLabel>{" "}
                    </Grid>
                  )}
                {contract && (
                  <Grid item xs={12}>
                    <Price
                      className={memberClassNames}
                      style={{
                        ...contract.style,
                        color: "#CA2022",
                        fontWeight: 400
                      }}
                      data-testid={`price-for-contract`}
                      display="inline"
                      {...genericProps}
                    >
                      {currency
                        ? contract.amount * quantity
                        : formatCurrencyPrice(contract.amount * quantity)}
                    </Price>{" "}
                    <PriceLabel
                      className={memberClassNames}
                      style={{
                        ...contract.style,
                        color: "#CA2022",
                        fontWeight: 400
                      }}
                      display="inline"
                    >
                      {contract.label || pageContent?.CONTRACT_PRICE_TAG}
                    </PriceLabel>{" "}
                  </Grid>
                )}
                {trade && (
                  <Grid item xs={12}>
                    <Price
                      className={memberClassNames}
                      style={{
                        ...trade.style,
                        color: "#CA2022",
                        fontWeight: 400
                      }}
                      data-testid={`price-for-trade`}
                      display="inline"
                      {...genericProps}
                    >
                      {currency
                        ? trade.amount * quantity
                        : formatCurrencyPrice(trade.amount * quantity)}
                    </Price>{" "}
                    <PriceLabel
                      className={memberClassNames}
                      style={{
                        ...trade.style,
                        color: "#CA2022",
                        fontWeight: 400
                      }}
                      display="inline"
                    >
                      {trade.label || pageContent?.TRADE_PRICE_TAG}
                    </PriceLabel>
                  </Grid>
                )}
                {sale && (
                  <Grid item xs={12}>
                    <Price
                      className={`${saleClassNames} ${localClasses.cartSalePriceNumber}`}
                      style={{
                        ...sale.style,
                        color: "#CA2022",
                        fontWeight: 100
                      }}
                      data-testid={`price-for-sale`}
                      display="inline"
                      {...genericProps}
                    >
                      {currency
                        ? sale.amount * quantity
                        : formatCurrencyPrice(sale.amount * quantity)}
                    </Price>{" "}
                    <PriceLabel
                      className={`${saleClassNames} ${localClasses.cartSalePriceText}`}
                      style={{
                        ...sale.style,
                        color: "#CA2022",
                        fontWeight: 100
                      }}
                      display="inline"
                    >
                      {sale.label || pageContent?.SALE_PRICE_TAG}
                    </PriceLabel>{" "}
                  </Grid>
                )}
                {finalSale && (
                  <Grid item xs={12}>
                    <Price
                      {...genericProps}
                      className={`${localClasses.finalSaleText} ${saleClassNames}`}
                      style={{
                        ...finalSale.style,
                        color: "#CA2022",
                        fontWeight: 100
                      }}
                      data-testid={`price-for-final-sale`}
                      display="inline"
                    >
                      {currency
                        ? finalSale.amount * quantity
                        : formatCurrencyPrice(finalSale.amount * quantity)}
                    </Price>{" "}
                    <PriceLabel
                      className={`${localClasses.finalSaleNumber} ${saleClassNames}`}
                      style={{
                        ...finalSale.style,
                        color: "#CA2022",
                        fontWeight: 100
                      }}
                      display="inline"
                    >
                      {pageContent?.SALE_PRICE_TAG}
                    </PriceLabel>{" "}
                  </Grid>
                )}
              </>
            ) : (
              <Grid item xs={12}>
                {member &&
                  member.showMemberPrice &&
                  showMemberColumn &&
                  showMemberColumn && (
                    <>
                      <Price
                        className={`${memberClassNames} ${localClasses.cartMemberPriceNumber}`}
                        style={{
                          ...member.style,
                          color: "#CA2022",
                          fontWeight: 400
                        }}
                        data-testid="price-for-member-2"
                        display="inline"
                        {...genericProps}
                      >
                        {currency
                          ? member.discountAmount ?? member?.originalMemberPrice
                          : formatCurrencyPrice(
                              member.discountAmount ??
                                member?.originalMemberPrice
                            )}
                      </Price>{" "}
                      <PriceLabel
                        className={`${memberClassNames} ${localClasses.cartMemberPriceText}`}
                        style={{
                          ...member.style,
                          fontWeight: 400,
                          color: "#CA2022"
                        }}
                        display="inline"
                      >
                        {member.label || pageContent?.MEMBER_PRICE_TAG}
                      </PriceLabel>{" "}
                    </>
                  )}
                {contract && (
                  <>
                    <Price
                      className={memberClassNames}
                      style={{
                        ...contract.style,
                        color: "#CA2022",
                        fontWeight: 400
                      }}
                      data-testid={`price-for-contract`}
                      display="inline"
                      {...genericProps}
                    >
                      {currency
                        ? contract.amount
                        : formatCurrencyPrice(contract.amount)}
                    </Price>{" "}
                    <PriceLabel
                      className={memberClassNames}
                      style={{
                        ...contract.style,
                        color: "#CA2022",
                        fontWeight: 400
                      }}
                      display="inline"
                    >
                      {contract.label || pageContent?.CONTRACT_PRICE_TAG}
                    </PriceLabel>{" "}
                  </>
                )}
                {trade && (
                  <>
                    <Price
                      className={memberClassNames}
                      style={{
                        ...trade.style,
                        color: "#CA2022",
                        fontWeight: 400
                      }}
                      data-testid={`price-for-trade`}
                      display="inline"
                      {...genericProps}
                    >
                      {currency
                        ? trade.amount
                        : formatCurrencyPrice(trade.amount)}
                    </Price>{" "}
                    <PriceLabel
                      className={memberClassNames}
                      style={{
                        ...trade.style,
                        color: "#CA2022",
                        fontWeight: 400
                      }}
                      display="inline"
                    >
                      {trade.label || pageContent?.TRADE_PRICE_TAG}
                    </PriceLabel>
                  </>
                )}
                {sale && (
                  <>
                    {((member &&
                      member.showMemberPrice &&
                      showMemberColumn &&
                      showMemberColumn) ||
                      contract ||
                      trade) && (
                      <Typography
                        style={{
                          fontSize: variant === "small" ? 11 : 13,
                          color: "#CA2022"
                        }}
                        display="inline"
                      >
                        {" / "}
                      </Typography>
                    )}
                    <Price
                      className={`${saleClassNames} ${localClasses.cartSalePriceNumber}`}
                      style={{
                        ...sale.style,
                        color: "#CA2022",
                        fontWeight: 100
                      }}
                      data-testid={`price-for-sale`}
                      display="inline"
                      {...genericProps}
                    >
                      {currency
                        ? sale.amount
                        : formatCurrencyPrice(sale.amount)}
                    </Price>{" "}
                    <PriceLabel
                      className={`${saleClassNames} ${localClasses.cartSalePriceText}`}
                      style={{
                        ...sale.style,
                        color: "#CA2022",
                        fontWeight: 100
                      }}
                      display="inline"
                    >
                      {sale.label || pageContent?.SALE_PRICE_TAG}
                    </PriceLabel>{" "}
                  </>
                )}
                {finalSale && (
                  <>
                    {((member &&
                      member.showMemberPrice &&
                      showMemberColumn &&
                      showMemberColumn) ||
                      contract ||
                      trade) && (
                      <Typography
                        style={{
                          fontSize: variant === "small" ? 11 : 13,
                          color: "#CA2022"
                        }}
                        display="inline"
                      >
                        {" / "}
                      </Typography>
                    )}
                    <Price
                      {...genericProps}
                      className={`${localClasses.finalSaleText} ${saleClassNames}`}
                      style={{
                        ...finalSale.style,
                        color: "#CA2022",
                        fontWeight: 100
                      }}
                      data-testid={`price-for-final-sale`}
                      display="inline"
                    >
                      {currency
                        ? finalSale.amount
                        : formatCurrencyPrice(finalSale.amount)}
                    </Price>{" "}
                    <PriceLabel
                      className={`${localClasses.finalSaleNumber} ${saleClassNames}`}
                      style={{
                        ...finalSale.style,
                        color: "#CA2022",
                        fontWeight: 100
                      }}
                      display="inline"
                    >
                      {pageContent?.SALE_PRICE_TAG}
                    </PriceLabel>{" "}
                  </>
                )}
              </Grid>
            )
          ) : null}

          {isCartPage ? (
            finalSale || sale ? (
              <Grid item xs={12}>
                {member &&
                  member.showMemberPrice &&
                  showMemberColumn &&
                  showMemberColumn && (
                    <>
                      <Price
                        className={`${memberClassNames} ${localClasses.cartMemberPriceNumber}`}
                        style={{
                          ...member.style,
                          color: finalSale || sale ? "#898886" : "#000",
                          fontWeight: 400
                        }}
                        data-testid="price-for-member-3"
                        strikethrough={!!(sale || finalSale)}
                        display="inline"
                        {...genericProps}
                      >
                        {currency
                          ? member.amount * quantity
                          : formatCurrencyPrice(member.amount * quantity)}
                      </Price>{" "}
                      <PriceLabel
                        className={`${memberClassNames} ${localClasses.cartMemberPriceText}`}
                        style={{
                          ...member.style,
                          color: finalSale || sale ? "#898886" : "#000",
                          fontWeight: 400
                        }}
                        display="inline"
                        strikethrough={!!(sale || finalSale)}
                      >
                        {member.label || pageContent?.MEMBER_PRICE_TAG}
                      </PriceLabel>{" "}
                    </>
                  )}

                {contract && (
                  <>
                    <Price
                      className={memberClassNames}
                      style={{
                        ...contract.style,
                        color: finalSale || sale ? "#898886" : "#000",
                        fontWeight: 400
                      }}
                      data-testid={`price-for-contract`}
                      strikethrough={!!(sale || finalSale)}
                      display="inline"
                      {...genericProps}
                    >
                      {currency
                        ? contract.amount * quantity
                        : formatCurrencyPrice(contract.amount * quantity)}
                    </Price>{" "}
                    <PriceLabel
                      className={memberClassNames}
                      style={{
                        ...contract.style,
                        color: finalSale || sale ? "#898886" : "#000",
                        fontWeight: 400
                      }}
                      display="inline"
                      strikethrough={!!(sale || finalSale)}
                    >
                      {contract.label || pageContent?.CONTRACT_PRICE_TAG}
                    </PriceLabel>{" "}
                  </>
                )}
                {trade && (
                  <>
                    <Price
                      className={memberClassNames}
                      style={{
                        ...trade.style,
                        color: finalSale || sale ? "#898886" : "#000",
                        fontWeight: 400
                      }}
                      data-testid={`price-for-trade`}
                      strikethrough={!!(sale || finalSale)}
                      display="inline"
                      {...genericProps}
                    >
                      {currency
                        ? trade.amount * quantity
                        : formatCurrencyPrice(trade.amount * quantity)}
                    </Price>{" "}
                    <PriceLabel
                      className={memberClassNames}
                      style={{
                        ...trade.style,
                        color: finalSale || sale ? "#898886" : "#000",
                        fontWeight: 400
                      }}
                      display="inline"
                      strikethrough={!!(sale || finalSale)}
                    >
                      {trade.label || pageContent?.TRADE_PRICE_TAG}
                    </PriceLabel>
                  </>
                )}
                {regular && (
                  <span className={localClasses.nowrap}>
                    {((member &&
                      member.showMemberPrice &&
                      showMemberColumn &&
                      showMemberColumn) ||
                      contract ||
                      trade) && (
                      <Typography
                        style={{
                          fontSize: variant === "small" ? 11 : 13,
                          color: finalSale || sale ? "#898886" : "#000"
                        }}
                        display="inline"
                      >
                        {" / "}
                      </Typography>
                    )}
                    <Price
                      className={`${regularClassNames} ${localClasses.cartRegularPriceNumber}`}
                      style={{
                        ...regular.style,
                        color: finalSale || sale ? "#898886" : "#000",
                        fontWeight: 100
                      }}
                      data-testid={`price-for-regular`}
                      strikethrough={!!(sale || finalSale)}
                      display="inline"
                      {...genericProps}
                    >
                      {currency
                        ? regular.amount * quantity
                        : formatCurrencyPrice(regular.amount * quantity)}
                    </Price>{" "}
                    <PriceLabel
                      className={`${regularClassNames} ${localClasses.cartRegularPriceText}`}
                      style={{
                        ...regular.style,
                        color: finalSale || sale ? "#898886" : "#000",
                        fontWeight: 100
                      }}
                      display="inline"
                      strikethrough={!!(sale || finalSale)}
                    >
                      {regular.label || pageContent?.REGULAR_PRICE_TAG}
                    </PriceLabel>{" "}
                  </span>
                )}
              </Grid>
            ) : (
              <>
                {member &&
                  member.showMemberPrice &&
                  showMemberColumn &&
                  showMemberColumn && (
                    <Grid item xs={12}>
                      <Price
                        className={`${memberClassNames} ${localClasses.cartMemberPriceNumber}`}
                        style={{
                          ...member.style,
                          color: finalSale || sale ? "#898886" : "#000",
                          fontWeight: 400
                        }}
                        data-testid="price-for-member-4"
                        display="inline"
                        {...genericProps}
                      >
                        {currency
                          ? member.amount * quantity
                          : formatCurrencyPrice(member.amount * quantity)}
                      </Price>{" "}
                      <PriceLabel
                        className={`${memberClassNames} ${localClasses.cartMemberPriceText}`}
                        style={{
                          ...member.style,
                          color: finalSale || sale ? "#898886" : "#000",
                          fontWeight: 400
                        }}
                        display="inline"
                      >
                        {member.label || pageContent?.MEMBER_PRICE_TAG}
                      </PriceLabel>{" "}
                    </Grid>
                  )}
                {contract && (
                  <Grid item xs={12}>
                    <Price
                      className={memberClassNames}
                      style={{
                        ...contract.style,
                        color: finalSale || sale ? "#898886" : "#000",
                        fontWeight: 400
                      }}
                      data-testid={`price-for-contract`}
                      display="inline"
                      {...genericProps}
                    >
                      {currency
                        ? contract.amount * quantity
                        : formatCurrencyPrice(contract.amount * quantity)}
                    </Price>{" "}
                    <PriceLabel
                      className={memberClassNames}
                      style={{
                        ...contract.style,
                        color: finalSale || sale ? "#898886" : "#000",
                        fontWeight: 400
                      }}
                      display="inline"
                    >
                      {contract.label || pageContent?.CONTRACT_PRICE_TAG}
                    </PriceLabel>{" "}
                  </Grid>
                )}
                {trade && (
                  <Grid item xs={12}>
                    <Price
                      className={memberClassNames}
                      style={{
                        ...trade.style,
                        color: finalSale || sale ? "#898886" : "#000",
                        fontWeight: 400
                      }}
                      data-testid={`price-for-trade`}
                      display="inline"
                      {...genericProps}
                    >
                      {currency
                        ? trade.amount * quantity
                        : formatCurrencyPrice(trade.amount * quantity)}
                    </Price>{" "}
                    <PriceLabel
                      className={memberClassNames}
                      style={{
                        ...trade.style,
                        color: finalSale || sale ? "#898886" : "#000",
                        fontWeight: 400
                      }}
                      display="inline"
                    >
                      {trade.label || pageContent?.TRADE_PRICE_TAG}
                    </PriceLabel>
                  </Grid>
                )}
                {regular && (
                  <Grid
                    id="regular-price"
                    item
                    xs={12}
                    style={{
                      ...(onClick
                        ? {
                            cursor: "pointer",
                            textDecoration: "underline"
                          }
                        : null)
                    }}
                    onClick={onClick}
                  >
                    <span className={localClasses.nowrap}>
                      <Price
                        className={`${regularClassNames} ${localClasses.cartRegularPriceNumber}`}
                        style={{
                          ...regular.style,
                          color: finalSale || sale ? "#898886" : "#000",
                          fontWeight: 100
                        }}
                        data-testid={`price-for-regular`}
                        display="inline"
                        {...genericProps}
                      >
                        {currency
                          ? regular.amount * quantity
                          : formatCurrencyPrice(regular.amount * quantity)}
                      </Price>{" "}
                      <PriceLabel
                        className={`${regularClassNames} ${localClasses.cartRegularPriceText}`}
                        style={{
                          ...regular.style,
                          color: finalSale || sale ? "#898886" : "#000",
                          fontWeight: 100
                        }}
                        display="inline"
                      >
                        {regular.label || pageContent?.REGULAR_PRICE_TAG}
                      </PriceLabel>{" "}
                    </span>
                  </Grid>
                )}
              </>
            )
          ) : (
            <Grid item xs={12}>
              {member &&
                member.showMemberPrice &&
                showMemberColumn &&
                showMemberColumn && (
                  <>
                    <Price
                      className={`${memberClassNames} ${localClasses.cartMemberPriceNumber}`}
                      style={{
                        ...member.style,
                        color: finalSale || sale ? "#898886" : "#000",
                        fontWeight: 400
                      }}
                      data-testid="price-for-member-5"
                      strikethrough={!!(sale || finalSale)}
                      display="inline"
                      {...genericProps}
                    >
                      {currency
                        ? member.amount
                        : formatCurrencyPrice(member.amount)}
                    </Price>{" "}
                    <PriceLabel
                      className={`${memberClassNames} ${localClasses.cartMemberPriceText}`}
                      style={{
                        ...member.style,
                        color: finalSale || sale ? "#898886" : "#000",
                        fontWeight: 400
                      }}
                      display="inline"
                      strikethrough={!!(sale || finalSale)}
                    >
                      {member.label || pageContent?.MEMBER_PRICE_TAG}
                    </PriceLabel>{" "}
                  </>
                )}

              {contract && (
                <>
                  <Price
                    className={memberClassNames}
                    style={{
                      ...contract.style,
                      color: finalSale || sale ? "#898886" : "#000",
                      fontWeight: 400
                    }}
                    data-testid={`price-for-contract`}
                    strikethrough={!!(sale || finalSale)}
                    display="inline"
                    {...genericProps}
                  >
                    {currency
                      ? contract.amount
                      : formatCurrencyPrice(contract.amount)}
                  </Price>{" "}
                  <PriceLabel
                    className={memberClassNames}
                    style={{
                      ...contract.style,
                      color: finalSale || sale ? "#898886" : "#000",
                      fontWeight: 400
                    }}
                    display="inline"
                    strikethrough={!!(sale || finalSale)}
                  >
                    {contract.label || pageContent?.CONTRACT_PRICE_TAG}
                  </PriceLabel>{" "}
                </>
              )}
              {trade && (
                <>
                  <Price
                    className={memberClassNames}
                    style={{
                      ...trade.style,
                      color: finalSale || sale ? "#898886" : "#000",
                      fontWeight: 400
                    }}
                    data-testid={`price-for-trade`}
                    strikethrough={!!(sale || finalSale)}
                    display="inline"
                    {...genericProps}
                  >
                    {currency
                      ? trade.amount
                      : formatCurrencyPrice(trade.amount)}
                  </Price>{" "}
                  <PriceLabel
                    className={memberClassNames}
                    style={{
                      ...trade.style,
                      color: finalSale || sale ? "#898886" : "#000",
                      fontWeight: 400
                    }}
                    display="inline"
                    strikethrough={!!(sale || finalSale)}
                  >
                    {trade.label || pageContent?.TRADE_PRICE_TAG}
                  </PriceLabel>
                </>
              )}
              {regular && (
                <span className={localClasses.nowrap}>
                  {((member &&
                    member.showMemberPrice &&
                    showMemberColumn &&
                    showMemberColumn) ||
                    contract ||
                    trade) && (
                    <Typography
                      style={{
                        fontSize: variant === "small" ? 11 : 13,
                        color: finalSale || sale ? "#898886" : "#000"
                      }}
                      display="inline"
                    >
                      {" / "}
                    </Typography>
                  )}
                  <Price
                    className={`${regularClassNames} ${localClasses.cartRegularPriceNumber}`}
                    style={{
                      ...regular.style,
                      color: finalSale || sale ? "#898886" : "#000",
                      fontWeight: 100
                    }}
                    data-testid={`price-for-regular`}
                    strikethrough={!!(sale || finalSale)}
                    display="inline"
                    {...genericProps}
                  >
                    {currency
                      ? regular.amount
                      : formatCurrencyPrice(regular.amount)}
                  </Price>{" "}
                  <PriceLabel
                    className={`${regularClassNames} ${localClasses.cartRegularPriceText}`}
                    style={{
                      ...regular.style,
                      color: finalSale || sale ? "#898886" : "#000",
                      fontWeight: 100
                    }}
                    display="inline"
                    strikethrough={!!(sale || finalSale)}
                  >
                    {regular.label || pageContent?.REGULAR_PRICE_TAG}
                  </PriceLabel>{" "}
                </span>
              )}
            </Grid>
          )}

          <Grid item xs={12}>
            {isFEATURE_ADDON_MESSAGING && discountAmount && (
              <span className={localClasses.nowrap}>
                <Price
                  style={{
                    color: "#CA2022",
                    fontWeight: 100
                  }}
                  data-testid={`price-for-regular`}
                  strikethrough={!!(sale || finalSale)}
                  display="inline"
                  {...genericProps}
                >
                  {currency
                    ? discountAmount / 100
                    : formatCurrencyPrice(discountAmount / 100)}
                </Price>{" "}
                <PriceLabel
                  style={{
                    color: "#CA2022",
                    fontWeight: 100
                  }}
                  display="inline"
                  strikethrough={!!(sale || finalSale)}
                >
                  {pageContent?.DISCOUNT}
                </PriceLabel>{" "}
              </span>
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid item container spacing={1}>
          {/* PRICE COLUMN */}
          <Grid item>
            {regular && (
              <Price
                className={`${localClasses.regularPriceNumber} ${regularClassNames}`}
                style={regular.style}
                data-testid={`price-for-regular`}
                strikethrough={regular?.strikethrough!}
                {...genericProps}
              >
                {currency
                  ? regular.amount
                  : formatCurrencyPrice(regular.amount)}
              </Price>
            )}
            {sale && (
              <Price
                className={saleClassNames}
                style={sale.style}
                data-testid={`price-for-sale`}
                strikethrough={sale?.strikethrough!}
                {...genericProps}
              >
                {currency ? sale.amount : formatCurrencyPrice(sale.amount)}
              </Price>
            )}
            {finalSale && (
              <Price
                {...genericProps}
                className={`${localClasses.finalSaleText} ${saleClassNames}`}
                style={finalSale.style}
                data-testid={`price-for-final-sale`}
                strikethrough={finalSale?.strikethrough!}
              >
                {currency
                  ? finalSale.amount
                  : formatCurrencyPrice(finalSale.amount)}
              </Price>
            )}
            {member &&
              member.showMemberPrice &&
              showMemberColumn &&
              showMemberColumn && (
                <Price
                  className={memberClassNames}
                  style={{
                    ...member.style,
                    color: priceMessage ? "#666666" : ""
                  }}
                  data-testid="price-for-member-6"
                  strikethrough={member?.strikethrough!}
                  {...genericProps}
                >
                  {currency
                    ? member.amount
                    : formatCurrencyPrice(member.amount)}
                </Price>
              )}
            {contract && (
              <Price
                className={memberClassNames}
                style={contract.style}
                data-testid={`price-for-contract`}
                strikethrough={contract?.strikethrough!}
                {...genericProps}
              >
                {currency
                  ? contract.amount
                  : formatCurrencyPrice(contract.amount)}
              </Price>
            )}
            {trade && (
              <Price
                className={memberClassNames}
                style={trade.style}
                data-testid={`price-for-trade`}
                strikethrough={trade?.strikethrough!}
                {...genericProps}
              >
                {currency ? trade.amount : formatCurrencyPrice(trade.amount)}
              </Price>
            )}
            {isFEATURE_ADDON_MESSAGING && discountAmount && (
              <Price
                style={{
                  color: "#CA2022",
                  fontWeight: 100
                }}
                className={memberClassNames}
                data-testid={`price-for-trade`}
                strikethrough={trade?.strikethrough!}
                {...genericProps}
              >
                {currency
                  ? discountAmount / 100
                  : formatCurrencyPrice(discountAmount / 100)}
              </Price>
            )}
          </Grid>
          {/* LABEL COLUMN */}
          <Grid item>
            {regular && (
              <PriceLabel
                className={`${localClasses.regularPriceText} ${regularClassNames}`}
                style={regular.style}
              >
                {regular.label || pageContent?.REGULAR_PRICE_TAG}
              </PriceLabel>
            )}
            {sale && (
              <PriceLabel className={saleClassNames} style={sale.style}>
                {sale.label || pageContent?.SALE_PRICE_TAG}
              </PriceLabel>
            )}
            {finalSale && (
              <PriceLabel
                className={`${localClasses.finalSaleNumber} ${saleClassNames}`}
                style={finalSale.style}
              >
                {pageContent?.SALE_PRICE_TAG}
              </PriceLabel>
            )}
            {member &&
              member.showMemberPrice &&
              showMemberColumn &&
              showMemberColumn && (
                <PriceLabel className={memberClassNames} style={member.style}>
                  {member.label || pageContent?.MEMBER_PRICE_TAG}
                </PriceLabel>
              )}
            {contract && (
              <PriceLabel className={memberClassNames} style={contract.style}>
                {contract.label || pageContent?.CONTRACT_PRICE_TAG}
              </PriceLabel>
            )}
            {trade && (
              <PriceLabel className={memberClassNames} style={trade.style}>
                {trade.label || pageContent?.TRADE_PRICE_TAG}
              </PriceLabel>
            )}
            {isFEATURE_ADDON_MESSAGING && discountAmount && (
              <PriceLabel
                style={{
                  color: "#CA2022",
                  fontWeight: 100
                }}
              >
                {pageContent?.DISCOUNT}
              </PriceLabel>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export const RHRPriceSeparator = memoize(() => {
  const typographyClasses = useTypographyStyles({
    keys: ["rhBaseCaption"]
  });

  return (
    <Typography className={typographyClasses.rhBaseCaption} display="inline">
      {" / "}
    </Typography>
  );
});

export const RHRUniversalPriceRangeDisplay: FC<
  Omit<PriceRangeDisplayProps, "data"> & {
    isCartPage?: boolean;
    onSale?: boolean;
    topLabel?: string;
    range: PriceRange;
    /** @deprecated use currency */
    currencyLabel?: string;
    /** @deprecated use currency */
    currencySymbol?: string;
    showCents?: boolean;
    priceMessage?: string;
    priceMessagePrice?: number;
    showSaleMessage?: boolean;
    emptyProduct?: boolean;
    memberPrice?: number;
    isMetaProduct?: boolean;
    nextGenDrivenOnSale?: boolean;
    metaProductComponent?: boolean;
    skuOnSalePercentage?: number;
  }
> = ({
  topLabel,
  range: {
    regular,
    sale,
    finalSale,
    member,
    trade,
    contract,
    lowestSkuMemberPrice,
    lowestSkuRegularPrice,
    lowestSkuSalePrice
  } = {},
  currencyLabel,
  currencySymbol,
  currency,
  showCents = false,
  variant,
  priceMessage,
  priceMessagePrice,
  isCartPage,
  showSaleMessage,
  emptyProduct,
  memberPrice,
  isMetaProduct,
  nextGenDrivenOnSale,
  onViewSelectItemsOnSaleClick,
  metaProductComponent,
  skuOnSalePercentage
}) => {
  const env = useEnv();
  const isSSRToggledWithCSR = isSSRToggledWithClientRender();
  const { pageContent } = usePageContent();
  const typographyClasses = useTypographyStyles({
    keys: [
      "priceRange",
      "priceRangeSmall",
      "priceRangeBold",
      "priceRangeSale",
      "priceRangeRegular",
      "priceRangeMember",
      "priceRangeCaptionSmall",
      "rhBaseBody2",
      "rhBaseBody1",
      "rhBaseCaption",
      "priceViewSaleLink",
      "priceViewSaleLinkBtn",
      "priceViewSale"
    ]
  });
  const localClasses = useStyles();
  const genericProps = {
    currencyLabel,
    currencySymbol,
    currency,
    showCents
  };
  const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));

  const showMemberColumn = countries?.[getCountryFromUrl() || "US"]?.member;

  const fontStyle = useMemo<CSSProperties>(() => {
    let style: CSSProperties = {
      fontFamily: "RHSans-Thin",
      fontWeight: "none",
      lineHeight: "1.5rem"
    };

    if (variant === "small") {
      style = { ...style, fontSize: 11 };
    } else if (variant === "medium") {
      style = {
        ...style,
        fontSize: 13
      };
    } else {
      style = { ...style, fontSize: 13 };
    }

    return style;
  }, [variant]);

  const regularClassNames = classNames(
    typographyClasses.priceRange,
    { [typographyClasses.priceRangeSale]: false },
    variant === "small" && !mdUp ? typographyClasses.priceRangeSmall : "",
    typographyClasses.rhBaseBody1
  );
  const saleClassNames = classNames({
    [typographyClasses.priceRangeSmall]: variant === "small" && !mdUp,
    [typographyClasses.priceRangeSale]: true,
    [typographyClasses.rhBaseBody1]: true
  });
  const regularSaleClassNames = classNames(typographyClasses.priceRangeMember, {
    [typographyClasses.priceRangeSmall]: variant === "small" && !mdUp,
    [typographyClasses.priceRangeSale]: false,
    [typographyClasses.rhBaseBody2]: true
  });
  const memberClassNames = classNames(typographyClasses.priceRangeMember, {
    [typographyClasses.priceRangeSmall]: variant === "small" && !mdUp,
    [typographyClasses.priceRangeSale]: false,
    [typographyClasses.rhBaseBody2]: true
  });

  const formatCurrencyPrice = price => {
    let currencyCode = Boolean(env.FEATURE_INTERNATIONAL)
      ? countryNameMapper[getCountryFromUrl() || "US"]
      : currencySymbol && currencySymbolToCurrencyCodeMapper?.[currencySymbol!];
    return formatCurrencyWithoutCS(price, currencyCode, false);
  };

  const params = useParams({
    sale: ""
  });

  const memoizedPrice = useMemo(() => {
    const membPrice =
      showSaleMessage && priceMessagePrice !== null && !isMetaProduct
        ? memberPrice
        : member?.amount;
    const isAllProductOnSale =
      skuOnSalePercentage === 100 && !showSaleMessage && !params?.sale;
    const regularPriceValue = isAllProductOnSale
      ? lowestSkuRegularPrice?.amount
      : regular?.amount;
    const salePriceValue = isAllProductOnSale
      ? lowestSkuSalePrice?.amount
      : sale?.amount;
    const memberPriceValue = isAllProductOnSale
      ? lowestSkuMemberPrice?.amount
      : membPrice;

    return {
      regularPriceValue,
      salePriceValue,
      memberPriceValue
    };
  }, [
    showSaleMessage,
    priceMessagePrice,
    isMetaProduct,
    memberPrice,
    member?.amount,
    skuOnSalePercentage,
    params?.sale,
    lowestSkuRegularPrice?.amount,
    regular?.amount,
    lowestSkuSalePrice?.amount,
    sale?.amount,
    lowestSkuMemberPrice?.amount
  ]);

  const { regularPriceValue, salePriceValue, memberPriceValue } = memoizedPrice;

  return (
    <div id="price-range-display">
      <Grid container alignItems="center" className={localClasses.container}>
        {!isCartPage && (
          <Grid item>
            <Typography
              className={classNames(
                typographyClasses.priceRange,
                {
                  [typographyClasses.priceRangeSmall]:
                    variant === "small" && !mdUp
                }
                // typographyClasses.rhBaseCaption  //conflicts font family with body1
              )}
              display="inline"
              style={
                isSSRToggledWithCSR
                  ? {
                      lineHeight: "2rem",
                      ...fontStyle
                    }
                  : {
                      textTransform: "none", // stop the capitalization
                      color: "#666666",
                      ...fontStyle
                    }
              }
            >
              {topLabel || pageContent?.FROM_TOP_LABEL}&nbsp;
            </Typography>
          </Grid>
        )}
        <Grid item>
          {member && member.showMemberPrice && showMemberColumn && (
            <>
              <Price
                className={classNames(memberClassNames, {
                  [typographyClasses?.priceRangeSale]: finalSale || sale
                })}
                display="inline"
                style={{
                  ...fontStyle,
                  ...member.style
                }}
                data-testid="price-for-member-7"
                {...genericProps}
              >
                {currency
                  ? memberPriceValue
                  : formatCurrencyPrice(memberPriceValue)}
              </Price>{" "}
              <PriceLabel
                className={classNames(memberClassNames, {
                  [typographyClasses?.priceRangeSale]: finalSale || sale
                })}
                display="inline"
                style={{
                  ...fontStyle,
                  ...member.style
                }}
              >
                {MEMBER_PRICE_TAG}
              </PriceLabel>
            </>
          )}
          {regular && (
            <span id="regularPrice">
              <RHRPriceSeparator />
              <Price
                className={regularClassNames}
                display="inline"
                style={{
                  ...fontStyle,
                  ...regular?.style
                }}
                data-testid={`price-for-regular`}
                {...genericProps}
              >
                {currency
                  ? regularPriceValue
                  : formatCurrencyPrice(regularPriceValue)}
              </Price>{" "}
              <PriceLabel
                className={classNames(memberClassNames, {
                  [typographyClasses?.priceRangeSale]: finalSale || sale
                })}
                display="inline"
                style={{
                  ...fontStyle
                }}
              >
                {REGULAR_PRICE_TAG}
              </PriceLabel>
            </span>
          )}
          {sale && (
            <>
              <RHRPriceSeparator />
              <Price
                className={classNames(
                  regularSaleClassNames,
                  typographyClasses?.priceRangeSale
                )}
                display="inline"
                style={{ ...fontStyle, ...sale.style }}
                data-testid={`price-for-sale`}
                {...genericProps}
              >
                {currency
                  ? salePriceValue
                  : formatCurrencyPrice(salePriceValue)}
              </Price>{" "}
              <PriceLabel
                className={classNames(
                  regularSaleClassNames,
                  typographyClasses?.priceRangeSale
                )}
                display="inline"
                style={{ ...fontStyle, ...sale.style }}
              >
                {pageContent?.SALE_PRICE_TAG}
              </PriceLabel>
            </>
          )}
          {finalSale && (
            <>
              <RHRPriceSeparator />
              <Price
                {...genericProps}
                display="inline"
                className={classNames(
                  saleClassNames,
                  typographyClasses?.priceRangeSale
                )}
                style={{ ...fontStyle, ...finalSale.style }}
                data-testid={`price-for-final-sale`}
              >
                {currency
                  ? finalSale.amount
                  : formatCurrencyPrice(finalSale.amount)}
              </Price>{" "}
              <PriceLabel
                className={classNames(
                  saleClassNames,
                  typographyClasses?.priceRangeSale
                )}
                display="inline"
                style={{ ...fontStyle, ...finalSale.style }}
              >
                {pageContent?.SALE_PRICE_TAG}
              </PriceLabel>
            </>
          )}
          {member && member.showMemberPrice && showMemberColumn && (
            <>
              <RHRPriceSeparator />
              <Price
                className={classNames(memberClassNames, {
                  [typographyClasses?.priceRangeSale]: finalSale || sale
                })}
                display="inline"
                style={{ ...fontStyle, ...member.style }}
                data-testid="price-for-member-8"
                {...genericProps}
              >
                {currency
                  ? memberPriceValue
                  : formatCurrencyPrice(memberPriceValue)}
              </Price>{" "}
              <PriceLabel
                className={classNames(memberClassNames, {
                  [typographyClasses?.priceRangeSale]: finalSale || sale
                })}
                display="inline"
                style={{ ...fontStyle, ...member.style }}
              >
                {pageContent?.MEMBER_PRICE_TAG}
              </PriceLabel>
            </>
          )}
          {contract && (
            <>
              <RHRPriceSeparator />
              <Price
                className={classNames(memberClassNames, {
                  [typographyClasses?.priceRangeSale]: finalSale || sale
                })}
                display="inline"
                style={{ ...fontStyle, ...contract.style }}
                data-testid={`price-for-contract`}
                {...genericProps}
              >
                {currency
                  ? contract.amount
                  : formatCurrencyPrice(contract.amount)}
              </Price>{" "}
              <PriceLabel
                className={classNames(memberClassNames, {
                  [typographyClasses?.priceRangeSale]: finalSale || sale
                })}
                display="inline"
                style={{ ...fontStyle, ...contract.style }}
              >
                {contract.label || pageContent?.CONTRACT_PRICE_TAG}
              </PriceLabel>
            </>
          )}
          {trade && (
            <>
              <RHRPriceSeparator />
              <Price
                className={classNames(memberClassNames, {
                  [typographyClasses?.priceRangeSale]: finalSale || sale
                })}
                display="inline"
                style={{ ...fontStyle, ...trade.style }}
                data-testid={`price-for-trade`}
                {...genericProps}
              >
                {currency ? trade?.amount : formatCurrencyPrice(trade?.amount)}
              </Price>{" "}
              <PriceLabel
                className={classNames(memberClassNames, {
                  [typographyClasses?.priceRangeSale]: finalSale || sale
                })}
                display="inline"
                style={{ ...fontStyle, ...trade.style }}
              >
                {trade.label || pageContent?.TRADE_PRICE_TAG}
              </PriceLabel>
            </>
          )}
          {priceMessage && emptyProduct && nextGenDrivenOnSale && (
            <>
              <RHRPriceSeparator />
              <PriceLabel
                variant={"caption"}
                display="inline"
                className={classNames(
                  {
                    [typographyClasses.priceRangeCaptionSmall]:
                      variant === "small"
                  },
                  typographyClasses.priceRangeSale
                )}
                style={fontStyle}
              >
                {`${pageContent?.SELECT_ITEMS_ON_SALE_STARTING_AT_TAG} `}
              </PriceLabel>
              <Price
                variant={"caption"}
                display="inline"
                className={classNames(
                  {
                    [typographyClasses.priceRangeCaptionSmall]:
                      variant === "small"
                  },
                  typographyClasses.priceRangeSale
                )}
                style={fontStyle}
                {...genericProps}
              >
                {currency
                  ? priceMessagePrice ?? sale?.amount
                  : formatCurrencyPrice(priceMessagePrice ?? sale?.amount)}
              </Price>
            </>
          )}
        </Grid>
      </Grid>
      {priceMessagePrice && !emptyProduct && nextGenDrivenOnSale && (
        <Grid>
          <Grid container className={localClasses.container}>
            <Grid item>
              <ButtonBase
                disableRipple
                onClick={onViewSelectItemsOnSaleClick}
                disabled={!onViewSelectItemsOnSaleClick}
                className={typographyClasses.priceViewSaleLinkBtn}
              >
                <Typography
                  className={classNames(
                    typographyClasses.rhBaseBody1,
                    {
                      [typographyClasses.priceRangeSmall]:
                        variant === "small" && !mdUp
                    },
                    !onViewSelectItemsOnSaleClick
                      ? typographyClasses.priceViewSale
                      : typographyClasses.priceViewSaleLink
                  )}
                  style={{
                    ...fontStyle,
                    lineHeight: isSSRToggledWithCSR ? "2rem" : ""
                  }}
                >
                  {!onViewSelectItemsOnSaleClick
                    ? pageContent?.SELECT_ITEM_ON_SALE
                    : pageContent?.VIEW_ITEM_ON_SALE}
                </Typography>
              </ButtonBase>
            </Grid>
          </Grid>
          {!metaProductComponent && (
            <Grid container className={localClasses.container}>
              {!isCartPage && (
                <Grid item>
                  <Typography
                    className={classNames(
                      typographyClasses.priceRange,
                      {
                        [typographyClasses.priceRangeSmall]:
                          variant === "small" && !mdUp
                      },
                      typographyClasses.rhBaseCaption
                    )}
                    style={
                      isSSRToggledWithCSR
                        ? {
                            lineHeight: "2rem",
                            ...fontStyle
                          }
                        : {
                            textTransform: "none", // stop the capitalization
                            color: "#666666",
                            ...fontStyle
                          }
                    }
                  >
                    {topLabel || pageContent?.FROM_TOP_LABEL}&nbsp;
                  </Typography>
                </Grid>
              )}

              <Grid item>
                {lowestSkuRegularPrice && (
                  <Price
                    className={regularClassNames}
                    display="inline"
                    style={{ ...fontStyle, ...regular?.style }}
                    data-testid={`price-for-regular`}
                    {...genericProps}
                  >
                    {formatCurrencyPrice(lowestSkuRegularPrice?.amount)}
                  </Price>
                )}

                <>
                  <RHRPriceSeparator />
                  <PriceLabel
                    variant={"caption"}
                    display="inline"
                    className={classNames(
                      {
                        [typographyClasses.priceRangeCaptionSmall]:
                          variant === "small"
                      },
                      typographyClasses.priceRangeSale
                    )}
                    style={fontStyle}
                  >
                    {`
        ${
          priceMessagePrice && lowestSkuSalePrice
            ? `${currencySymbol}${formatCurrencyPrice(
                lowestSkuSalePrice?.amount
              )}`
            : ""
        }
        ${SALE_PRICE_TAG}
      `}
                  </PriceLabel>
                </>

                {member &&
                  member.showMemberPrice &&
                  showMemberColumn &&
                  showMemberColumn &&
                  lowestSkuMemberPrice && (
                    <>
                      <RHRPriceSeparator />
                      <Price
                        className={classNames(memberClassNames, {
                          [typographyClasses?.priceRangeSale]: true
                        })}
                        display="inline"
                        style={{ ...fontStyle, ...member.style }}
                        data-testid="price-for-member-9"
                        {...genericProps}
                      >
                        {formatCurrencyPrice(lowestSkuMemberPrice?.amount)}
                      </Price>{" "}
                      <PriceLabel
                        className={classNames(memberClassNames, {
                          [typographyClasses?.priceRangeSale]: true
                        })}
                        display="inline"
                        style={{ ...fontStyle, ...member.style }}
                      >
                        {pageContent?.MEMBER_PRICE_TAG}
                      </PriceLabel>
                    </>
                  )}
                {contract && (
                  <>
                    <RHRPriceSeparator />
                    <Price
                      className={classNames(memberClassNames, {
                        [typographyClasses?.priceRangeSale]: finalSale || sale
                      })}
                      display="inline"
                      style={{ ...fontStyle, ...contract.style }}
                      data-testid={`price-for-contract`}
                      {...genericProps}
                    >
                      {formatCurrencyPrice(contract?.amount)}
                    </Price>{" "}
                    <PriceLabel
                      className={classNames(memberClassNames, {
                        [typographyClasses?.priceRangeSale]: finalSale || sale
                      })}
                      display="inline"
                      style={{ ...fontStyle, ...contract.style }}
                    >
                      {contract.label || pageContent?.CONTRACT_PRICE_TAG}
                    </PriceLabel>
                  </>
                )}
                {trade && (
                  <>
                    <RHRPriceSeparator />
                    <Price
                      className={classNames(memberClassNames, {
                        [typographyClasses?.priceRangeSale]: true
                      })}
                      display="inline"
                      style={{ ...fontStyle, ...trade.style }}
                      data-testid={`price-for-trade`}
                      {...genericProps}
                    >
                      {formatCurrencyPrice(trade?.amount)}
                    </Price>{" "}
                    <PriceLabel
                      className={classNames(memberClassNames, {
                        [typographyClasses?.priceRangeSale]: true
                      })}
                      display="inline"
                      style={{ ...fontStyle, ...trade.style }}
                    >
                      {trade.label || pageContent?.TRADE_PRICE_TAG}
                    </PriceLabel>
                  </>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </div>
  );
};

export const PriceRangeDisplay: FC<PriceRangeDisplayProps> = ({
  data,
  variant,
  topLabel,
  display = "block",
  currency,
  showSaleMessage = false,
  emptyProduct,
  memberPrice,
  onViewSelectItemsOnSaleClick,
  skulSalePrice,
  isMetaProduct,
  nextGenDrivenOnSale,
  metaProductComponent,
  skuOnSalePercentage,
  onSale,
  isFromRegistry = false
}) => {
  const cookies = useIsoCookies();
  const selectedAccount = cookies["selected_customer"];
  let { userType = selectedAccount } = useRhUserAtomValue();

  const { pageContent } = usePageContent();

  const range = useMemo((): PriceRange => {
    return convertProductPriceRangeDisplayToPriceRange(
      data,
      userType,
      isMetaProduct,
      onSale
    );
  }, [data, isMetaProduct, skulSalePrice, userType, onSale]);
  const STARTING_AT_PRICE = pageContent?.["STARTING_AT"];

  const getCurrencyLabel = useCallback(() => {
    const latestCookie = parseUserContextCookie(getCookie("userContext"));
    return latestCookie?.currencyCode === "CAD"
      ? ""
      : data?.currencyLabel ?? "";
  }, [data]);

  return display === "block" ? (
    <UniversalPriceRangeDisplay
      topLabel={
        topLabel ??
        (data?.overridePriceLabel ||
          (data?.hasOnlyOneSku ? "" : pageContent?.FROM))
      }
      range={range}
      currencyLabel={
        userType === "CONTRACT" ? getCurrencyLabel() : data?.currencyLabel ?? ""
      }
      currencySymbol={data.currencySymbol!}
      currency={currency}
      variant={variant}
      priceMessage={data.priceMessage!}
      priceMessagePrice={data.priceMessagePrice!}
      memberPrice={memberPrice}
      isFromRegistry
    />
  ) : (
    <>
      <RHRUniversalPriceRangeDisplay
        topLabel={
          topLabel ??
          (data?.overridePriceLabel ||
            (data?.hasOnlyOneSku ? "" : pageContent?.FROM))
        }
        range={range}
        currencyLabel={
          userType === "CONTRACT"
            ? getCurrencyLabel()
            : data?.currencyLabel ?? ""
        }
        currencySymbol={data.currencySymbol!}
        currency={currency}
        variant={variant}
        priceMessage={data.priceMessage!}
        showSaleMessage={showSaleMessage}
        emptyProduct={emptyProduct}
        priceMessagePrice={
          data.priceMessage
            ? data?.priceMessagePrice ?? data?.salePrices?.[0]
            : data?.priceMessagePrice!
        }
        onViewSelectItemsOnSaleClick={onViewSelectItemsOnSaleClick}
        memberPrice={memberPrice}
        metaProductComponent={metaProductComponent}
        nextGenDrivenOnSale={nextGenDrivenOnSale}
        skuOnSalePercentage={skuOnSalePercentage}
        isMetaProduct={isMetaProduct}
      />
    </>
  );
};

PriceRangeDisplay.defaultProps = {
  variant: "default"
};

export default memoize(PriceRangeDisplay);
