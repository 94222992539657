import { PropTypes } from "aem/ComponentWrapper";

export default {
  title: "RH Open Table Reservation",
  type: "rh/components/content/opentable",
  config: {
    emptyLabel: "RH Open Table Reservation",
    isEmpty: ({}: PropTypes) => true
  }
};
