import React from "react";

export type NotificationVariant = "warning" | "success" | "error";
export type NotificationContent = string | React.ReactNode;
export type ErrorInfo = {
  trace_id: string;
  timestamp: string;
};

export interface ProviderContext {
  showNotificationModal: (
    title: string,
    content?: NotificationContent,
    variant?: NotificationVariant,
    errorInfo?: ErrorInfo
  ) => void;
  closeNotificationModal: (onClose?: () => any) => void;
}

export default React.createContext<ProviderContext>({
  showNotificationModal: () => {},
  closeNotificationModal: () => {}
});
