import React, { FC } from "react";
import RHTextField, { RHTextFieldProps } from "component-rh-text-field";
import { makeStyles, createStyles, Theme } from "utils/material-ui-core";
const deepmerge = require("@material-ui/utils").deepmerge; // no type definitions for this package exist

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    underline: {
      paddingBottom: 0,
      "&:before": {
        borderBottom: "1px solid #CCCCCC"
      },
      "&:hover:not(.Mui-disabled):before": {
        borderBottom: "1px solid #CCCCCC"
      },
      "&:after": {
        borderBottom: "1px solid #CCCCCC"
      }
    },
    root: {
      color: "#666666",
      backgroundColor: "#f9f7f4 !important",
      paddingLeft: "18px",
      paddingRight: "18px",
      "&:focus": {
        backgroundColor: "#f9f7f4 !important"
      }
    },
    icon: {
      right: 0
    }
  })
);

export const RHStandardSelect: FC<RHTextFieldProps> = props => {
  const classes = useStyles();

  const mergedProps = deepmerge(
    {
      margin: "none",
      select: true,
      variant: "standard",
      InputProps: {
        classes: {
          underline: classes.underline
        }
      },
      SelectProps: {
        classes: {
          root: classes.root,
          icon: classes.icon
        }
      }
    },
    props
  );

  return <RHTextField {...mergedProps}>{props.children}</RHTextField>;
};

export default RHStandardSelect;
