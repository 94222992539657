import { makeStyles, createStyles } from "@material-ui/core";
import React, {
  Children,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import styled from "styled-components";
import horizontalScroll from "./assets/horizontal-scroll.svg";
import { StyleProps } from "./types";
import {
  BREAKPOINT_LG,
  BREAKPOINT_MD,
  BREAKPOINT_SM,
  BREAKPOINT_XL,
  BREAKPOINT_XS
} from "utils/constants";
import ArrowCarouselIcon from "icon-arrow-carousel";
import memoize from "utils/memoize";
import { isMobile } from "utils/deviceDetect";

const DEFAULT_GAP = 20;

const useStyles = (props: StyleProps) =>
  makeStyles(theme => {
    const { xs, sm, md, lg, xl } = props;

    return createStyles({
      carouselArrowButton: {
        cursor: "pointer",
        lineHeight: "12px",
        opacity: 0.8,
        position: "absolute",
        top: "calc(50% - 23px)",
        width: "auto",
        zIndex: 10,
        transition: "opacity 0.5s ease",
        "&:hover": {
          opacity: 1
        },
        "&.hidden": {
          display: "none"
        },
        "&.show-progress": {
          top: "calc(50% - 50px)"
        }
      },
      carouselContainer: {
        display: "flex",
        flexWrap: "nowrap",
        overflowX: "scroll",
        scrollBehavior: "smooth",
        cursor: `url(${horizontalScroll})`,
        "&::-webkit-scrollbar": {
          display: "none"
        },
        [theme.breakpoints.up(BREAKPOINT_XS)]: {
          margin: "0 16px"
        },
        [theme.breakpoints.up(BREAKPOINT_SM)]: {
          margin: "0 32px"
        },
        [theme.breakpoints.up(BREAKPOINT_MD)]: {
          margin: "0 40px"
        },
        [theme.breakpoints.up(BREAKPOINT_XL)]: {
          margin: "0 80px"
        },
        "& img": {
          maxWidth: "none"
        },
        "&.concept": {
          height: "52.875vw"
        },
        "&.zigzag": {
          [theme.breakpoints.up(BREAKPOINT_XS)]: {
            height: "66vw"
          },
          [theme.breakpoints.up(BREAKPOINT_MD)]: {
            height: "42.75vw"
          }
        }
      },
      carouselSlide: {
        margin: 0,
        boxSizing: "border-box",
        flex: "0 0 auto",
        width: "100%",
        scrollSnapAlign: "center",
        marginRight: DEFAULT_GAP,
        [theme.breakpoints.up(BREAKPOINT_XS)]: {
          marginRight: xs?.marginRight,
          maxWidth: "calc(100vw - 32px)"
        },
        [theme.breakpoints.up(BREAKPOINT_SM)]: {
          marginRight: sm?.marginRight,
          maxWidth: "calc(100vw - 64px)"
        },
        [theme.breakpoints.up(BREAKPOINT_MD)]: {
          marginRight: md?.marginRight,
          maxWidth: "calc(100vw - 80px)"
        },
        [theme.breakpoints.up(BREAKPOINT_LG)]: {
          marginRight: lg?.marginRight
        },
        [theme.breakpoints.up(BREAKPOINT_XL)]: {
          marginRight: xl?.marginRight,
          maxWidth: "calc(100vw - 160px)"
        },
        "& img": {
          width: "auto !important",
          maxHeight: "52.875vw"
        },
        "& div": {
          maxHeight: "52.875vw"
        },
        "&.zigzag": {
          "& img": {
            [theme.breakpoints.up(BREAKPOINT_XS)]: {
              maxHeight: "66vw"
            },
            [theme.breakpoints.up(BREAKPOINT_MD)]: {
              maxHeight: "42.75vw"
            }
          },
          "& div": {
            [theme.breakpoints.up(BREAKPOINT_XS)]: {
              maxHeight: "66vw"
            },
            [theme.breakpoints.up(BREAKPOINT_MD)]: {
              maxHeight: "42.75vw"
            }
          }
        }
      },
      carouselProgress: {
        display: "flex",
        width: "80%",
        margin: "0px auto",
        "&.hidden": {
          width: "0"
        },
        "& div": {
          width: "100%",
          cursor: "pointer",
          "& span.progress-segment": {
            display: "block",
            margin: "10px 0",
            background: "#ddd",
            transition: "background-color .8s ease-in-out",
            height: "1px",
            [theme.breakpoints.up(BREAKPOINT_XS)]: {
              margin: "32px 0 0 0"
            },
            [theme.breakpoints.up(BREAKPOINT_MD)]: {
              margin: "40px 0 0 0"
            }
          }
        },
        "& div.active": {
          "& span.progress-segment": {
            background: "#000",
            transition: "background-color .8s ease-in-out"
          }
        }
      }
    });
  });

const GridRoot = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  position: relative;
`;

export type RHRCarouselProps = {
  collpaseSlidesInEditMode?: boolean;
  children?: any;
  styles?: any;
  showArrows?: boolean;
  carouselType?: string;
  autoAdvance?: number;
  showProgressBar?: boolean;
  showMobileArrow?: boolean;
  loadingHeight: string;
};

export const RHRCarousel: FC<RHRCarouselProps> = props => {
  const scrollEl = useRef(null);
  const [slideIndex, setSlideIndex] = useState(0);
  const [firstSlide, setFirstSlide] = useState(!slideIndex && "hidden");
  const [lastSlide, setLastSlide] = useState("");
  const {
    children,
    styles,
    showArrows,
    showProgressBar,
    carouselType,
    autoAdvance,
    loadingHeight
  } = props;
  const childrenArray = Children.toArray(children);
  const classes = useStyles(styles)();
  const showProgressClass = showProgressBar ? "show-progress" : "hide-progress";
  const timeoutRef = useRef(null);
  const touchCoordsRef = useRef({ touchStart: { x: 0, y: 0 } });

  const handleNextClick = useCallback(() => {
    slideTo(slideIndex + 1);
  }, [slideIndex]);

  const handlePreviousClick = useCallback(() => {
    slideTo(slideIndex - 1);
  }, [slideIndex]);

  const slideTo = index => {
    const el = scrollEl.current.children[index];
    const elCenter = el.offsetLeft + el.offsetWidth / 2;
    const elParentCenter =
      el.parentNode.offsetLeft + el.parentNode.offsetWidth / 2;

    el.parentNode.scrollLeft = elCenter - elParentCenter;
    setSlideIndex(slideIndex => index);
    setFirstSlide(index === 0 ? "hidden" : "");

    if (isMobile()) {
      setLastSlide(index >= childrenArray.length - 2 ? "hidden" : "");
    } else {
      setLastSlide(index >= childrenArray.length - 1 ? "hidden" : "");
    }
    const nextElIndex = index + 1;
    const nextEl = scrollEl.current.children[nextElIndex];
    loadNextImage(nextEl, nextElIndex);
  };

  const loadNextImage = (elem: HTMLElement, ind: number) => {
    if (elem && ind < childrenArray.length && carouselType === "zigzag") {
      const imgElems = elem.querySelectorAll("img");
      for (let i = 0; i < imgElems.length; i++) {
        if (imgElems[i]) {
          imgElems[i].loading = "eager";
        }
      }
    }
  };

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  useEffect(() => {
    if (autoAdvance && slideIndex < childrenArray.length) {
      resetTimeout();
      timeoutRef.current = setTimeout(() => handleNextClick(), autoAdvance);

      return () => {
        resetTimeout();
      };
    }
  }, [slideIndex]);

  useEffect(() => {
    if (scrollEl.current) {
      setTimeout(() => {
        const nextElIndex = slideIndex + 1;
        const nextEl = scrollEl.current.children[nextElIndex];
        loadNextImage(nextEl, nextElIndex);
      }, 700);
    }
  }, []);

  const setMediaRef = ref => {
    if (ref) {
      scrollEl.current = ref;
      const slider = scrollEl.current;
      let isDown = false;
      let startX;
      let scrollLeft;

      slider.addEventListener("mousedown", e => {
        isDown = true;
        slider.classList.add("active");
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
        cancelMomentumTracking();
      });

      slider.addEventListener("mouseleave", () => {
        isDown = false;
        slider.classList.remove("active");
      });

      slider.addEventListener("mouseup", () => {
        isDown = false;
        slider.classList.remove("active");
        beginMomentumTracking();
      });

      slider.addEventListener("mousemove", e => {
        e.preventDefault();
        if (!isDown) return;
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * 3; //scroll-fast
        const prevScrollLeft = slider.scrollLeft;
        slider.scrollLeft = scrollLeft - walk;
        velX = slider.scrollLeft - prevScrollLeft;
      });

      // Momentum
      let velX = 0;
      let momentumID;

      slider.addEventListener("wheel", e => {
        cancelMomentumTracking();
      });

      slider.addEventListener("touchstart", (event: React.TouchEvent): void => {
        touchCoordsRef.current.touchStart.x = event.targetTouches[0].clientX;
        touchCoordsRef.current.touchStart.y = event.targetTouches[0].clientY;
      });

      slider.addEventListener(
        "touchend",
        (event: React.TouchEvent & { view: Window }): void => {
          const touchEndX = event.changedTouches[0].clientX;
          const touchEndY = event.changedTouches[0].clientY;
          const touchStartX = touchCoordsRef.current.touchStart.x;
          const touchStartY = touchCoordsRef.current.touchStart.y;
          const xDistance = touchStartX - touchEndX;
          const yDistance = touchStartY - touchEndY;

          if (Math.abs(xDistance) >= Math.abs(yDistance)) {
            const swipeDistance = slider.scrollLeft ?? 1;
            const innerWidth = event?.view?.innerWidth ?? 1;
            const pageIndex = Math.abs(Math.round(swipeDistance / innerWidth));
            slideTo(pageIndex);
          }
        }
      );

      const beginMomentumTracking = function () {
        cancelMomentumTracking();
        momentumID = requestAnimationFrame(momentumLoop);
      };

      const cancelMomentumTracking = function () {
        cancelAnimationFrame(momentumID);
      };

      const momentumLoop = function () {
        slider.scrollLeft += velX;
        velX *= 0.95;
        if (Math.abs(velX) > 0.8) {
          momentumID = requestAnimationFrame(momentumLoop);
        }
      };
    }
  };
  // Set the loading height to match image size to prevent relayout after SSR
  const setLoadingHeight = loadingHeight ? { height: loadingHeight } : {};
  // flex basis must be the aspect ratio relative to the first images height on a 100% grid.
  return (
    <GridRoot
      style={{ display: "block", ...setLoadingHeight }}
      id={"component-rhr-carousel"}
    >
      {showArrows && (
        <>
          <div
            className={`${firstSlide} ${classes.carouselArrowButton} ${showProgressClass}`}
            onClick={handlePreviousClick}
            style={{
              left: 0
            }}
          >
            <ArrowCarouselIcon className="prev-arrow arrow-icon" />
          </div>
          <div
            className={`${lastSlide} ${classes.carouselArrowButton} ${showProgressClass}`}
            onClick={handleNextClick}
            style={{
              right: 0
            }}
          >
            <ArrowCarouselIcon
              className="next-arrow arrow-icon"
              style={{
                transform: "rotate(180deg)"
              }}
            />
          </div>
        </>
      )}
      <div
        ref={setMediaRef}
        className={`${classes.carouselContainer} ${carouselType}`}
      >
        {childrenArray.map((child, index) => (
          <div
            className={`${classes.carouselSlide} ${index} ${
              slideIndex === index && "active"
            } ${carouselType}`}
            key={index}
            data-slidePosition={"Slide" + index}
          >
            {child}
          </div>
        ))}
      </div>
      {showProgressBar && (
        <div className={`${classes.carouselProgress}`}>
          {childrenArray.map((slide, index) => (
            <div
              className={slideIndex === index && "active"}
              onClick={() => {
                slideTo(index);
              }}
              key={index}
            >
              <span className="progress-segment"></span>
            </div>
          ))}
        </div>
      )}
    </GridRoot>
  );
};

RHRCarousel.defaultProps = {
  styles: {}
};

export default memoize(RHRCarousel);
