import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  arrowIcon: {
    width: "16px",
    height: "16px",
    flexDhrink: "0"
  },
  "@media (max-width: 992px)": {
    List: {
      marginTop: "16px"
    }
  },
  dropdownCustom: {
    "&:before": {
      content: '""',
      position: "absolute",
      background: "#f1f0ed",
      left: "48.4%",
      top: "-5px",
      transform: "rotate(45deg) translateX(-50%)",
      width: "25px",
      height: "25px"
    }
  },
  ItemWrapper: {
    "&:hover #dropdownCustom": {
      display: "grid"
    }
  },
  List: {
    "& li button": {
      fontSize: "13px",
      fontWeight: 300,
      lineHeight: "20px",
      background: "transparent",
      border: "none",
      display: "flex",
      alignItems: "center",
      gap: "0px",
      cursor: "pointer",
      padding: "0",
      "&:first-child button": {
        marginRight: "32px"
      },
      "&:last-child button": {
        marginLeft: "32px"
      }
    }
  },
  ellipses: {
    "& svg": {
      display: "flex"
    }
  },
  MobileListWrapper: {
    display: "inherit",
    height: "auto",
    "& .List": {
      paddingTop: "67px",
      display: "flex",
      justifyContent: "space-between"
    }
  }
});

export default useStyles;
