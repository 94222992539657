import React, { forwardRef } from "react";
import {
  Button,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery
} from "utils/material-ui-core";
import { useIsoCookies } from "hooks/useIsoCookies";
import {
  COUNTRY_COOKIE,
  LANGUAGE_COOKIE,
  MEASUREMENT_COOKIE
} from "utils/constants";
import RHDropdownIcon from "icon-dropdown";
import { shortNamesLanguageMeasurement } from "resources/countries-config.json";
import { CHECKOUT_HEADER_FOOTER } from "resources/page-level-resources-schemas/checkout-sign-in-register.json";
import getCountryFromUrl from "utils/getCountryFromUrl";
import cn from "classnames";
import RHRFlagIcon from "icons/RHRFlagIcon";
import { usePageContent } from "customProviders/LocationProvider";
import { countryCodeMapper } from "@RHCommerceDev/resources/countries-config.json";
import yn from "yn";
import { useEnv } from "hooks/useEnv";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: theme.zIndex.appBar,
      width: "100%",
      resize: "both",
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.up("md")]: {
        marginBottom: theme.spacing(6)
      }
    },
    iconLink: {
      display: "flex",
      alignItems: "center",
      textTransform: "none",
      letterSpacing: 0,
      fontSize: 11,
      cursor: "pointer"
    },
    shipButton: {
      background: "none",
      border: "none",
      color: "inherit",
      "&:hover": {
        backgroundColor: "transparent",
        boxShadow: "none"
      },
      "&:focus": {
        outline: `1px solid #000`
      },
      padding: 0
    },
    dropdownIcon: {
      fontSize: theme.typography.pxToRem(10),
      width: "10px !important",
      height: "10px !important",
      marginLeft: 6,
      transition: "all .2s ease-in-out",
      transform: "rotate(0 deg)"
    },
    chevronIconOpened: {
      transform: "rotate(180 deg)"
    },
    flagIcon: {
      marginRight: 10
    },
    disabled: {
      // prevent fading color when disabled
      color: "inherit !important"
    }
  })
);

const env = useEnv();
const FEATURE_CHECKOUT_SHIPPING_BUTTON_V2 = yn(
  env.FEATURE_CHECKOUT_SHIPPING_BUTTON_V2
);

export type RHShippingButtonProps = {
  disabled?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  open?: boolean;
  showChevron?: boolean;
  mixBlendMode?: boolean;
};

export const RHShippingButton = forwardRef<
  HTMLButtonElement,
  RHShippingButtonProps
>((props, ref) => {
  const {
    disabled = false,
    onClick,
    open,
    showChevron = true,
    mixBlendMode = true
  } = props;
  const classes = useStyles(open);
  const smDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("xs"));
  const { pageContent } = usePageContent();

  const cookies = useIsoCookies([
    COUNTRY_COOKIE,
    LANGUAGE_COOKIE,
    MEASUREMENT_COOKIE
  ]);

  const shipToFlag = getCountryFromUrl() || cookies[COUNTRY_COOKIE] || "US";
  const isGB = shipToFlag == "GB";
  const shipToText = `${
    pageContent?.CHECKOUT_HEADER_FOOTER?.SHIP_TO ||
    CHECKOUT_HEADER_FOOTER?.SHIP_TO ||
    ""
  } ${
    shortNamesLanguageMeasurement[
      getCountryFromUrl() || cookies[COUNTRY_COOKIE] || "US"
    ]
  }`;

  const shipToLabel = `${
    pageContent?.CHECKOUT_HEADER_FOOTER?.SHIP_TO ||
    CHECKOUT_HEADER_FOOTER?.SHIP_TO ||
    ""
  } ${countryCodeMapper[shipToFlag]}`;

  const colorBlendMode: React.CSSProperties = mixBlendMode
    ? { mixBlendMode: "difference" }
    : { mixBlendMode: "unset" };

  return (
    <>
      {FEATURE_CHECKOUT_SHIPPING_BUTTON_V2 ? (
        <Button
          className={cn(classes.shipButton, disabled ? classes.disabled : "")}
          onClick={onClick}
          ref={ref}
          id={"component-rh-shipping-button"}
          disabled={disabled}
          aria-expanded={open}
        >
          <Typography
            color="inherit"
            component="span"
            className={classes.iconLink}
          >
            <div
              className={classNames(
                "flex flex-col xs:w-16 h-4 flex-shrink-0 mr-1",
                {
                  "sm:!w-[143px]": isGB,
                  "sm:!w-32": !isGB
                }
              )}
            >
              <div className=" text-black font-primary-rhlight text-right font-light text-[13px] text-nowrap leading-4 tracking-[0.52px]">
                {smDown ? shipToText : shipToLabel}
              </div>
            </div>

            {showChevron && (
              <div className="flex w-4 h-4 !justify-center !items-center flex-shrink-0">
                <RHDropdownIcon
                  style={colorBlendMode}
                  className={classNames(
                    "text-[10px] w-[10px] h-[10px] transition-all duration-200 ease-in-out transform rotate-0",
                    {
                      "rotate-180": open
                    }
                  )}
                />
              </div>
            )}
          </Typography>
        </Button>
      ) : (
        <Button
          className={cn(classes.shipButton, disabled ? classes.disabled : "")}
          onClick={onClick}
          ref={ref}
          id={"component-rh-shipping-button"}
          disabled={disabled}
          aria-expanded={open}
        >
          <Typography
            color="inherit"
            component="span"
            className={classes.iconLink}
          >
            <RHRFlagIcon country={shipToFlag} className={classes.flagIcon} />
            {!smDown && (
              <Typography
                style={colorBlendMode}
                color="inherit"
                component="span"
              >
                {shipToText}
              </Typography>
            )}
            {showChevron && (
              <RHDropdownIcon
                style={colorBlendMode}
                className={cn(classes.dropdownIcon, {
                  [classes.chevronIconOpened]: open
                })}
              />
            )}
          </Typography>
        </Button>
      )}
    </>
  );
});
