// import loadable from "@loadable/component";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import AEMWrapper from "aem/ComponentWrapper";
import { AEMComponentProps } from "aem/types";
import React, { FC } from "react";
import { IntersectionOptions } from "react-intersection-observer";
import { withImageStyles } from "utils/styles";

import {
  BREAKPOINT_MD,
  BREAKPOINT_SM,
  BREAKPOINT_LG,
  BREAKPOINT_XL
} from "utils/constants";
import { RHImageProps } from ".";
import meta from "./aem-meta";

// const RHImage = loadable(
//   () => import(/* webpackChunkName: "component-rh-image" */ "."),
//   {
//     fallback: <></>
//   }
// );
import RHImage from ".";

export type AEMImageProps = RHImageProps &
  AEMComponentProps & {
    hideOnDesktop?: boolean;
    hideOnMobile?: boolean;
    hideOnTablet?: boolean;
    hideOnMd?: boolean;
    hideOnLg?: boolean;
    hideOnXl?: boolean;
    enableSmartImaging?: boolean;
    imageDMURL?: string;
    s7Preset?: string;
    imageModifiers?: string;
    loadImageFromDm?: boolean;
    breakpoints?: string;
    zoomOnHover?: boolean;
  };

const inViewOptions: IntersectionOptions = {
  rootMargin: "50%"
};

const useStyles = ({
  hideOnMobile,
  hideOnTablet,
  hideOnDesktop,
  hideOnMd,
  hideOnLg,
  hideOnXl,
  zoomOnHover
}: AEMImageProps) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        [theme.breakpoints.down(BREAKPOINT_SM)]: {
          display: hideOnMobile ? "none !important" : undefined
        },
        [theme.breakpoints.between(BREAKPOINT_SM, BREAKPOINT_MD)]: {
          display: hideOnTablet ? "none !important" : undefined
        },
        [theme.breakpoints.up(BREAKPOINT_MD)]: {
          display: hideOnDesktop ? "none !important" : undefined
        },
        [theme.breakpoints.between(BREAKPOINT_MD, BREAKPOINT_LG)]: {
          display: !hideOnDesktop && hideOnMd ? "none !important" : undefined
        },
        [theme.breakpoints.between(BREAKPOINT_LG, BREAKPOINT_XL)]: {
          display: !hideOnDesktop && hideOnLg ? "none !important" : undefined
        },
        [theme.breakpoints.up(BREAKPOINT_XL)]: {
          display: !hideOnDesktop && hideOnXl ? "none !important" : undefined
        }
      },
      image: withImageStyles({ zoomOnHover })({})
    })
  );

export const AEMImage: FC<AEMImageProps> = props => {
  const { root, image } = useStyles(props)();
  let imageProps = { ...props };
  if (props.loadImageFromDm) {
    const searchParams = [];
    if (props.s7Preset) {
      searchParams.push(`$${props.s7Preset}$`);
    }
    if (props.breakpoints) {
      const breakpointsArray = props.breakpoints
        ? props.breakpoints.split(",")
        : [];
      let isBreakpointExists = false;
      for (const i in breakpointsArray) {
        let bp = parseInt(breakpointsArray[i]);
        if (bp > window.innerWidth) {
          searchParams.push(`wid=${bp}`);
          isBreakpointExists = true;
          break;
        }
      }
      if (!isBreakpointExists) {
        searchParams.push(
          `wid=${breakpointsArray[breakpointsArray.length - 1]}`
        );
      }
    }
    if (props.imageModifiers) {
      searchParams.push(props.imageModifiers);
    }
    if (props.enableSmartImaging) {
      searchParams.push(`bfc=on`);
    }

    const src = props.imageDMURL
      ? props?.imageDMURL && searchParams.length
        ? `${props?.imageDMURL}?${searchParams?.join("&")}`
        : props?.imageDMURL
      : props?.src;
    imageProps = { ...props, src, preset: null };
  }
  return (
    <RHImage
      {...imageProps}
      rootProps={{ classes: { root } }}
      inViewOptions={inViewOptions}
      disableInitialShapeEffect={true}
      imageClassName={image}
      loading="eager"
    />
  );
};

export default AEMWrapper(meta, AEMImage);
