import { PropTypes, WrapperOptions } from "aem/ComponentWrapper";
import { Children } from "react";

const meta: WrapperOptions = {
  title: "RHR Carousel",
  type: "rh/components/content/rhr-carousel",
  superType: "core/wcm/components/carousel/v1/carousel",
  isContainer: true,
  config: {
    emptyLabel: "No Slides",
    isEmpty: ({ children }: PropTypes) => Children.count(children) === 0
  }
};

export default meta;
