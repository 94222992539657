import { logImageErrors } from "@RHCommerceDev/graphql-client/queries/logImageErrors";
import { useMutation } from "@apollo/client";
import classNames from "classnames";
import { useCheckBrowserSupport } from "hooks/useCheckBrowserSupport";
import { AppEnv, useEnv } from "hooks/useEnv";
import { isSSRToggledWithClientRender } from "utils/ssrHelpers";
import { processEnvServer } from "hooks/useSsrHooks";
import useState from "hooks/useState";
import useUnsafeEffect from "hooks/useUnsafeEffect";
import React, {
  FC,
  ImgHTMLAttributes,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useContext,
  useLayoutEffect,
  useRef
} from "react";
import { InView, IntersectionOptions } from "react-intersection-observer";
import ImageSkeleton from "skeleton-image";
import EventEmitter, { COMPONENT_DID_LOAD_EVENT } from "utils/EventEmitter";
import { handleKeyboardPressWithEvent } from "utils/accessibility";
import getImageUrlWithPreset, {
  ProductImagePresetKeys
} from "utils/getImageUrlWithPreset";
import { Grid, GridProps } from "utils/material-ui-core";
import memoize from "utils/memoize";
import yn from "yn";
import { RHImageContext } from "./RHImageContext";
import DragPanImage from "./drag-pan-image";

interface ImageWrapperProps {
  env: AppEnv;
  innerRef?: React.Ref<HTMLDivElement>;
  altImage?: string;
  onMouseEnter: Function;
  onMouseLeave: Function;
  children: ReactNode;
  imageStyles?: React.CSSProperties;
  isSwatchTooltip?: string;
  isShopByRoom?: boolean;
}

const ImageWrapper: FC<ImageWrapperProps> = memoize(
  ({
    env,
    innerRef = undefined,
    altImage,
    onMouseEnter,
    onMouseLeave,
    children,
    imageStyles = {},
    isSwatchTooltip,
    isShopByRoom = false
  }) => (
    <div
      className={`${
        !isSwatchTooltip
          ? `flex items-center justify-center `
          : `flex justify-center `
      } ${isShopByRoom && "!bg-[#F9F7F4]"}`}
      id="image-view"
      style={{
        height: "100%",
        position: "relative",
        width: "100%",
        display: "flex",
        ...imageStyles
      }}
      ref={innerRef}
      onMouseEnter={altImage ? onMouseEnter : undefined}
      onMouseLeave={altImage ? onMouseLeave : undefined}
    >
      {children}
    </div>
  )
);

export interface RHImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  rootProps?: GridProps;
  pinchable?: boolean;
  preset?: ProductImagePresetKeys;
  loadingHeight?: string | number;
  altImage?: string;
  objectFit?: string;
  aspectRatio?: number;
  loadingBehavior?: "eager" | "lazy";
  imageWrapperStyles?: React.CSSProperties;
  heroContainerStyles?: any;
  heroImageStyles?: any;
  imageWrapperProps?: object;
  isInEditor?: boolean;
  useCssAspectratio?: boolean;
  imageWrapperClassName?: string;
  inViewOptions?: IntersectionOptions;
  disableInitialShapeEffect?: boolean;
  imageClassName?: string;
  imageSkeletonBackgroundColor?: string;
  imageCarousels?: Boolean;
  imgStyle?: React.CSSProperties;
  setIsLineItemImageUrlFailed?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsHeroImageUrlFailed?: React.Dispatch<React.SetStateAction<boolean>>;
  failedImageUrl?: string;
  swatchImageUrl?: string;
  zoomContainerStyle?: any;
  imgDimension?: any;
  sliderCss?: string;
  containerStyle?: any;
  isSwatchPanelDialogOpen?: boolean;
  isZoomViewerDialogOpen?: boolean;
  imageLayout?: string;
  isShimmerStopped?: boolean;
  alt?: string;
  imageStyles?: React.CSSProperties;
  dataAnalyticsId?: string;
  isSwatchTooltip?: string;
  imageRef?: boolean;
  setMaxUpSetHeight?: React.Dispatch<React.SetStateAction<number[]>>;
  upsellContainerStyles?: React.CSSProperties;
  isFinishOption?: string;
  isNewPdpLayout?: boolean;
  parentBaseId?: string;
  isShopByRoom?: Boolean;
  parentAccessibilityProps?: {
    tabIndex?: number;
  };
  relatedProductId?: string;
}

const RHImage: FC<RHImageProps> = memoize(
  ({
    rootProps,
    children,
    loadingHeight,
    aspectRatio,
    src,
    preset,
    altImage,
    imgDimension,
    containerStyle,
    pinchable,
    loadingBehavior,
    objectFit,
    imageWrapperStyles,
    heroContainerStyles,
    heroImageStyles,
    imageWrapperProps,
    imageWrapperClassName,
    zoomContainerStyle,
    classes,
    isInEditor,
    inViewOptions = {},
    disableInitialShapeEffect = false,
    imageClassName = "",
    imageSkeletonBackgroundColor,
    imageCarousels,
    isSwatchPanelDialogOpen,
    isZoomViewerDialogOpen,
    imageLayout,
    imgStyle = {},
    useCssAspectratio = false,
    setIsLineItemImageUrlFailed,
    setIsHeroImageUrlFailed,
    failedImageUrl,
    sliderCss,
    isShimmerStopped = false,
    alt,
    imageStyles = {},
    dataAnalyticsId,
    isSwatchTooltip,
    imageRef = false,
    setMaxUpSetHeight = () => {},
    upsellContainerStyles = {},
    isFinishOption,
    parentBaseId,
    parentAccessibilityProps,
    isShopByRoom,
    relatedProductId,
    ...rest
  }) => {
    const baseId = `${parentBaseId}-RHImage`;
    const env = useEnv();
    if (env.ENV_ID !== "PROD" && yn(env.FEATURE_USE_PLACEHOLDER_IMAGES)) {
      const [_, queryString] = src.split("?");
      src = queryString
        ? `/placeholder.jpg?${queryString}`
        : "/placeholder.jpg";
      if (altImage) altImage = src;
    }
    const currentRef = useRef<HTMLImageElement>(null);
    const { loading } = useContext(RHImageContext);
    const _disableInitialShapeEffect =
      loading === "eager" ? true : disableInitialShapeEffect;
    const _loading = loadingBehavior || loading;
    const [done, setDone] = useState(false);
    const [error, setError] = useState(false);
    const [hover, setHover] = useState(false);
    const [loadedSrc, setLoadedSrc] = useState("");
    const imgLoading = useCheckBrowserSupport("imgLoading");

    const onMouseEnter = () => {
      return setHover(true);
    };
    const onMouseLeave = () => setHover(false);
    function parseStyles(cssString) {
      if (cssString?.length) {
        const stylesArray = cssString.split(";").filter(Boolean);

        const stylesObject = stylesArray.reduce((acc, style) => {
          const [property, value] = style.split(":").map(s => s.trim());
          if (property && value) {
            acc[property] = value;
          }
          return acc;
        }, {});

        return stylesObject;
      }
      return;
    }

    let productId: string | null = null;
    const prefix = "prod";
    const productStartIndex = src && src?.indexOf(prefix);
    if (src && productStartIndex !== -1) {
      const endIndex = src?.indexOf("_", productStartIndex);
      if (endIndex !== -1) {
        productId = src?.substring(productStartIndex, endIndex);
      }
    }

    const [notifyImageFailure] = useMutation<Mutation>(logImageErrors, {
      context: {
        fetchOptions: {
          method: "POST"
        }
      }
    });

    useLayoutEffect(() => {
      if (loadedSrc !== src) {
        setDone(false);
        setError(false);
      } else {
        setDone(true);
      }
    }, [src, loadedSrc]);

    const handleLoad = useCallback(
      (event: SyntheticEvent<HTMLImageElement, Event>) => {
        setLoadedSrc(src);
        if (rest.onLoad) {
          rest.onLoad(event);
          if (setIsLineItemImageUrlFailed) setIsLineItemImageUrlFailed(false);
          if (setIsHeroImageUrlFailed) setIsHeroImageUrlFailed(false);
        }

        EventEmitter.dispatch(COMPONENT_DID_LOAD_EVENT);
      },
      [rest.onLoad, src]
    );

    const handleError = useCallback(
      (event: SyntheticEvent<HTMLImageElement, Event>) => {
        const target = event.target as HTMLImageElement;
        setDone(false);
        setError(true);
        if (target.src) {
          notifyImageFailure({
            variables: {
              imageUrl: target.src,
              productId: productId
            }
          });
          if (setIsLineItemImageUrlFailed) setIsLineItemImageUrlFailed(true);
          if (setIsHeroImageUrlFailed) setIsHeroImageUrlFailed(true);
        }
        if (rest.onError) {
          rest.onError(event, target.src);
        }
      },
      [rest.onError]
    );

    const startIndex = src?.lastIndexOf("/") + 1;
    const endIndex = src?.includes("?")
      ? src?.indexOf("?")
      : src?.lastIndexOf(".");
    const altText = alt ?? src?.substring(startIndex, endIndex);
    const accessibilityProps = rest?.onClick
      ? {
          alt: altText,
          "aria-label": altText,
          tabIndex: 0,
          ...parentAccessibilityProps
        }
      : { "aria-hidden": "true" };
    const imageCondition = !error;
    const imageWrapperListProps = {
      env,
      classes,
      altImage,
      onMouseEnter,
      onMouseLeave,
      imageStyles,
      isSwatchTooltip,
      isShopByRoom
    };

    const renderImage = ({ src, imageLoaded }) => {
      const _image = imageLoaded && (
        <img
          id={`${baseId}-${relatedProductId}-${src}`}
          onClick={() => (rest.onClick ? rest.onClick() : {})}
          onKeyDown={
            rest.onClick
              ? handleKeyboardPressWithEvent(rest.onClick)
              : undefined
          }
          src={src}
          height={rootProps?.style?.minHeight || loadingHeight}
          //newer html property that ts doesnt recognize yet
          //@ts-ignore
          fetchpriority={loadingBehavior === "eager" ? "high" : undefined}
          style={{
            ...rest.style,
            objectFit: objectFit ?? "contain",
            width: parseStyles(sliderCss)?.width ?? rest.style?.width,
            height:
              parseStyles(sliderCss)?.height ?? rest.style?.height ?? "100%",
            marginTop: "0px",
            ...imgStyle
          }}
          className={classNames(
            imageClassName,
            !isShimmerStopped ? (hover ? "opacity-0" : imageClassName) : "",
            "block",
            altImage ? "absolute" : "static",
            "top-0",
            sliderCss?.length
              ? "max-w-[139px]"
              : rest.style?.maxWidth
              ? `max-w-[${rest.style.maxWidth}]`
              : "unset",
            rest.style?.maxHeight && `max-h-[${rest.style.maxHeight}]`,
            rest.style?.minWidth && `min-w-[${rest.style.minWidth}]`,
            rest.style?.minHeight && `min-h-[${rest.style.minHeight}]`,
            "mt-0",
            imageCarousels
              ? "transition-none"
              : "transition-all duration-500 ease-[cubic-bezier(0.4, 0, 0.2, 1)]",
            isShopByRoom && "mix-blend-darken"
          )}
          loading={(imgLoading && _loading) || "lazy"}
          onLoad={e => {
            handleLoad(e);
            if (imageRef && currentRef.current) {
              const imageHeight: string = getComputedStyle(
                currentRef.current
              )?.height;
              const numHeight: string = imageHeight?.slice(
                0,
                imageHeight.length - 2
              );
              setMaxUpSetHeight(p => [parseFloat(numHeight), ...p]);
            }
          }}
          onError={handleError}
          data-analytics-id={dataAnalyticsId}
          ref={currentRef}
          {...accessibilityProps}
        />
      );

      return isSwatchPanelDialogOpen ? (
        <DragPanImage
          isZoomViewerDialogOpen={isZoomViewerDialogOpen}
          imageLayout={imageLayout}
        >
          {_image}
        </DragPanImage>
      ) : (
        _image
      );
    };

    // const _image = imageCondition && (
    //   <img
    //     id={`${baseId}-${relatedProductId}-${src}`}
    //     onClick={() => (rest.onClick ? rest.onClick() : {})}
    //     onKeyDown={
    //       rest.onClick ? handleKeyboardPressWithEvent(rest.onClick) : undefined
    //     }
    //     src={
    //       imageCondition
    //         ? getImageUrlWithPreset(src, pinchable ? "mobileZoom" : preset)
    //         : failedImageUrl
    //     }
    //     height={rootProps?.style?.minHeight || loadingHeight}
    //     //newer html property that ts doesnt recognize yet
    //     //@ts-ignore
    //     fetchpriority={loadingBehavior === "eager" ? "high" : undefined}
    //     style={{
    //       ...rest.style,
    //       objectFit: objectFit ?? "contain",
    //       width: parseStyles(sliderCss)?.width ?? rest.style?.width,
    //       height:
    //         parseStyles(sliderCss)?.height ?? rest.style?.height ?? "100%",
    //       marginTop: "0px",
    //       ...imgStyle
    //     }}
    //     className={classNames(
    //       imageClassName,
    //       !isShimmerStopped ? (hover ? "opacity-0" : imageClassName) : "",
    //       "block",
    //       altImage ? "absolute" : "static",
    //       "top-0",
    //       sliderCss?.length
    //         ? "max-w-[139px]"
    //         : rest.style?.maxWidth
    //         ? `max-w-[${rest.style.maxWidth}]`
    //         : "unset",
    //       rest.style?.maxHeight && `max-h-[${rest.style.maxHeight}]`,
    //       rest.style?.minWidth && `min-w-[${rest.style.minWidth}]`,
    //       rest.style?.minHeight && `min-h-[${rest.style.minHeight}]`,
    //       "mt-0",
    //       imageCarousels
    //         ? "transition-none"
    //         : "transition-all duration-500 ease-[cubic-bezier(0.4, 0, 0.2, 1)]",
    //       isShopByRoom && "mix-blend-darken"
    //     )}
    //     loading={(imgLoading && _loading) || "lazy"}
    //     onLoad={e => {
    //       handleLoad(e);
    //       if (imageRef && currentRef.current) {
    //         const imageHeight: string = getComputedStyle(
    //           currentRef.current
    //         )?.height;
    //         const numHeight: string = imageHeight?.slice(
    //           0,
    //           imageHeight.length - 2
    //         );
    //         setMaxUpSetHeight(p => [parseFloat(numHeight), ...p]);
    //       }
    //     }}
    //     onError={handleError}
    //     data-analytics-id={dataAnalyticsId}
    //     ref={currentRef}
    //     {...accessibilityProps}
    //   />
    // );

    // const image = isSwatchPanelDialogOpen ? (
    //   <DragPanImage
    //     isZoomViewerDialogOpen={isZoomViewerDialogOpen}
    //     imageLayout={imageLayout}
    //   >
    //     {_image}
    //   </DragPanImage>
    // ) : (
    //   _image
    // );

    const aspectRatioStyle = aspectRatio
      ? useCssAspectratio
        ? {
            aspectRatio: aspectRatio.toString()
          }
        : {
            ["--aspect-ratio" as any]: aspectRatio
          }
      : {};

    const styleCSS = {
      position: "relative",
      overflow: "hidden",
      alignItems: "stretch",
      minHeight: rootProps?.style?.minHeight || loadingHeight,
      justifyContent: "center",
      backgroundColor: "transparent",
      transition: imageCarousels
        ? "none"
        : "background-color 2250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      ...rootProps?.style,
      ...(rest?.isNewPdpLayout ? {} : aspectRatioStyle),
      height: isFinishOption ? "244px" : rootProps?.style?.height || "",
      ...upsellContainerStyles
    };

    const imageSrc = imageCondition
      ? getImageUrlWithPreset(src, pinchable ? "mobileZoom" : preset)
      : failedImageUrl;

    return (
      <Grid
        id={
          rest?.isNewPdpLayout
            ? "component-rh-image-pdp-v3"
            : "component-rh-image"
        }
        {...rootProps}
        className={heroImageStyles}
        container
        style={styleCSS}
        data-testid="component-sale-banner-test-id"
      >
        {(!processEnvServer &&
          !done &&
          !imageCondition &&
          !_disableInitialShapeEffect &&
          !isSSRToggledWithClientRender()) ||
        !src ? (
          <ImageSkeleton
            aspectRatio={aspectRatio}
            className={"absolute top-0 left-0"}
            color={imageSkeletonBackgroundColor}
          />
        ) : (
          <Grid
            id="component-rh-image_wrapper"
            data-analytics-id="rh-image"
            item
            xs
            style={{
              ...imageWrapperStyles,
              ...(useCssAspectratio && {
                height: "100%"
              })
            }}
            className={classNames([imageWrapperClassName])}
            {...imageWrapperProps}
          >
            {imageCondition ? (
              // TODO: using LazyLoad in jest makes tests run 60s longer; once that's fixed, this condition can be removed
              process?.env?.NODE_ENV === "test" ||
              _loading === "eager" ||
              isInEditor ||
              yn(env.FEATURE_SSR) ||
              imgLoading ? (
                <ImageWrapper {...imageWrapperListProps}>
                  {renderImage({ src: imageSrc, imageLoaded: imageCondition })}
                </ImageWrapper>
              ) : (
                <InView triggerOnce {...inViewOptions}>
                  {({ inView, ref }) => (
                    <ImageWrapper innerRef={ref} {...imageWrapperListProps}>
                      {" "}
                      {rest.disableInView
                        ? renderImage({
                            src: imageSrc,
                            imageLoaded: imageCondition
                          })
                        : inView &&
                          renderImage({
                            src: imageSrc,
                            imageLoaded: imageCondition
                          })}
                    </ImageWrapper>
                  )}
                </InView>
              )
            ) : (
              <ImageWrapper {...imageWrapperListProps}>
                {renderImage({ src: failedImageUrl, imageLoaded: true })}
              </ImageWrapper>
            )}
          </Grid>
        )}
        {children}
      </Grid>
    );
  }
);

export default RHImage;

const preloadImages: { [key: string]: boolean } = {};

export interface ImagePreloaderProps {
  preset?: ProductImagePresetKeys;
  images: Array<string>;
}

export const ImagePreloader: FC<ImagePreloaderProps> = memoize(
  ({ images, preset }) => {
    // Preload images into browser cache.
    useUnsafeEffect(() => {
      images.forEach(src => {
        const url = getImageUrlWithPreset(src, preset);
        if (url && !preloadImages[url]) {
          preloadImages[url] = true;
          let img = new Image();
          img.onload = () => {};
          img.src = url;
        }
      });
    }, []);
    return null;
  }
);
