import { useCallback, useMemo, useState } from "react";
import { Option } from "./types";

export const usePDPConfiguratorData = props => {
  const { availableOptions } = props;
  const [seletedoptions, setSelectedOptions] = useState<Option[]>([]);
  const [productSize, setProductSize] = useState(null);
  const excludedTypes = ["Color", "Fabric", "Leather"];

  const isExcludedType = type => {
    return excludedTypes.includes(type);
  };

  const processedOptions = useMemo(() => {
    return availableOptions?.reduce((acc, { type, options }) => {
      if (isExcludedType(type)) {
        return acc;
      }
      if (type.toLowerCase() === "width") {
        setProductSize(
          options.find(option => option.status.toLowerCase() === "selected")
            ?.value
        );
      }
      acc[type] = {
        options: options.slice().sort((a, b) => a.sortPriority - b.sortPriority)
      };
      return acc;
    }, {});
  }, [availableOptions]);

  const handleSelect = useCallback((option: Option) => {
    setSelectedOptions(prevOptions => {
      const filteredOptions = prevOptions?.filter(
        prevOption => prevOption?.type !== option?.type
      );
      return [...filteredOptions, option];
    });
  }, []);

  return {
    excludedTypes,
    processedOptions,
    seletedoptions,
    productSize,
    isExcludedType,
    setSelectedOptions,
    handleSelect
  };
};
