// import loadable from "@loadable/component";
import AEMWrapper from "aem/ComponentWrapper";
import React, { FC } from "react";
import meta from "./aem-meta";
import { RHDividerProps } from "./types";

// const Component = loadable(
//   () => import(/* webpackChunkName: "component-rh-divider" */ "."),
//   {
//     fallback: <></>
//   }
// );
import Component from ".";

const AEMComponent: FC<RHDividerProps> = props => {
  return <Component {...props} filter="opaque" />;
};

export default AEMWrapper(meta, AEMComponent);
