import { PropTypes } from "aem/ComponentWrapper";
import React from "react";

export default {
  title: "Carousel",
  type: "rh/components/content/composite-container-carousel",
  superType: "core/wcm/components/carousel/v1/carousel",
  isContainer: true,
  overrideIntersectionObserver: true,
  config: {
    emptyLabel: "No Slides",
    isEmpty: ({ children }: PropTypes) => React.Children.count(children) === 0
  }
};
