import React from "react";
import memoize from "@RHCommerceDev/utils/memoize";
import { createTypography } from "./helper";
import { MaterialInfoProps } from "./types";

const MaterialInfo: React.FC<MaterialInfoProps> = ({
  selectedMaterialName,
  currentSelectedSwatch,
  processedSwatches,
  processedFinishSwatches,
  getBorderClass,
  setSelectedMaterialName
}) => {
  const fabricTitleStyle = "text-black text-xs uppercase inline";

  return (
    <div
      id={"component-pdp-swatch-carousel_material_info"}
      data-testid={"component-pdp-swatch-carousel_material_info"}
      className="flex justify-between items-center pb-2"
    >
      <div>
        {createTypography(
          selectedMaterialName,
          `${fabricTitleStyle} font-primary-rhroman leading-[120%] tracking-[0.48px] font-normal`
        )}
        {currentSelectedSwatch?.swatchGroupName &&
          createTypography(
            `- ${currentSelectedSwatch?.swatchGroupName}, ${currentSelectedSwatch?.title}`,
            `${fabricTitleStyle} font-primary-rh-light leading-[100%] font-thin tracking-[0.44px]`
          )}
      </div>
      {!!processedSwatches?.swatches?.length &&
        !!processedFinishSwatches?.materialName && (
          <div className="flex items-center gap-2">
            {createTypography(
              processedFinishSwatches?.materialName,
              `text-xs font-normal !leading-5 cursor-pointer uppercase m-0 ${getBorderClass(
                processedFinishSwatches?.materialName
              )}`,
              "h6",
              {
                onClick: () =>
                  setSelectedMaterialName(
                    processedFinishSwatches?.materialName || ""
                  )
              }
            )}
            {createTypography("|", "text-xs text-rh-gray m-0", "h6")}
            {createTypography(
              "Fabric",
              `text-xs font-normal uppercase !leading-5 cursor-pointer m-0 ${getBorderClass(
                "fabric"
              )}`,
              "h6",
              { onClick: () => setSelectedMaterialName("fabric") }
            )}
          </div>
        )}
    </div>
  );
};

export default memoize(MaterialInfo);
