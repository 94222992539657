import React, { FC } from "react";
import classNames from "classnames";
import { Typography } from "utils/material-ui-core";
import { TypographyProps } from "@material-ui/core/Typography";
import useTypographyStyles from "hooks/useTypographyStyles";
import memoize from "utils/memoize";

interface PriceLabelProps extends TypographyProps {
  children?: string | null;
  strikethrough?: boolean;
}

export const PriceLabel: FC<PriceLabelProps> = ({
  children,
  className = "",
  style,
  strikethrough,
  display
}) => {
  const classes = useTypographyStyles({
    keys: ["priceRange", "rhBaseCaption"]
  });

  return typeof children === "string" ? (
    <Typography
      id="price-label"
      data-testid={`price-label-${(children || "")
        .toLowerCase()
        .replace(/ /g, "-")}`}
      display={display}
      className={classNames(
        classes.rhBaseCaption,
        classes.priceRange,
        className
      )}
      style={{
        textDecoration: strikethrough ? "line-through" : "",
        fontWeight: "initial",
        ...style,
        textTransform: "none" // stop the capitalization
      }}
    >
      {children}
    </Typography>
  ) : null;
};

export default memoize(PriceLabel);
