import React, { FC } from "react";
import url from "url";
import { useHistory } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import RHImage from "component-rh-image";
import { PromoProps } from "component-promo";
import IframeDialog from "dialog-iframe";
import PromosDialog from "dialog-promos";
import useState from "hooks/useState";
import { queryPages } from "graphql-client/queries/pages";
import htmlToJson from "utils/htmlToJson";
import handlePromoUrl from "utils/handlePromoUrl";
import ImageMapArea from "./ImageMapArea";
import { ProductImagePresetKeys } from "utils/getImageUrlWithPreset";
import useMediaString from "hooks/useMediaString";
import useBrand from "hooks-use-brand/useBrand";
import { processEnvServer } from "hooks/useSsrHooks";
import useIsoRedirect from "hooks-use-isoredirect";

export const PromoImageMap: FC<PromoProps> = ({
  data,
  loadingHeight,
  ...props
}) => {
  const history = useHistory();
  const mediaString = useMediaString();
  const brand = useBrand();
  let defaultImageHeight: number = 0;

  const [iframeDialog, , setIframeDialogValue] = useState({
    open: false,
    src: ""
  });

  const [promosDialog, , setPromosDialogValue] = useState({
    open: false,
    promos: [] as PromoDisplay[]
  });

  const [getPage, { data: { contentFragment } = {} as Query, loading }] =
    useLazyQuery<Query>(queryPages);
  if (contentFragment && !loading) {
    const pageText = htmlToJson(contentFragment.text);
    if (iframeDialog.src !== pageText.attributes.src) {
      setIframeDialogValue("src")(pageText.attributes.src);
    }
  }

  switch (brand) {
    case "MO":
      defaultImageHeight = 420;
      break;
    default:
      defaultImageHeight = 616; //set for current promo size
  }

  return (
    <>
      <RHImage
        src={data.imageUrl || undefined}
        alt={data.dynamicText.alt || "RH Promo"}
        loadingHeight={loadingHeight}
        preset={`full-width-${mediaString}` as ProductImagePresetKeys}
        {...props}
        onClick={() => {
          if (data.targetUrl) {
            if (data.linkType === "new-window") {
              const win = useIsoRedirect(data.targetUrl, "windowOpen");
              if (win) {
                (win as Window).focus();
              }
            } else {
              handlePromoUrl(data.targetUrl, history);
            }
          }
        }}
      >
        {data.subItems.map(item => (
          <ImageMapArea
            key={`image-map-area-${item.id}`}
            dimensions={{
              imageWidth: Number(data.dynamicText.width) || 1000,
              imageHeight: Number(data.dynamicText.height) || defaultImageHeight
            }}
            link={item.targetUrl}
            coords={item.dynamicText.coords || "0,0,0,0"}
            onClick={mapAreaOnClickFactory(item)}
          />
        ))}
      </RHImage>
      {!processEnvServer && (
        <>
          <IframeDialog
            src={iframeDialog.src}
            open={iframeDialog.open}
            onClose={() => setIframeDialogValue("open")(false)}
          />
          <PromosDialog
            data={promosDialog.promos}
            open={promosDialog.open}
            onClose={() => setPromosDialogValue("open")(false)}
          />
        </>
      )}
    </>
  );

  function mapAreaOnClickFactory(item: PromoDisplay) {
    return () => {
      if (item.linkType === "modal") {
        if (item.linkUrl) {
          const urlObj = url.parse(item.linkUrl, true);
          if (urlObj.query.id) {
            getPage({
              variables: {
                id: urlObj.query.id
              }
            });
            setIframeDialogValue("src")("");
            setIframeDialogValue("open")(true);
          }
        } else if (item.subItems.length) {
          setPromosDialogValue("promos")(item.subItems);
          setPromosDialogValue("open")(true);
        }
      } else if (item.targetUrl) {
        handlePromoUrl(item.targetUrl, history);
      }
    };
  }
};

PromoImageMap.defaultProps = {};

export default PromoImageMap;
