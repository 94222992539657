import React from "react";
import memoize from "@RHCommerceDev/utils/memoize";
import CloseIcon from "./CloseIcon";
import { SwatchDetailPanelProps } from "./types";
import { usePageContent } from "@RHCommerceDev/custom-providers/LocationProvider";

const SwatchDetailPanel: React.FC<SwatchDetailPanelProps> = ({
  swatch,
  onClose
}) => {
  if (!swatch) return null;
  const { pageContent } = usePageContent();
  return (
    <div
      className="min-h-64 w-full relative"
      id={"component-pdp-swatch-carousel_swatch_detail_panel"}
      data-testid={"component-pdp-swatch-carousel_swatch_detail_panel"}
      style={{
        background: `url('${swatch?.swatchImageLg?.url}') top center / contain`
      }}
    >
      <div className="relative flex p-4 w-full flex-col flex-1 gap-6 h-full">
        {!!swatch?.optionCopy ? (
          <div
            className={
              "text-black text-xs tracking-[0.48px] leading-[120%] font-light [&>strong]:text-[36px] [&>strong]:font-primary-thin [&>strong]:font-[75]  [&>strong]:leading-9 [&>strong]:uppercase"
            }
            dangerouslySetInnerHTML={{
              __html: `${swatch?.optionCopy}`
            }}
          />
        ) : (
          <div className="flex-1 flex flex-col gap-6 ">
            <div className="flex flex-col gap-1.5">
              <div className="flex justify-between items-center">
                <h3 className="m-0 text-black text-[36px] font-primary-thin font-[75] leading-9 uppercase ">
                  {swatch?.swatchGroupName}
                </h3>
              </div>
              <p className="m-0 text-black text-xs tracking-[0.48px] leading-[120%] font-light uppercase">
                {swatch?.title}
              </p>
            </div>
          </div>
        )}
        <CloseIcon
          className="text-black text-xl absolute top-2 right-2 cursor-pointer"
          onClick={onClose}
        />

        <button className="flex text-black w-[fit-content] flex-start border border-solid uppercase p-2.5 text-xs">
          {pageContent?.ADD_SWATCH_TO_CART}
        </button>
      </div>
    </div>
  );
};

export default memoize(SwatchDetailPanel);
