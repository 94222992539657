import React, { FC } from "react";
import memoize from "@RHCommerceDev/utils/memoize";
import PDPConfiguratorOptions from "./PDPConfiguratorOptions";
import { usePDPConfiguratorData } from "./usePDPConfiguratorData";
import { PDPConfiguratorProps } from "./types";

export const PDPConfigurator: FC<PDPConfiguratorProps> = props => {
  const { processedOptions, handleSelect } = usePDPConfiguratorData(props);

  return (
    <div
      id={"component-pdp-configurator"}
      data-testid={"component-pdp-configurator"}
      className="relative flex flex-wrap gap-14 pt-5 flex-1"
    >
      {Object?.keys(processedOptions)?.map(key => (
        <PDPConfiguratorOptions
          key={key}
          label={key}
          values={Object.values(processedOptions[key]?.options)}
          variant={key === "Width" ? "square" : "flex"}
          defaultValue={processedOptions[key]?.options?.[0]?.value as string}
          onSelect={handleSelect}
        />
      ))}
    </div>
  );
};
export default memoize(PDPConfigurator);
