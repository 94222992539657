import React, { FC } from "react";
import { PromoProps } from "component-promo";
import RHCarousel from "component-rh-carousel";
import Promo from "component-promo";
import { useMediaQuery, Theme } from "utils/material-ui-core";

export const PromoSlideShow: FC<PromoProps> = ({
  data,
  loadingHeight,
  ...rest
}) => {
  const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
  return (
    <RHCarousel
      autoplay={data.dynamicText.autoplay ?? true}
      autoplaySpeed={data.dynamicText.slidetime ?? 6000}
      easing={data.dynamicText.easing ?? "ease-out"}
      pauseOnHover={data.dynamicText.pauseOnHover ?? false}
      speed={data.dynamicText.effecttime ?? 9}
      resetOffScreen={data.dynamicText.resetOffScreen ?? false}
      {...rest}
    >
      {data.subItems
        .filter(item => item?.imageUrl)
        .map((item, index) => (
          <Promo
            loadingHeight={!index ? loadingHeight : undefined}
            key={item.id}
            data={item}
            {...rest}
          />
        ))}
    </RHCarousel>
  );
};

PromoSlideShow.defaultProps = {};

export default PromoSlideShow;
