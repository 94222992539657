import React from "react";
import { TailwindTypography } from "@RHCommerceDev/component-tailwind-typography";

export const createTypography = (
  content: string | React.ReactNode,
  extraStyles: string = "",
  component: string = "body1",
  restProps: { [key: string]: string | (() => void) } = {}
) => (
  <TailwindTypography
    className={`text-black text-[0.9167rem] leading-[1.0833rem] tracking-[0.04em] font-primary-light ${extraStyles}`}
    component={component}
    {...restProps}
  >
    {content}
  </TailwindTypography>
);
