import React, { FC } from "react";
import LiveTextLayer from "./LiveTextLayer";
import CallToActionLayer from "./CallToActionLayer";

export interface LayerProps {
  layer: PromoDisplayLayer;
}

export interface LayerMap {
  [K: string]: FC<LayerProps>;
}

export interface DesktopLayerProps {
  layer: PromoDisplayLayer;
  id: string;
  index: number;
}

export const DesktopLayer: FC<DesktopLayerProps> = ({ id, layer, index }) => {
  const Layer = (
    {
      liveText: LiveTextLayer,
      button: CallToActionLayer
    } as LayerMap
  )[layer.layerType];

  if (!Layer) {
    return null;
  }

  return <Layer key={`${id}-layer-${index}`} layer={layer} />;
};

export default DesktopLayer;
