import type { ComponentProps } from "react";
import React from "react";
import memoize from "utils/memoize";


const RHSimpleImage = (
  props: ComponentProps<"img"> & {aspectRatio?: string, classes?: string }
) => {
  const { src, srcSet, sizes, alt, style, loading, classes} = props
  return (
    <img
      src={src}
      srcSet={srcSet}
      sizes={sizes}
      alt={alt}
      style={style}
      loading={loading ?  loading : "lazy"}
      className={classes} />
    
  );
};

export default memoize(RHSimpleImage);
