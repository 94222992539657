import React from "react";
import {
  formatCurrencyPrice,
  isNullOrUndefined
} from "@RHCommerceDev/component-rh-price-range-display/helpers";
import { PriceProps } from "./type";
import PDPTypography from "./PDPTypography";
import { clsx as cn } from "clsx";

const Price = (props: PriceProps) => {
  const { memberPrice, topLabel, skulowestMemberPrice, onSale } = props;

  return !isNullOrUndefined(memberPrice || skulowestMemberPrice) ? (
    <div
      id={"component-pdp-product-details_price"}
      data-testid={"component-pdp-product-details_price"}
      className={
        "flex flex-col justify-center pr-2.5 box-border xs:justify-start items-start"
      }
    >
      <div className={"flex flex-row items-baseline flex-wrap"}>
        {topLabel ? (
          <PDPTypography
            component="span"
            name={topLabel}
            className={cn("my-0 mr-1", {
              ["!text-red-600"]: onSale
            })}
          />
        ) : null}
        <PDPTypography
          component="span"
          name={formatCurrencyPrice(
            onSale ? skulowestMemberPrice : memberPrice
          )}
          className={cn({
            ["!text-red-600"]: onSale
          })}
        />
      </div>
    </div>
  ) : (
    <></>
  );
};
export default React.memo(Price);
