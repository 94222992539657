import classNames from "classnames";
import { useEnv } from "hooks/useEnv";
import useTypographyStyles from "hooks/useTypographyStyles";
import RHArrowIcon from "icon-arrow";
import RHCloseIcon from "icon-close";
import React, { FC } from "react";
import {
  createStyles,
  DialogTitle,
  DialogTitleProps,
  IconButton,
  makeStyles,
  Theme,
  Typography
} from "utils/material-ui-core";

export interface RHDialogTitleProps extends DialogTitleProps {
  onBack?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
  onClose?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
  titleClassNames?: string;
  titlePaddingClassNames?: string;
  closeIconClassnames?: string;
  closeButtonRootClassnames?: string;
  solidWhite?: boolean; // Temporary prop until the dialog types are hashed out
  subTitle?: string;
  anchor?: string;
  isDark?: boolean;
  styleTile?: any;
}

export const RHDialogTitle: FC<RHDialogTitleProps> = ({
  onBack,
  onClose,
  title,
  titleClassNames,
  titlePaddingClassNames,
  closeButtonRootClassnames,
  children,
  solidWhite,
  subTitle,
  anchor,
  styleTile,
  isDark = false,
  ...rest
}) => {
  const env = useEnv();
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      icon: {
        top: "50%",
        transform: "translateY(-50%)"
      },
      title_padding: {
        padding: theme.spacing(3, 4),
        [theme.breakpoints.up("xs")]: {
          padding: theme.spacing(3.4, 4, 3, 5),
          ...rest.style
        },
        [theme.breakpoints.up("sm")]: {
          padding: theme.spacing(4, 4, 3, 5),
          ...rest.style
        },
        [theme.breakpoints.up("xl")]: {
          padding: theme.spacing(7.62, 4, 3, 10),
          ...rest.style
        }
      }
    })
  );

  const classes = useTypographyStyles({
    keys: ["textCenter", "rhBaseCaption", "rhBaseBody1"]
  });

  const iconClasses = useStyles();
  return (
    <DialogTitle
      id={"component-rh-dialog-title"}
      disableTypography
      {...rest}
      style={{ ...rest.style }}
      className={classNames(
        `bg-[#f9f7f4] relative top-0 right-0 flex`,
        //We can put this line back once we have a confirmation modal popup that has a main title
        //Make sure to change position: "absolute" to position: "relative" too
        iconClasses.title_padding,
        titlePaddingClassNames
      )}
    >
      {onBack && (
        <IconButton
          disableRipple
          className={classNames(iconClasses.icon, "!absolute left-1.5 z-20")}
          color="inherit"
          onClick={event => onBack(event, "escapeKeyDown")}
        >
          <RHArrowIcon
            style={{
              transform: "rotate(180deg)"
            }}
          />
        </IconButton>
      )}

      {title && (
        <>
          <Typography
            className={classNames(
              [
                { [classes.textCenter]: !Boolean(titleClassNames) },
                titleClassNames ? titleClassNames : classes.rhBaseBody1
              ],
              "!text-left"
            )}
            dangerouslySetInnerHTML={{
              __html: title
            }}
          />
          {subTitle && (
            <Typography
              className={classNames(
                [
                  { [classes.textCenter]: !Boolean(titleClassNames) },
                  titleClassNames,
                  classes.rhBaseCaption
                ],
                "!text-left !mt-[20px] !ml-[-65px]"
              )}
              dangerouslySetInnerHTML={{
                __html: subTitle
              }}
            />
          )}
        </>
      )}
      {children}
      {onClose && (
        <IconButton
          disableRipple
          className={classNames(
            iconClasses.icon,
            "!absolute !p-[2px] right-1.5 !justify-start",
            "!text-[20px] focus-visible:outline focus-visible:outline-[1px] focus-visible:outline-auto focus-visible:outline-[#015fcc]",
            anchor === "right" ? "-left-[46px]" : "",
            anchor === "left" ? "-right-[58px]" : "",
            rest.closeIconClassnames
          )}
          aria-label="Close"
          classes={{ root: closeButtonRootClassnames }}
          color="inherit"
          tabIndex={0}
          onClick={event => onClose(event, "escapeKeyDown")}
          data-testid="dialog-title-close-button"
        >
          <RHCloseIcon className="!text-[2rem]" />
        </IconButton>
      )}
    </DialogTitle>
  );
};

RHDialogTitle.defaultProps = {};

export default RHDialogTitle;
