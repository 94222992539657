import React from "react";
import {
  makeStyles,
  createStyles,
  Grid,
  useTheme,
  ButtonBase
} from "utils/material-ui-core";
import LargeArrowIcon from "icon-large-arrow";
import { sanitizeArrowClassName } from "./sanitizeArrowClassName";
import { StyleProps } from "./types";
import yn from "yn";
import LargeArrowIconV2 from "icons/LargeArrowIconV2";
import { getGlobal } from "@RHCommerceDev/utils/Globals/getGlobal";

const env = getGlobal("_env");
const featureCgCarousel = yn(env.FEATURE_CG_CAROUSEL);

const useStyles = (props: StyleProps) =>
  makeStyles(theme => {
    const {
      arrowsSize,
      arrowsVisible = "",
      arrowsBackgroundColor,
      arrowWidth
    } = props;
    const isLarge = arrowsSize === "large";
    const isLargeAem = arrowsSize === "large-AEM";
    const slideArrowWidth = 32;
    const slideArrowHeight = isLargeAem ? 56 : isLarge ? 68 : undefined;

    return createStyles({
      carouselArrowGrid: {
        opacity: ["never", "hover-only"].includes(arrowsVisible)
          ? "0 !important"
          : "1 !important",
        "&:hover": {
          opacity: arrowsVisible === "never" ? 0 : 1
        }
      },
      carouselArrow: {
        backgroundColor: arrowsBackgroundColor,
        [theme.breakpoints.up("lg")]: {
          width: `${
            arrowWidth
              ? arrowWidth + "px !important"
              : slideArrowWidth + "px !important"
          }`
        }
      }
    });
  });

export const NextArrow = props => {
  const {
    onClick,
    carouselArrowGridStyles,
    carouselArrowStyles,
    className = "",
    allowDisabledClass = false,
    arrowZoomImage,
    imageCarousels,
    arrowsColor,
    icon,
    arrowsBackgroundColor,
    col,
    handleNext = () => {},
    nextArrowAccessibilityProps,
    arrowsSize
  } = props;
  const classes = useStyles(props)();
  const theme = useTheme();

  const conditionalPadding = featureCgCarousel ? "p-0" : "p-[16px]";

  const isLarge = arrowsSize === "large";
  const isLargeAem = arrowsSize === "large-AEM";

  return (
    <Grid
      container
      className={`${
        classes.carouselArrowGrid
      } !transition-opacity !duration-500 !ease-in-out !w-auto absolute top-0 bottom-0 ${conditionalPadding} z-10 cursor-pointer arrow-container ${sanitizeArrowClassName(
        className,
        allowDisabledClass
      )}`}
      style={{
        right: imageCarousels ? "-20px" : 0,
        top: imageCarousels ? "-10px" : 0,
        left: arrowZoomImage && "0",
        padding: arrowZoomImage ? "0" : imageCarousels ? "20px 0" : "",
        ...carouselArrowGridStyles,
        transform: arrowZoomImage ? "rotate(90deg)" : "",
        position: arrowZoomImage ? "inherit" : "",
        zIndex: arrowZoomImage ? 0 : "",
        width: "auto !important",
        margin: "0 auto !important",
        opacity: 0.8
      }}
      alignItems="center"
      justify="center"
    >
      <ButtonBase
        disableRipple
        className={`[&:focus-visible]:outline-[#015fcc] [&:focus-visible]:outline-[1px] [&:focus-visible]:outline`}
        onClick={e => {
          e?.preventDefault();

          handleNext();
          onClick && onClick();
        }}
        style={{ transform: imageCarousels ? "scale(0.3)" : "" }}
        {...nextArrowAccessibilityProps}
      >
        {icon == "yes" ? (
          <LargeArrowIconV2
            className={`${classes.carouselArrow} lg:!text-[68px] ${
              isLargeAem ? "!text-[40px]" : "!text-[68px]"
            } ${
              isLargeAem ? "!h-[56px]" : isLarge ? "!h-[68px]" : ""
            } !w-[32px] arrow-icon`}
            style={{
              ...carouselArrowStyles,
              transform: "rotate(180deg)",
              backgroundColor: arrowsBackgroundColor || "transparent"
            }}
            fill={arrowsBackgroundColor ? "none" : "white"}
            stroke={arrowsColor ?? theme?.palette?.common?.black}
            data-analytics-id="next"
          />
        ) : (
          <LargeArrowIcon
            className={`${classes.carouselArrow} lg:!text-[68px] ${
              isLargeAem ? "!text-[40px]" : "!text-[68px]"
            } ${
              isLargeAem ? "!h-[56px]" : isLarge ? "!h-[68px]" : ""
            } !w-[32px]`}
            style={{
              ...carouselArrowStyles,
              transform: "rotate(180deg)",
              backgroundColor: arrowsBackgroundColor || "transparent !important"
            }}
            fill={arrowsBackgroundColor ? "none" : "white"}
            stroke={arrowsColor ?? theme?.palette?.common?.black}
            data-analytics-id="next"
            col={col}
          />
        )}
      </ButtonBase>
    </Grid>
  );
};
export default NextArrow;
