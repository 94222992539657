import AEMWrapper from "aem/ComponentWrapper";
import ContainerWrapper from "concierge-alias-aem/ContainerWrapper";
import meta from "./aem-meta";
import React from "react";
import Component from "./aem-component";
export const FallbackComponent = props => {
  return props.placeholderHeight && props.placeholderHeight !== "" ? (
    <div style={{ height: props.placeholderHeight }}></div>
  ) : (
    <></>
  );
};
export default AEMWrapper(meta, Component, {
  wrapper: ContainerWrapper
});
