import { PropTypes } from "aem/ComponentWrapper";

export default {
  title: "Media Player",
  type: "rh/components/content/mediaplayer",
  config: {
    emptyLabel: "No media configured",
    isEmpty: ({ source }: PropTypes) => !source?.url
  }
};
