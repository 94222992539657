import React from "react";
import { ZipCodeProps } from "../type";
import memoize from "@RHCommerceDev/utils/memoize";
import { usePageContent } from "@RHCommerceDev/custom-providers/LocationProvider";

const ZipCode: React.FC<ZipCodeProps> = ({ zipCode, onClick, className }) => {
  const { pageContent } = usePageContent();
  return (
    <span
      id={"component-pdp-cta_price_range"}
      data-testid={"component-pdp-cta_price_range"}
      className={`underline underline-offset-sm cursor-pointer ${className}`}
      aria-label={pageContent?.Click_to_change_zip_code}
      onClick={onClick}
    >
      {zipCode}
    </span>
  );
};

export default memoize(ZipCode);
