import { PropTypes } from "aem/ComponentWrapper";

export default {
  title: "QuickLinks",
  type: "rh/components/content/rhquicklinks",
  isContainer: false,
  config: {
    emptyLabel: "QuickLinks - No items configured",
    isEmpty: (props: PropTypes) =>
      Object.values(props?.submenuItems ?? {}).length === 0
  }
};
