import AEMWrapper from "aem/ComponentWrapper";
import meta from "./aem-meta";
// import loadable from "@loadable/component";

// const Component = loadable(
//   () =>
//     import(/* webpackChunkName: "component-rh-button-add-membership" */ "."),
//   {
//     fallback: <></>
//   }
// );
import Component from ".";

export default AEMWrapper(meta, Component);
