import React, { FC } from "react";

export const PreviousArrowIcon: FC<{
  className?: string;
}> = ({ className = "" }) => (
  <svg
    focusable="false"
    width="24"
    height="26"
    viewBox="0 0 24 26"
    aria-hidden="true"
    data-analytics-id="previous"
    fill="none"
    className={className}
  >
    <path d="M18 25L6 13L18 1" stroke="black" strokeWidth="1.5" />
  </svg>
);

export const NextArrowIcon: FC<{
  className?: string;
}> = ({ className = "" }) => (
  <svg
    focusable="false"
    width="24"
    height="26"
    viewBox="0 0 24 26"
    aria-hidden="true"
    data-analytics-id="next"
    fill="none"
    className={className}
  >
    <path d="M6 1L18 13L6 25" stroke="black" strokeWidth="1.5" />
  </svg>
);
