import AEMWrapper from "aem/ComponentWrapper";
import ContainerWrapper from "concierge-alias-aem/ContainerWrapper";
import loadable from "@loadable/component";
import React from "react";

const Component = loadable(
  () =>
    import(/* webpackChunkName: "component-rh-positioning-container" */ "."),
  {
    fallback: <></>
  }
);

export default AEMWrapper(
  {
    type: "rh/components/content/positioning-container",
    config: {
      emptyLabel: "positioning-container",
      isEmpty: _ => false
    }
  },
  Component,
  {
    wrapper: ContainerWrapper
  }
);
