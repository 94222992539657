import React, { useState } from "react";
import memoize from "@RHCommerceDev/utils/memoize";
import { PDPConfiguratorOptionsProps, Option } from "./types";
import { clsx as cn } from "clsx";
import { usePageContent } from "@RHCommerceDev/custom-providers/LocationProvider";
import template from "lodash/template";

const PDPConfiguratorOptions: React.FC<PDPConfiguratorOptionsProps> = ({
  label,
  values,
  defaultValue,
  onSelect,
  variant = ""
}) => {
  const [selectedValue, setSelectedValue] = useState<Option>({
    value: defaultValue
  });
  const { pageContent } = usePageContent();

  const handleSelect = (value: Option) => {
    setSelectedValue(value);
    onSelect?.(value);
  };

  const strikeThroughStyle =
    "before:left-1 before:right-1 before:absolute before:top-1/2 before:h-px before:bg-rh-dark before:transform before:-translate-y-1/2";

  return (
    <div
      id="component-pdp-configurator_custom_select"
      data-testid="component-pdp-configurator_custom_select"
      aria-label={template(pageContent?.Button_Group_option)({ label })}
      className="flex flex-col gap-2 max-w-[30%]"
    >
      <label
        id={`component-pdp-configurator_${label}`}
        data-testid={`component-pdp-configurator_${label}`}
        className="text-xs leading-[100%] font-primary-rhlight uppercase tracking-[0em]"
        aria-label={label}
      >
        {label}
      </label>
      <div
        id={`component-pdp-configurator_options_${label}_wrapper`}
        data-testid={`component-pdp-configurator_options_${label}_wrapper`}
        className="flex flex-wrap gap-1.5"
      >
        {values.map((opt, index) => {
          const isUnavailable =
            opt.status !== "available" || index === values.length - 1;
          const isSelected = selectedValue.value === opt.value;

          const selectedOptionStyle = isUnavailable
            ? "bg-rh-buff text-rh-dark shadow-inner-stroke-thin-pdp-configurator"
            : "shadow-inner-stroke-pdp-configurator";

          const defaultOptionStyle = isUnavailable
            ? "shadow-inner-stroke-thin-pdp-configurator-unavailable text-rh-dark bg-rh-buff bg-opacity-30 hover:bg-[#F1F0ED]"
            : "shadow-inner-stroke-thin-pdp-configurator hover:shadow-inner-stroke-hover-pdp-configurator hover:bg-[#FFFFFF80]";

          return (
            <button
              key={opt.value}
              id={`component-pdp-configurator_value_${opt.value}`}
              data-testid={`component-pdp-configurator_value_${opt.value}`}
              onClick={() => handleSelect(opt)}
              aria-label={`${opt.value} ${
                isSelected ? pageContent?.selected : ""
              }`}
              aria-pressed={isSelected}
              className={cn(
                `h-9 relative cursor-pointer flex justify-center items-center`,
                variant === "square" ? "w-9 p-0" : "w-auto px-2 py-0",
                isUnavailable && strikeThroughStyle,
                isSelected ? selectedOptionStyle : defaultOptionStyle
              )}
            >
              <span
                className="whitespace-nowrap text-xs leading-[100%] font-primary-rhlight capitalize tracking-normal"
                dangerouslySetInnerHTML={{ __html: opt.value }}
              />
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default memoize(PDPConfiguratorOptions);
