import { PropTypes } from "aem/ComponentWrapper";

export default {
  title: "RHR Quadrant Slideshow",
  type: "rh/components/content/rhr-quadrant-slideshow",
  isContainer: true,
  config: {
    emptyLabel: "RHR Quadrant Slideshow",
    isEmpty: ({}: PropTypes) => true
  }
};
