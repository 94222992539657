import { PropTypes } from "aem/ComponentWrapper";

export default {
  title: "Wistia Hero",
  type: "rh/components/content/wistiahero",
  // type: "rh/components/content/wistia",
  isContainer: false,
  config: {
    emptyLabel: "Wistia Hero",
    isEmpty: (props: PropTypes) => !props.smId
  }
};
