import React, { FC } from "react";
import { DrawerHeaderProps } from "container-drawer-header/types";
import { InlineHeaderProps } from "container-inline-header/types";
import { RHRHeaderProps } from "container-rhr-header/types";
import memoize from "utils/memoize";
import HeaderSwitcher from "containers/HeaderSwitcher";
export interface HeaderSwitcherProps {
  InlineHeaderProps?: InlineHeaderProps;
  DrawerHeaderProps?: DrawerHeaderProps;
  RHRHeaderProps?: RHRHeaderProps;
}

export const GlobalHeader: FC<HeaderSwitcherProps> = props => {
  return <HeaderSwitcher {...props} isAem={true} />;
};

export default memoize(GlobalHeader);
