export const getPositionAttributes = (
  dynamicText: PromoDisplayLayerDynamicText
) => {
  if (dynamicText.styles) return {};

  let top = dynamicText.topCoordinate || "auto";
  let right = dynamicText.rightCoordinate || "auto";
  let bottom = dynamicText.bottomCoordinate || "auto";
  let left = dynamicText.leftCoordinate || "auto";
  let isVerticallyCentered = false;
  let isHorizontallyCentered = false;

  if (dynamicText.verticalAlign) {
    switch (dynamicText.verticalAlign) {
      case "top":
        top = "104px";
        break;
      case "bottom":
        bottom = "50px";
        break;
      case "center":
        top = "50%";
        bottom = "auto";
        isVerticallyCentered = true;
        break;
      default:
        break;
    }
  }

  if (dynamicText.horizontalAlign) {
    switch (dynamicText.horizontalAlign) {
      case "left":
        left = "50px";
        break;
      case "right":
        right = "50px";
        break;
      case "center":
        left = "50%";
        right = "auto";
        isHorizontallyCentered = true;
        break;
      default:
        break;
    }
  }

  let transform = "none";
  if (isHorizontallyCentered && isVerticallyCentered)
    transform = "translate(-50%, -50%)";
  else if (isHorizontallyCentered) transform = "translateX(-50%)";
  else if (isVerticallyCentered) transform = "translateY(-50%)";

  const styles = {
    bottom: bottom,
    left: left,
    right: right,
    top: top,
    transform: transform,
    width: dynamicText.width
  };

  return styles;
};

export default getPositionAttributes;
