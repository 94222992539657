// import loadable from "@loadable/component";
import AEMWrapper from "aem/ComponentWrapper";
import { /* React,  */ memo } from "react";
import meta from "./aem-meta";
import WistiaHero from "./index";

// export const FallbackComponent = props => {
//   console.count('fallback wistia')
//   console.warn('fallback wistia')
//   return (
//     <div style={{ aspectRatio: "16 /32", color:"pink" , backgroundColor:"yellow"}}>
//       FALLBACK WISTIA
//     </div>
//   );
// };

// const Component = loadable(
//   () => import(/* webpackChunkName: "component-rh-wistia-hero" */ "./index"),
//   {
//     fallback: <FallbackComponent />
//     //we're going to test this with SSR now with the new configuration
//     //ssr: false /* this component throws  a lot of errors on server side  */
//   }
// );

export default memo(AEMWrapper(meta, WistiaHero));
