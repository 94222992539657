import { PropTypes } from "aem/ComponentWrapper";

export default {
  title: "Form Text",
  type: "rh/components/form/text",
  config: {
    emptyLabel: "Text Input",
    isEmpty: (_: PropTypes) => false
  }
};
