import React, { FC, ChangeEvent, FocusEvent, useRef } from "react";
import {
  Controller,
  Control,
  FieldError,
  UseControllerOptions
} from "react-hook-form";
import RHTextField, { RHTextFieldProps } from "./index";
import memoize from "utils/memoize";

export type RHFormTextFieldProps = Omit<RHTextFieldProps, "error"> & {
  control: Control;
  rules?: UseControllerOptions["rules"];
  onInputBlur?: (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: string
  ) => void;
  onInputChange?: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: string
  ) => void;
  error?: FieldError;
  emailValidation?: Boolean;
  errorMessages?: Object;
  isCompanyFieldRequired?: any;
};

const RHFormTextField: FC<RHFormTextFieldProps> = ({
  control,
  label,
  name,
  defaultValue,
  children,
  onInputBlur,
  onInputChange,
  rules,
  select,
  error,
  emailValidation = false,
  onChange: onChangeProp,
  ...rest
}) => {
  const inputRef = useRef(null);

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      rules={rules}
      onFocus={() => {
        // Needed to wrap this in a if statement to prevent an error in form unit tests
        if (inputRef.current) {
          // when input is been disabled.
          inputRef.current.disabled = false;
          if (inputRef.current.scrollIntoView) {
            inputRef.current.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }
          inputRef.current.focus({ preventScroll: true });
        }
      }}
      render={({ onChange, onBlur, value }) => (
        <RHTextField
          select={select}
          value={emailValidation ? defaultValue : value}
          label={label}
          inputRef={inputRef}
          onBlur={e => {
            onBlur();
            if (onInputBlur) onInputBlur(e, name);
          }}
          onChange={e => {
            if (!emailValidation) onChange(e);
            if (onChangeProp) onChangeProp(e);
            if (onInputChange) onInputChange(e, name);
          }}
          error={Boolean(error)}
          helperText={error?.message}
          dropdowntype={true}
          {...(rest as RHTextFieldProps)}
        >
          {children}
        </RHTextField>
      )}
    />
  );
};

RHFormTextField.defaultProps = {
  defaultValue: ""
};

export default memoize(RHFormTextField);
