import React, { FC, useLayoutEffect, useRef } from "react";
import { PromoProps } from "component-promo";
import { useHistory } from "react-router";
import isAbsoluteUrl from "utils/isAbsoluteUrl";
import { useLocalization } from "hooks/useLocalization";

import useDialogAnchorPageLoadAction from "hooks/page-load-actions/useDialogAnchorPageLoadAction";
import useIsoRedirect from "hooks-use-isoredirect";
import { isSSRToggledCheck } from "utils/ssrHelpers";

export const PromoHTML: FC<PromoProps> = ({ data }) => {
  // Per Eugene, the usefulness of this script is questionable.
  // As of adding it around 03/16/20 the content team had not noticed it was missing
  // 03/24/20 - Commented out as it causes errors due to missing libraries presumably.
  // Also removed the Helmet component that loaded it
  // const embedUrl = `/assets/js/content-utilities.js`;

  const htmlPromoRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const prefix = useLocalization();
  const isSSRToggled = isSSRToggledCheck();

  useDialogAnchorPageLoadAction(htmlPromoRef, []);

  useLayoutEffect(() => {
    const onClick = (event: MouseEvent) => {
      const unformattedHref = (event.target as Element)
        ?.closest("a")
        ?.getAttribute("href");
      const href = unformattedHref
        ? encodeURI(decodeURIComponent(`${prefix}${unformattedHref}`))
        : "";

      if (href) {
        // mailto and tel hrefs should not be prevented or pushed on to history
        if (href.match(/((mailto:\w+)|(tel:\w+)).+/g)) {
          return;
        }

        event.preventDefault();

        if (isAbsoluteUrl(href) || isSSRToggled) {
          useIsoRedirect(href, "windowAssign");
        } else {
          history.push(href);
        }
      }
    };

    const div = htmlPromoRef.current;

    div?.addEventListener("click", onClick);
    return () => {
      div?.removeEventListener("click", onClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, htmlPromoRef]);

  const isAboveNavPromo = data?.name?.includes("Above-Nav-Promo");

  if (isAboveNavPromo) {
    return null;
  }

  // TODO / FIX
  // This file is no longer used and replaced with the AboveNavPromo component
  // Need to remove logic pertaining to this component but don't have time now
  // So just commenting it out and returning null
  return (
    <div
      ref={htmlPromoRef}
      className="component-promo-html"
      style={{ textAlign: "center" }}
      data-promo-id={data.id}
      data-promo-name={data.name}
      data-marketing-priority={data.marketingPriority}
      dangerouslySetInnerHTML={{ __html: data.text || "" }}
    />
  );
};

PromoHTML.defaultProps = {};

export default PromoHTML;
