import { PropTypes } from "aem/ComponentWrapper";

export default {
  title: "Form Option",
  type: "rh/components/form/options",
  config: {
    emptyLabel: "Form Option",
    isEmpty: (_: PropTypes) => false
  }
};
