import React, { FC } from "react";
// import loadable from "@loadable/component";
import AEMWrapper from "aem/ComponentWrapper";
import { AEMComponentProps } from "aem/types";

export type RHHtmlProps = AEMComponentProps & {
  htmlText?: string | "";
};

// const RHHtml = loadable(
//   () => import(/* webpackChunkName: "component-rh-html" */ "."),
//   {
//     fallback: <></>
//   }
// );
import RHHtml from ".";

export const AEMHtml: FC<RHHtmlProps> = props => {
  return (
    <>
      <RHHtml htmlText={""} {...props} />
    </>
  );
};
export default AEMWrapper(
  {
    type: "rh/components/content/rhhtml",
    config: {
      emptyLabel: "Enter HTML Markup",

      isEmpty: function (props: any) {
        return !props || !props.htmlText || props.htmlText.trim().length < 1;
      }
    }
  },
  AEMHtml
);
