import type { ComponentProps } from "react";

export default {
  title: "Image",
  type: "rh/components/content/simpleimage",
  isContainer: false,
  config: {
    emptyLabel: "Pick an image using the dialog",
    isEmpty: (props: ComponentProps<"img">) => !props.src
  }
};
