import AEMWrapper from "aem/ComponentWrapper";
import React from "react";
import meta from "./aem-meta";
import loadable from "@loadable/component";
const RHRestaurantsMainV2 = loadable(
  () => import(/* webpackChunkName: "component-rh-restaurants-v2" */ "."),
  { fallback: <></> }
);

export default AEMWrapper(meta, RHRestaurantsMainV2);
