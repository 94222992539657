import React, { FC } from "react";

type HtmlProps = {
  htmlText: string;
};
const RHHtml: FC<HtmlProps> = props => {
  let result = props.htmlText;
  return (
    <>
      <div
        dangerouslySetInnerHTML={{ __html: result }}
        id={"component-rh-html"}
      ></div>
    </>
  );
};

export default RHHtml;
