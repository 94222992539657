import useState from "hooks/useState";
import React, { FC, ImgHTMLAttributes } from "react";
import { useTheme } from "utils/material-ui-core";
import spinner from "./spinner.svg";

export interface RHSpinnerProps extends ImgHTMLAttributes<HTMLImageElement> {
  color?: "black" | "white";
}

const RHSpinner: FC<RHSpinnerProps> = ({ color = "black", style, ...rest }) => {
  const theme = useTheme();
  const [error, setError] = useState(false);

  if (error) {
    return null;
  }

  return (
    <img
      id={"component-rh-spinner"}
      data-testid="rh-spinner"
      alt="spinner"
      {...rest}
      src={spinner}
      style={{
        ...style
      }}
      className="!absolute !m-auto !left-0 !right-0 !top-0 !bottom-0 !max-w-[64px] !max-h-[64px] !pointer-events-none"
      onError={() => setError(true)}
    />
  );
};

RHSpinner.defaultProps = {};

export default RHSpinner;
