import { PropTypes } from "aem/ComponentWrapper";
import React from "react";

export default {
  title: "Link2",
  type: "rh/components/content/link2",
  isContainer: true,
  config: {
    emptyLabel: "Link2",
    isEmpty: ({ children }: PropTypes) => React.Children.count(children) === 0
  }
};
