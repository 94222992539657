import React from "react";
import { getSuperscriptSuffix } from "../helper";
import { DeliveredByProps } from "../type";
import memoize from "@RHCommerceDev/utils/memoize";
import { usePageContent } from "@RHCommerceDev/custom-providers/LocationProvider";

const DeliveredBy: React.FC<DeliveredByProps> = ({
  deliveryDate,
  prefix = "",
  suffix = "",
  className
}) => {
  const dateSuffix = getSuperscriptSuffix(deliveryDate);
  const { pageContent } = usePageContent();

  return (
    <span
      id={"component-pdp-cta_delivered_by"}
      data-testid={"component-pdp-cta_delivered_by"}
      className={className}
      aria-label={pageContent?.Delivery_info}
    >
      {prefix || pageContent?.Delivered_by} {deliveryDate}
      <sup className="lowercase text-xs leading-[100%] tracking-normal">
        {dateSuffix}
      </sup>{" "}
      <span className="normal-case">{suffix || pageContent?.TO}</span>
    </span>
  );
};

export default memoize(DeliveredBy);
