import yn from "yn";
import { PriceRange } from "..";

export const convertSkuResponsePriceInfoToRange = (
  data: SearchRecordProductPriceInfo | null,
  skuData: SkuResponsePriceInfo,
  userType: Maybe<string> = ""
): PriceRange => {
  const isFinalSale = (data?.salePriceLabel || "")
    .toLowerCase()
    .includes("final sale");
  const isSale = skuData?.onSale;
  return {
    regular: {
      label: "Regular",
      amount: skuData?.listPrice as number, // todo temporary patch... this conversion will probably go away in the future anyways
      strikethrough: yn(isSale) as boolean
    },
    ...(!isFinalSale && isSale
      ? {
          sale: {
            label: "Sale",
            amount: skuData?.salePrice as number,
            strikethrough: false
          }
        }
      : null),
    ...(isFinalSale
      ? {
          finalSale: {
            label: "Final Sale",
            amount: skuData?.salePrice as number,
            strikethrough: false
          }
        }
      : null),
    ...(userType?.toUpperCase() !== "TRADE" &&
    userType?.toUpperCase() !== "CONTRACT"
      ? {
          member: {
            label: "Member",
            amount: isSale
              ? (skuData?.memberOriginalPrice as number)
              : (skuData?.memberPrice as number),
            strikethrough: false,
            showMemberPrice: yn(skuData?.showMemberPrice)
          }
        }
      : null),
    ...(userType?.toUpperCase() === "TRADE"
      ? {
          trade: {
            label: "Trade",
            amount: skuData?.tradePrice as number,
            strikethrough: false
          }
        }
      : null),
    ...(userType?.toUpperCase() === "CONTRACT"
      ? {
          contract: {
            label: "Contract",
            amount: skuData?.contractPrice as number,
            strikethrough: false
          }
        }
      : null),
    ...(isSale && skuData?.memberOriginalPrice !== skuData?.memberPrice
      ? {
          lowestSkuMemberPrice: {
            label: "lowestSkuMemberPrice",
            amount: skuData?.memberPrice as number,
            strikethrough: false
          }
        }
      : null)
  };
};
