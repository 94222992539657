import AEMWrapper from "aem/ComponentWrapper";
import React from "react";
import meta from "./aem-meta";
import loadable from "@loadable/component";
const RHVideoMultipleLinks = loadable(
  () => import(/* webpackChunkName: "component-rh-video-multiple-links" */ "."),
  { fallback: <></> }
);

export default AEMWrapper(meta, RHVideoMultipleLinks);
