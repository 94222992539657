import { PropTypes } from "aem/ComponentWrapper";

export default {
  title: "RH Video Multiple Links",
  type: "rh/components/content/rh-video-multiple-links",
  isContainer: true,
  config: {
    emptyLabel: "RH Video Multiple Links",
    isEmpty: ({}: PropTypes) => true
  }
};
