import React from "react";
import memoize from "@RHCommerceDev/utils/memoize";
import { CloseIconProps } from "./types";

const CloseIcon: React.FC<CloseIconProps> = ({ onClick, className }) => (
  <svg
    id={"component-pdp-swatch-carousel_close_icon"}
    data-testid={"component-pdp-swatch-carousel_close_icon"}
    className={className || ""}
    onClick={onClick}
    width="41"
    height="41"
    viewBox="0 0 41 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_957_48147)">
      <path
        d="M11.1172 11.1172L29.8864 29.8864"
        stroke="white"
        strokeWidth="1.75"
      />
      <path
        d="M11.1172 29.8867L29.8864 11.1175"
        stroke="white"
        strokeWidth="1.75"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_957_48147"
        x="5.5"
        y="5.5"
        width="32.0039"
        height="32.0039"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" dy="1" />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.8 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_957_48147"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_957_48147"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default memoize(CloseIcon);
