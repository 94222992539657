import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import RHImage from "component-rh-image";
import { PromoProps } from "component-promo";
import handlePromoUrl from "utils/handlePromoUrl";
import { ProductImagePresetKeys } from "utils/getImageUrlWithPreset";
import useMediaString from "hooks/useMediaString";
import useIsoRedirect from "hooks-use-isoredirect";

export const PromoImage: FC<PromoProps> = ({
  data,
  loadingHeight,
  ...props
}) => {
  const history = useHistory();
  const mediaString = useMediaString();

  return data.imageUrl ? (
    <RHImage
      src={data.imageUrl || undefined}
      alt={data.dynamicText.alt || "RH Promo"}
      loadingHeight={loadingHeight}
      preset={`full-width-${mediaString}` as ProductImagePresetKeys}
      onClick={() => {
        if (data.targetUrl) {
          if (data.linkType === "new-window") {
            const win = useIsoRedirect(data.targetUrl, "windowOpen");
            if (win) {
              (win as Window).focus();
            }
          } else {
            handlePromoUrl(data.targetUrl, history);
          }
        }
      }}
      {...props}
    />
  ) : null;
};

PromoImage.defaultProps = {};

export default PromoImage;
