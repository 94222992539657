import AEMWrapper from "aem/ComponentWrapper";
import loadable from "@loadable/component";
import React from "react";

export default AEMWrapper(
  {
    type: "rh/components/content/dmvideo",
    config: {
      emptyLabel: "Dynamic Media video - RH",

      isEmpty: function (props: any) {
        return (
          !props ||
          !props.fileReference ||
          props.fileReference.trim().length < 1
        );
      }
    }
  },
  loadable(() => import(/* webpackChunkName: "component-rh-dmvideo" */ "."), {
    fallback: <></>
  })
);
