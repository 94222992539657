import { PropTypes } from "aem/ComponentWrapper";

export default {
  title: "Collection Carousel",
  type: "rh/components/content/collection-carousel",
  config: {
    emptyLabel: "Collection Carousel",
    isEmpty: (_: PropTypes) => false
  }
};
