import React, { FC, useEffect, useRef, useMemo } from "react";
import { TailwindTypography } from "@RHCommerceDev/component-tailwind-typography";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";
import useState from "@RHCommerceDev/hooks/useState";
import useMediaString from "@RHCommerceDev/hooks/useMediaString";
import { IMAGE_ASPECT_RATIO } from "@RHCommerceDev/utils/constants";
import memoize from "@RHCommerceDev/utils/memoize";
import useTypographyStyles from "@RHCommerceDev/hooks/useTypographyStyles";
import cn from "classnames";
import IframeDialog from "@RHCommerceDev/dialog-iframe";
import { usePageContent } from "@RHCommerceDev/custom-providers/LocationProvider";
import ImageCarousel from "./ImageCarousel";
import { isMobileOrTablet } from "@RHCommerceDev/hooks/useDeviceOrientation";
import RHSpinner from "@RHCommerceDev/component-rh-spinner";
import { getRhrHeaderHeight } from "@RHCommerceDev/utils/getRhrHeaderHeight";

export interface HeroImageProps {
  images: ProductAlternateImage[] | null; // Noticed that it can happen that null gets passed into images prop in production
  isColorizable?: boolean;
  objectFit?: string;
  selectedSwatchImageUrl?: string;
  selectedOptionLabel?: boolean;
  selectedLineItemOptionLabel?: boolean;
  onIndexChange?: () => void;
  isRHR?: boolean;
  isBannerVisible?: boolean;
  isSwatchSwitch?: number;
  saleSwatchId?: string;
  selectedSwatch?: ProductSwatch | null;
  changeSwatchImageOnLineItem?: React.Dispatch<React.SetStateAction<string>>;
  imagePresetOverride?: string | null;
  isDefaultImages?: boolean;
}

export const PDPHeroImage: FC<HeroImageProps> = ({
  images,
  isColorizable,
  objectFit,
  selectedSwatchImageUrl,
  selectedLineItemOptionLabel,
  isRHR,
  isBannerVisible,
  isSwatchSwitch = 0,
  changeSwatchImageOnLineItem,
  imagePresetOverride,
  isDefaultImages
}) => {
  const { pageContent } = usePageContent();
  const mediaString = useMediaString();
  const [isHeroImageUrlFailed, setIsHeroImageUrlFailed] =
    useState<boolean>(false);

  // index is undefined to prevent selection on thumbnail
  const [activeIndex, setActiveIndex] = useState<number | undefined>(0);
  const [video, setVideo] = useState<string>("");
  const [needMore, setNeedMore] = useState(false);
  const captionRef = useRef<HTMLSpanElement>(null);
  const rhrHeaderHeight = getRhrHeaderHeight(false, "default");
  const xsRhrHeaderHeight = getRhrHeaderHeight(false, "xs");

  const isRHRImageStyling = useMemo(
    () => isMobileOrTablet && isRHR,
    [isRHR, isMobileOrTablet]
  );

  let [imageList, setImageList] = useState<ProductAlternateImage[] | []>([
    {
      imageUrl: "",
      caption: "",
      video: ""
    }
  ]);

  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseCaption", "rhBaseBody1"]
  });
  if (processEnvServer && images?.length) {
    imageList = images;
  }
  useEffect(() => {
    if (images?.length) {
      setImageList(images);
    }
  }, [images]);

  if (!processEnvServer) {
    useEffect(() => {
      if (!needMore && captionRef.current) {
        const captionStyle = window.getComputedStyle(captionRef.current);
        const lineHeight = parseInt(captionStyle.lineHeight);
        const height = parseInt(captionStyle.height);

        if (lineHeight > 0 && height > lineHeight) {
          setNeedMore(true);
        }
      }
    }, [captionRef, needMore]);
  }

  useEffect(() => {
    // set swatch undefined if swatch is inactive
    if (selectedSwatchImageUrl) {
      // set index 0 for page load when swatch is active
      setActiveIndex(0);
      // set swatch if active
      if (selectedSwatchImageUrl !== (images || [])[0]?.imageUrl) {
        changeSwatchImageOnLineItem &&
          changeSwatchImageOnLineItem(selectedSwatchImageUrl);
      } else if (selectedSwatchImageUrl === (images || {})[0]?.imageUrl) {
        changeSwatchImageOnLineItem &&
          changeSwatchImageOnLineItem((images || [])[0]?.imageUrl);
      }
    }
  }, [selectedSwatchImageUrl]);

  const showCarousel = useMemo(
    () => images?.length === imageList?.length,
    [images, imageList]
  );

  return (
    <div
      className={`w-full flex flex-wrap box-border ${
        isDefaultImages ? "px-10" : "absolute"
      }`}
      style={{
        top: `-${!isMobileOrTablet ? rhrHeaderHeight : xsRhrHeaderHeight}px`
      }}
      id={"component-pdp-hero-image"}
    >
      <div
        className={`flex-grow-0 max-w-full basis-full m-0 box-border ${
          isRHRImageStyling ? "m-auto w-[87%]" : ""
        } ${
          isRHR
            ? "relative pt-0 pb-0 px-4 sm:pt-0 sm:px-6 md:pt-0 md:pb-0 md:px-0 print:hidden"
            : "relative pb-0 md:pb-0 print:hidden"
        }`}
      >
        <div
          className={`relative w-full ${
            isRHRImageStyling ? "m-auto" : ""
          } pdpImageWrapper`}
        >
          {showCarousel ? (
            <ImageCarousel
              slides={imageList}
              setActiveIndex={setActiveIndex}
              pdpImageProps={{
                isRHRImageStyling,
                isHeroImageUrlFailed,
                imagePresetOverride,
                mediaString,
                IMAGE_ASPECT_RATIO,
                objectFit: isDefaultImages
                  ? !isMobileOrTablet
                    ? objectFit
                    : ""
                  : "fill",
                isDefaultImages: isDefaultImages
              }}
              setVideo={setVideo}
              selectedSwatchUrl={{ isColorizable, selectedSwatchImageUrl }}
            />
          ) : (
            <div className="min-h-96">
              <RHSpinner />
            </div>
          )}

          {(!isColorizable &&
            isBannerVisible &&
            (isSwatchSwitch > 1 || selectedLineItemOptionLabel)) ||
          ((selectedSwatchImageUrl == "" || isHeroImageUrlFailed) &&
            isSwatchSwitch > 0) ? (
            <div
              className={
                "absolute top-0 w-full h-16 bg-black-40 flex items-center justify-center"
              }
            >
              <TailwindTypography
                className={cn(typographyStyles.rhBaseBody1, "!text-white")}
              >
                {pageContent?.COLOR_PREVIEW_AVAILABLE_SOON}
              </TailwindTypography>
            </div>
          ) : null}
        </div>
        {!!video && !processEnvServer && (
          <IframeDialog
            src={
              video.includes(".com")
                ? undefined
                : `https://www.youtube.com/embed/${video}?modestbranding=1&rel=0&showinfo=0&autoplay=1&controls=0`
            }
            srcDoc={
              video.includes(".com")
                ? `<div style="
                width: 100%;
                height: calc(100vh - 56px);
                display: flex;
                align-items: center;
                justify-content: center;
            "><video autoplay name="media" loop style="
            object-fit: scale-down;
            width: inherit;
        "><source src="${video}" type="video/mp4"></video></div>`
                : undefined
            }
            open={!!video}
            onClose={() => setVideo("")}
          />
        )}
      </div>
    </div>
  );
};

PDPHeroImage.defaultProps = {};

export default memoize(PDPHeroImage);
