import { PropTypes } from "aem/ComponentWrapper";
import React from "react";

export default {
  title: "Modal",
  type: "rh/components/content/modal",
  isContainer: true,
  config: {
    emptyLabel: "Modal",
    isEmpty: ({ children }: PropTypes) => React.Children.count(children) === 0
  }
};
