import AEMWrapper from "aem/ComponentWrapper";
import ContainerWrapper from "concierge-alias-aem/ContainerWrapper";
import loadable from "@loadable/component";
import meta from "./aem-meta";
import React from "react";

const Component = loadable(
  () => import(/* webpackChunkName: "component-rh-modal" */ "."),
  {
    fallback: <></>
  }
);

export default AEMWrapper(meta, Component, {
  wrapper: ContainerWrapper
});
