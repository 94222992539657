import { PropTypes } from "aem/ComponentWrapper";

export default {
  title: "Submenu",
  type: "rh/components/content/rhsubmenu",
  isContainer: false,
  config: {
    emptyLabel: "Submenu - No items configured",
    isEmpty: (props: PropTypes) =>
      Object.values(props?.submenuItems ?? {}).length === 0
  }
};
