import React, { Fragment } from "react";
import memoize from "@RHCommerceDev/utils/memoize";
import { PreviousArrowIcon, NextArrowIcon } from "./SvgArrowsIcon";

const Arrows = ({
  scrollPrev,
  scrollNext,
  prevClassName = "",
  nextClassName = ""
}) => {
  return (
    <Fragment>
      <button
        className={`embla__prev m-0 p-0 flex w-10 justify-center items-center absolute bg-transparent border-0 cursor-pointer ${prevClassName} left-0 top-[36%]`}
        onClick={scrollPrev}
        aria-label="previous"
      >
        <PreviousArrowIcon className="mx-auto my-[26px]" />
      </button>
      <button
        className={`embla__next m-0 p-0 flex w-10 justify-center items-center  absolute bg-transparent border-0 cursor-pointer ${nextClassName} right-0 top-[36%]`}
        onClick={scrollNext}
        aria-label="next"
      >
        <NextArrowIcon className="mx-auto my-[26px]" />
      </button>
    </Fragment>
  );
};

export default memoize(Arrows);
