import React from "react";
import { CTAButtonProps } from "../type";
import memoize from "@RHCommerceDev/utils/memoize";
import { usePageContent } from "@RHCommerceDev/custom-providers/LocationProvider";

const CTAButton: React.FC<CTAButtonProps> = ({
  onClick,
  className = "flex items-center justify-center h-9 w-[143px] text-lg text-white uppercase font-primary-rhthin leading-[100%] bg-black",
  label = ""
}) => {
  const { pageContent } = usePageContent();
  return (
    <button
      id={"component-pdp-cta_cta_button"}
      data-testid={"component-pdp-cta_cta_button"}
      className={className}
      onClick={onClick}
      aria-label={pageContent?.Add_to_order}
    >
      {label || pageContent?.ORDER}
    </button>
  );
};

export default memoize(CTAButton);
