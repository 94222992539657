import React from "react";
import useDidMountEffect from "hooks/useDidMountEffect";
import { useState } from "react";
import memoize from "utils/memoize";
import { useMediaQuery, Theme } from "utils/material-ui-core";
const DragPanImage = props => {
  const { children, isZoomViewerDialogOpen, imageLayout } = props;

  const [imageX, setImageX] = useState(0);
  const [imageY, setImageY] = useState(0);
  const [scale, setScale] = useState(2.5);
  const [isDragging, setIsDragging] = useState(false);
  const [lastX, setLastX] = useState(0);
  const [lastY, setLastY] = useState(0);

  const smDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));

  const handleMouseDown = e => {
    e.preventDefault();
    setIsDragging(!isZoomViewerDialogOpen ? true : false);
    setLastX(e.clientX);
    setLastY(e.clientY);
  };

  useDidMountEffect(() => {
    setScale(isZoomViewerDialogOpen ? 1 : 2.5);
    setImageX(0);
    setImageY(0);
    setLastX(0);
    setLastY(0);
  }, [isZoomViewerDialogOpen]);

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handlePointerUp = e => {
    if (e?.clientX) {
      setIsDragging(false);
    }
  };

  const boundImageWithinContainer = (e, clientX, clientY, deltaX, deltaY) => {
    const rect = e.currentTarget.getBoundingClientRect();
    if (rect.left >= rect.width / 3 - 100) {
      if (rect.left <= rect.width / 3 && clientX < lastX) {
        setImageX(imageX + deltaX);
      }
    } else {
      if (Math.abs(rect.left) + 200 >= rect.width) {
        if (Math.abs(rect.left) <= rect.width && clientX > lastX) {
          setImageX(imageX + deltaX);
        }
      } else {
        setImageX(imageX + deltaX);
      }
    }

    if (rect.top >= rect.height / 3 - (smDown ? 100 : 400)) {
      if (rect.top <= rect.height / 3 && clientY < lastY) {
        setImageY(imageY + deltaY);
      }
    } else {
      if (Math.abs(rect.top) + (smDown ? 400 : 600) >= rect.height) {
        if (Math.abs(rect.top) <= rect.height && clientY > lastX) {
          setImageY(imageY + deltaY);
        }
      } else {
        setImageY(imageY + deltaY);
      }
    }
  };

  const handleMouseMove = e => {
    if (!isDragging) return;
    const deltaX = e.clientX - lastX;
    const deltaY = e.clientY - lastY;

    boundImageWithinContainer(e, e?.clientX, e?.clientY, deltaX, deltaY);
    setLastX(e.clientX);
    setLastY(e.clientY);
  };

  const handleTouchMove = e => {
    if (!isDragging) return;
    const deltaX = e?.touches[0]?.clientX - lastX;
    const deltaY = e?.touches[0]?.clientY - lastY;

    boundImageWithinContainer(
      e,
      e?.touches[0]?.clientX,
      e?.touches[0]?.clientY,
      deltaX,
      deltaY
    );
    setLastX(e?.touches[0]?.clientX);
    setLastY(e?.touches[0]?.clientY);
  };

  const imageStyle = {
    transform: `translate(${imageX}px, ${imageY}px) scale(${scale})`,
    ...(!isZoomViewerDialogOpen && { cursor: isDragging ? "grabbing" : "grab" })
  };

  const containerStyle = imageLayout === "horizontal" ? "rightCenter" : "";

  return (
    <div
      className={`image-container ${containerStyle}`}
      id="image-container"
      onTouchMove={handleTouchMove}
      onPointerDown={handleMouseDown}
      onPointerUp={handleMouseUp}
      onPointerMove={handleMouseMove}
      onPointerLeave={handlePointerUp}
      style={imageStyle}
    >
      {children}
    </div>
  );
};

export default memoize(DragPanImage);
