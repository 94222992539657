import AEMWrapper from "aem/ComponentWrapper";
import loadable from "@loadable/component";
import React from "react";

export default AEMWrapper(
  {
    type: "rh/components/content/dm-image-smart-crop",
    config: {
      emptyLabel: "Dynamic Media Smart Crop Image - RH",

      isEmpty: function (props: any) {
        return (
          !props ||
          !props.fileReference ||
          props.fileReference.trim().length < 1
        );
      }
    }
  },
  loadable(
    () =>
      import(/* webpackChunkName: "component-rh-dm-smart-crop-image" */ "."),
    {
      fallback: <></>
    }
  )
);
