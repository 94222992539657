import { RHImageProps } from ".";

export default {
  title: "Image",
  type: "rh/components/content/image",
  isContainer: false,
  config: {
    emptyLabel: "Pick an image using the dialog",
    isEmpty: (props: RHImageProps) => !props.src
  }
};
