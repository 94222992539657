import React, { FC, CSSProperties, useState } from "react";
import he from "he";
import { Typography, Grid, makeStyles } from "utils/material-ui-core";
import memoize from "utils/memoize";
import useTypographyStyles from "hooks/useTypographyStyles";
import { useEnv } from "hooks/useEnv";
import classNames from "classnames";
import { processEnvServer } from "hooks/useSsrHooks";
import { getReqContext } from "utils/reqContext";

export interface OptionLabelMobileProps {
  label: string;
  value: string;
  labelStyles?: CSSProperties;
  valueStyles?: CSSProperties;
  className?: string;
}

const useStyles = makeStyles(() => ({
  optionValue: {
    height: "auto",
    minHeight: "18px",
    width: "auto",
    minWidth: "256px"
  }
}));

const OptionLabelMobile: FC<OptionLabelMobileProps> = ({
  label,
  value,
  labelStyles,
  valueStyles,
  className
}) => {
  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseBody1", "rhBaseBody2"]
  });
  const env = useEnv();
  const classes = useStyles();
  const [numberOfLines, setNumberOfLines] = useState<number>(1);
  const getOptionLabelHeight = (typeRef: HTMLDivElement | null) => {
    if (!!typeRef) {
      const lineHeight = 18;
      const containerHeight = typeRef?.clientHeight;
      const numLines = Math.round(containerHeight / lineHeight);
      setNumberOfLines(numLines);
    }
  };
  const req = getReqContext();
  const isCartPage =
    (!processEnvServer
      ? location.pathname?.includes("/checkout/shopping_cart.jsp")
      : req?.path?.includes("/checkout/shopping_cart.jsp")) || false;
  return (
    <Grid container spacing={0} className={className}>
      <Grid item xs={isCartPage ? false : 4} sm={isCartPage ? false : 4}>
        <span
          ref={getOptionLabelHeight}
          className="min-w-[90px] w-[90px] h-auto absolute invisible"
          style={{ ...labelStyles }}
        >
          {label}
        </span>
        <Typography
          className={`${typographyStyles.rhBaseBody1} ${
            isCartPage
              ? "!flex flex-col !mr-2 w-[90px] min-h-[18px]"
              : "text-[#808080]"
          }`}
          style={{
            ...labelStyles,
            ...(isCartPage && {
              justifyContent: numberOfLines > 1 ? "center" : "flex-start",
              height:
                numberOfLines > 1
                  ? `${18 + (numberOfLines - 1) * 12}px`
                  : "18px",
              lineHeight: numberOfLines > 1 ? "12.1px" : "18px"
            })
          }}
        >{`${label}`}</Typography>
      </Grid>
      <Grid item xs={8} sm={8}>
        <Typography
          className={classNames([
            typographyStyles.rhBaseBody2,
            isCartPage && classes.optionValue
          ])}
          dangerouslySetInnerHTML={{
            __html: he.decode(value)
          }}
          style={{
            ...(isCartPage ? { marginBottom: 0 } : { marginBottom: 10 }),
            ...valueStyles
          }}
        />
      </Grid>
    </Grid>
  );
};

export default memoize(OptionLabelMobile);
