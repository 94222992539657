import { useState, useCallback, ChangeEvent, KeyboardEvent } from "react";
import { processEnvServer } from "hooks/useSsrHooks";
import React, { FC } from "react";
import sanitizeHtml from "sanitize-html";
import {
  makeStyles,
  createStyles,
  Theme,
  IconButton,
  useTheme,
  InputBase,
  InputBaseProps,
  useMediaQuery
} from "@material-ui/core";
import RHRSearchIcon from "icon-search-rhr";
import { useCookiesWithPermission } from "hooks/useCookiesWithPermission";
import useIsoRedirect from "hooks/useIsoRedirect";
import { useLocalization } from "hooks/useLocalization";
import { usePageContent } from "customProviders/LocationProvider";
import { SEARCH_LABEL, SUBMIT_SEARCH } from "resources/rh-header.json";
import { useTypographyStyles } from "hooks/useTypographyStyles";
import classNames from "classnames";
import {
  FONT_BARON_SANS,
  RH_MEDIUM,
  RH_DARK,
  RH_BLACK,
  RH_WHITE
} from "utils/constants";
import { codePattern } from "utils/regex";
import { formatSearchText } from "utils/formatSearchUrl";
import yn from "yn";
import { useEnv } from "hooks/useEnv";
import { useHistory } from "react-router";

export const useStyles = (isDarkMode, color) =>
  makeStyles((theme: Theme) =>
    createStyles({
      searchIconContainer: {
        padding: "5px 0 5px 3px",
        position: "absolute",
        left: 0,
        top: 0,
        zIndex: 1,
        borderRadius: "0px",
        marginLeft: "6px",
        "&:focus": {
          outline: "#015fcc auto 1px"
        }
      },
      searchInputRoot: {
        borderRadius: 38,
        width: 187,
        height: 36,
        background: "transparent",
        border: `0.75px solid ${
          color
            ? color
            : isDarkMode
            ? theme.palette.common.white
            : theme.palette.common.black
        }`
      },
      searchInput: {
        padding: "8px !important",
        marginLeft: "12px",
        paddingLeft: "32px !important",
        fontFamily: `${FONT_BARON_SANS} !important`,
        color: isDarkMode ? RH_MEDIUM : RH_DARK
      },
      searchTypingText: {
        color: `${
          isDarkMode ? `${RH_WHITE} !important` : `${RH_BLACK} !important`
        }`
      }
    })
  );

interface HeaderSearchTextFieldProps extends InputBaseProps {
  isDarkMode?: boolean;
  isHeaderSearch?: boolean;
  openDrawer: () => void;
}

const HeaderSearchTextField: FC<
  HeaderSearchTextFieldProps & { color?: string }
> = ({
  isHeaderSearch,
  isDarkMode,
  openDrawer,
  color = undefined,
  ...props
}) => {
  const history = useHistory();
  const classes = useStyles(isDarkMode, color)();
  const rhTypoClasses = useTypographyStyles({
    keys: ["reimagineBody2"]
  });
  const theme = useTheme();
  const prefix = useLocalization();
  const [searchText, setSearchText] = useState<string>("");
  const [showTypeAhead, setShowTypeAhead] = useState(false);
  const { setStorageValueWrapper } = useCookiesWithPermission();
  const { pageContent } = usePageContent();
  const mdDown = useMediaQuery(theme.breakpoints.between(0, 992));
  const env = useEnv();

  const handleTypeahead = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!processEnvServer) {
        setStorageValueWrapper({
          storageKey: "analytics-access",
          value: "typeahead"
        });
      }
      setSearchText(event.target.value);
      if (!showTypeAhead && !isHeaderSearch) {
        setShowTypeAhead(true);
      }
    },
    [showTypeAhead, isHeaderSearch, setStorageValueWrapper]
  );

  const handleEnterPressed = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      const searchPath = yn(env?.FEATURE_URL_CHANGE)
        ? formatSearchText(searchText)
        : `results.jsp?Ntt=${encodeURIComponent(
            searchText
          )}&Ns=product.sale%7C1`;
      if (
        !!searchText &&
        event.key === "Enter" &&
        !!codePattern.test(searchText)
      ) {
        if (!processEnvServer) {
          sessionStorage.removeItem("pdpViewItem");
          sessionStorage.removeItem("rhrGridChoice");
          setStorageValueWrapper({
            storageKey: "analytics-access",
            value: "direct"
          });
          setStorageValueWrapper({
            storageKey: "analytics-searchbar-enter",
            value: "true"
          });
        }
        if (yn(env?.FEATURE_PG_ENHANCEMENT)) {
          history.push(sanitizeHtml(`${prefix}/search/${searchPath}`));
        } else {
          useIsoRedirect(
            sanitizeHtml(
              `${window?.location?.origin}${prefix}/search/${searchPath}`
            ),
            "windowAssign"
          );
        }

        setSearchText("");
      }
    },

    [searchText, prefix, setStorageValueWrapper]
  );

  return (
    <div className={"relative"}>
      <IconButton
        disableRipple
        id="container-rhrSearchField_search-btn"
        onClick={() => openDrawer()}
        className={classes.searchIconContainer}
        aria-label={pageContent?.HEADER_CONTENT?.SUBMIT_SEARCH ?? SUBMIT_SEARCH}
      >
        <RHRSearchIcon
          className={"!w-[26px] !h-[26px] sm:w-6.5 sm:h-6.5"}
          style={{
            color: color
              ? color
              : isDarkMode
              ? theme.palette.common.white
              : theme.palette.common.black
          }}
        />
      </IconButton>
      {!mdDown && (
        <InputBase
          {...props}
          className={classes?.searchInputRoot}
          inputProps={{
            className: classNames(
              classes?.searchInput,
              rhTypoClasses.reimagineBody2,
              {
                [classes?.searchTypingText]: searchText?.length
              }
            ),
            "aria-label":
              pageContent?.HEADER_CONTENT?.SEARCH_LABEL ?? SEARCH_LABEL,
            placeholder: searchText
              ? searchText
              : pageContent?.HEADER_CONTENT?.SEARCH_LABEL
              ? pageContent?.HEADER_CONTENT?.SEARCH_LABEL
              : SEARCH_LABEL
          }}
          onChange={handleTypeahead}
          onKeyUp={handleEnterPressed}
        />
      )}
    </div>
  );
};

export default HeaderSearchTextField;
