import loadable from "@loadable/component";
import AEMWrapper from "aem/ComponentWrapper";
import ContainerWrapper from "concierge-alias-aem/ContainerWrapper";
import React from "react";
import meta from "./aem-meta";
import { processEnvServer } from "hooks/useSsrHooks";

export const FallbackComponent = props => {
  return <div style={{ height: props.placeholderHeight || "1000px" }}></div>;
};

const Component = loadable(
  () =>
    import(
      /* webpackChunkName: "component-media-player/aem-component" */ "./aem-component"
    ),
  {
    fallback: <FallbackComponent />,
    ssr: false /* this component throws  a lot of errors on server side  */
  }
);

export default AEMWrapper(
  meta,
  processEnvServer ? FallbackComponent : Component,
  {
    wrapper: ContainerWrapper
  }
);
