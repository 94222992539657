import React, { FC, CSSProperties } from "react";
import { processEnvServer } from "hooks/useSsrHooks";
export type ImageDimensions = {
  imageWidth: number;
  imageHeight: number;
};

export interface ImageMapAreaProps {
  dimensions: ImageDimensions;
  coords: string;
  onClick: () => void;
  link?: any;
}

export const ImageMapArea: FC<ImageMapAreaProps> = ({
  dimensions,
  coords,
  onClick,
  link = ""
}) => {
  const [left, top, right, bottom] = (coords || "0,0,0,0").split(",");
  const styles: CSSProperties = {
    position: "absolute",
    cursor: "pointer",
    left: `${(Number(left) / dimensions.imageWidth) * 100}%`,
    top: `${(Number(top) / dimensions.imageHeight) * 100}%`,
    right: `${(1 - Number(right) / dimensions.imageWidth) * 100}%`,
    bottom: `${(1 - Number(bottom) / dimensions.imageHeight) * 100}%`
  };
  return (
    <>
      {processEnvServer ? (
        <a href={link} style={styles} />
      ) : (
        <div style={styles} onClick={onClick} />
      )}
    </>
  );
};

export default ImageMapArea;
