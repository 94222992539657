import React, { FC } from "react";
import { MenuItem, MenuList } from "utils/material-ui-core";
import { OptionsProps } from "../index";

export const MenuListOptions: FC<OptionsProps> = ({
  options = [],
  value,
  onChange,
  ...rest
}) => {
  return (
    <MenuList autoFocusItem disableListWrap disablePadding>
      {options.map(option => (
        <MenuItem
          key={option.key}
          onClick={() => {
            onChange?.(option.value);
          }}
          selected={value === option.value}
          divider
          button
        >
          {option.display}
        </MenuItem>
      ))}
    </MenuList>
  );
};

export default MenuListOptions;
