import React, { FC, useState, useEffect, useMemo } from "react";
import { makeStyles, createStyles, Theme } from "utils/material-ui-core";
import RHLink from "component-rh-link";
import classNames from "classnames";
import PromosDialogDesktop from "dialog-promos-dialog-desktop";
import RequestConsultationDialog from "dialog-request-consultation";
import CustomWindowDialog from "dialog-custom-window";
import TradeRequestDialog from "dialog-trade-request";
import { useQuery } from "@apollo/client";
import { queryPromoDisplay } from "graphql-client/queries/promo-display";
import { LayerProps } from "./";
import getPositionAttributes from "./getPositionAttributes";
import parse from "style-to-object";
import useCTAPageLoadAction from "hooks/page-load-actions/useCTAPageLoadAction";
import stripTags from "utils/stripTags";
import useParams from "hooks/useParams";
import {
  DIALOG_TRADE_REQUEST_KEY,
  DIALOG_INTERIOR_DESIGN_KEY,
  DIALOG_CUSTOM_WINDOW_KEY
} from "component-promo/constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    microButton: {
      position: "absolute",
      overflowX: "hidden",
      background: "none",
      border: "1px solid white",
      color: "white",
      cursor: "pointer",
      display: "block",
      fontSize: 12,
      fontWeight: "bold",
      letterSpacing: 1.13,
      minWidth: 215,
      padding: 20,
      textAlign: "center",
      textTransform: "uppercase",
      transition: "all 1s ease",
      width: "auto",
      zIndex: 1,
      "&:hover": {
        background: "white",
        color: "black"
      }
    },
    arrow: {
      "&:after": {
        content: '"\u2192"',
        fontSize: 14,
        paddingLeft: 10
      }
    },
    blackOutline: {
      borderColor: "black",
      color: "black",
      "&:hover": {
        background: "black",
        color: "white"
      }
    },
    movie: {
      opacity: 0,
      border: "none",
      width: "100%",
      height: "75%",
      cursor: "pointer"
    }
  })
);

export const CallToActionLayer: FC<LayerProps> = ({ layer }) => {
  const classes = useStyles();

  const params = useParams({ showPromo: "" });
  const [promosDialogOpen, setPromosDialogOpen] = useState(false);
  const [consulationDialogOpen, setConsulationDialogOpen] = useState(false);
  const [tradeRequestDialogOpen, setTradeRequestDialogOpen] = useState(false);
  const [customWindowDialogOpen, setCustomWindowDialogOpen] = useState(false);

  const { data: { promoDisplay } = {} as Query, loading } = useQuery<Query>(
    queryPromoDisplay,
    {
      variables: {
        id: layer.dynamicText.promoId
      },
      skip: !layer.dynamicText.promoId
    }
  );

  const onEnd = useMemo(() => {
    if (
      !loading &&
      layer.dynamicText.promoId &&
      layer.dynamicText.promoId === params.showPromo
    ) {
      return () => {
        setPromosDialogOpen(false);
      };
    }
  }, [params.showPromo, loading, layer.dynamicText.promoId]);

  useEffect(() => {
    if (
      !loading &&
      layer.dynamicText.promoId &&
      layer.dynamicText.promoId === params.showPromo
    ) {
      setPromosDialogOpen(true);
    }
  }, [loading, layer.dynamicText.promoId, params.showPromo]);
  useCTAPageLoadAction(
    {
      [DIALOG_CUSTOM_WINDOW_KEY]: () => setCustomWindowDialogOpen(true),
      [DIALOG_INTERIOR_DESIGN_KEY]: () => setConsulationDialogOpen(true),
      [DIALOG_TRADE_REQUEST_KEY]: () => setTradeRequestDialogOpen(true)
    },
    layer.dynamicText.modalType,
    []
  );

  return (
    <>
      <RHLink
        underline="none"
        style={{
          ...getPositionAttributes(layer.dynamicText),
          ...parse(layer.dynamicText.styles ?? "")
        }}
        className={classNames(classes.microButton, {
          [classes.arrow]: layer.dynamicText?.classes?.includes("arrow"),
          [classes.blackOutline]:
            layer.dynamicText?.classes?.includes("black-outline"),
          [classes.movie]: layer.dynamicText?.classes?.includes("movie")
        })}
        to={layer.path}
        onClick={onCallToActionClicked}
        data-cta-link={stripTags(layer.htmlText)
          .replace(/ /g, "-")
          .toLowerCase()}
      >
        {layer.htmlText}
      </RHLink>
      {promoDisplay && (
        <PromosDialogDesktop
          promo={promoDisplay}
          open={promosDialogOpen}
          onClose={() => setPromosDialogOpen(false)}
          onEnd={onEnd}
        />
      )}
      <TradeRequestDialog
        open={tradeRequestDialogOpen}
        onClose={() => setTradeRequestDialogOpen(false)}
      />
      <RequestConsultationDialog
        open={consulationDialogOpen}
        onClose={() => setConsulationDialogOpen(false)}
      />
      <CustomWindowDialog
        open={customWindowDialogOpen}
        onClose={() => setCustomWindowDialogOpen(false)}
      />
    </>
  );

  function onCallToActionClicked(event: React.MouseEvent) {
    if (!layer.modal || layer.modal === "false") return;

    if (layer.dynamicText.modalType === DIALOG_INTERIOR_DESIGN_KEY) {
      setConsulationDialogOpen(true);
    } else if (layer.dynamicText.modalType === DIALOG_TRADE_REQUEST_KEY) {
      setTradeRequestDialogOpen(true);
    } else if (layer.dynamicText.modalType === DIALOG_CUSTOM_WINDOW_KEY) {
      setCustomWindowDialogOpen(true);
    } else if (layer.dynamicText?.promoId) {
      setPromosDialogOpen(true);
    } else {
      setConsulationDialogOpen(true);
    }
  }
};

export default CallToActionLayer;
