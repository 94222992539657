import EditableContainer, {
  EditableContainerProps
} from "aem/ui/authoring/EditableContainer";
import React, { FC } from "react";
import RHRCarousel, { RHRCarouselProps } from ".";

export type AEMRHRCarouselProps = RHRCarouselProps & EditableContainerProps;

export const AEMRHRCarousel: FC<AEMRHRCarouselProps> = ({
  children,
  collpaseSlidesInEditMode,
  isInEditor,
  ...rest
}) => {
  if (isInEditor) {
    const { containerProps, placeholderComponent } = rest;

    return (
      <EditableContainer
        containerProps={containerProps}
        placeholderComponent={placeholderComponent}
        showContentInEditMode={!collpaseSlidesInEditMode}
      >
        {children}
      </EditableContainer>
    );
  }

  return <RHRCarousel {...rest}>{children}</RHRCarousel>;
};

export default AEMRHRCarousel;
