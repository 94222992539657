export const sanitizedImages = (
  images: string[],
  productDetails?: any
): string[] => {
  const SPLIT = "?";
  if (productDetails?.alternateImages?.length) {
    const sanitizeImgs = images
      ?.filter(Boolean)
      ?.map((url: string) => {
        const [path, search = ""] = url?.split(SPLIT);
        return { path, search };
      })
      .reduce((acc, curr) => {
        if (acc.find((d: any) => d?.path === curr?.path)) {
          acc.push({ ...curr, repeated: true });
        } else {
          acc.push({ ...curr, repeated: false });
        }
        return acc;
      }, [] as { path: string; search: string; repeated: boolean }[])
      ?.filter(d => !d?.repeated)
      ?.map(d =>
        [d?.path, d?.search]
          ?.filter(Boolean)
          ?.join(SPLIT)
          ?.replace(/\$.*\$/, "")
      );
    return sanitizeImgs;
  } else {
    return [productDetails?.imageUrl];
  }
};

const arrowDistanceToEdge = "0px";
const arrowPadding = "0px";
const arrowSide = "15px";

export const getArrowStyles = (arrowSideKey: "right" | "left") => ({
  carouselArrowGridStyles: {
    [arrowSideKey]: arrowDistanceToEdge,
    padding: arrowPadding
  },
  carouselArrowStyles: {
    width: arrowSide,
    fontSize: arrowSide
  }
});

const imageNPFullWidth = "$np-fullwidth";
const imageNPCommon = "$np-common";
const imageBreakPoint = {
  md: "-md",
  lg: "-lg",
  sm: "-sm",
  xs: "-xs",
  xl: "-xl"
};

export const getPresetMap = (
  isConciergeRHRImage,
  isRHRImage,
  imagePresetOverride?: Maybe<string> | undefined
) => {
  const imagePresetNP = imagePresetOverride?.length
    ? imagePresetOverride
    : imageNPFullWidth;
  const imagePresetNPCommon = imagePresetOverride?.length
    ? imagePresetOverride
    : imageNPCommon;
  return {
    xlUp: {
      "col-1": "",
      "col-2": isConciergeRHRImage
        ? `$CON_IS_768$`
        : isRHRImage
        ? `${imagePresetNP + imageBreakPoint["md"]}$`
        : `${imagePresetNP + imageBreakPoint["lg"]}$`,
      "col-3": isConciergeRHRImage
        ? `$CON_IS_768$`
        : isRHRImage
        ? `${imagePresetNP + imageBreakPoint["sm"]}$`
        : `${imagePresetNP + imageBreakPoint["md"]}$`
    },
    lgUp: {
      "col-1": "",
      "col-2": isConciergeRHRImage
        ? `$CON_IS_768$`
        : isRHRImage
        ? `${imagePresetNP + imageBreakPoint["sm"]}$`
        : `${imagePresetNP + imageBreakPoint["md"]}$`,
      "col-3": isConciergeRHRImage
        ? `$CON_IS_375$`
        : isRHRImage
        ? `${imagePresetNP + imageBreakPoint["xs"]}$`
        : `${imagePresetNP + imageBreakPoint["sm"]}$`
    },
    mdUp: {
      "col-1": "",
      "col-2": isConciergeRHRImage
        ? `$CON_IS_768$`
        : isRHRImage
        ? `${imagePresetNPCommon + imageBreakPoint["md"]}$`
        : `${imagePresetNPCommon + imageBreakPoint["lg"]}$`,
      "col-3": isConciergeRHRImage
        ? `$CON_IS_375$`
        : isRHRImage
        ? `${imagePresetNPCommon + imageBreakPoint["sm"]}$`
        : `${imagePresetNPCommon + imageBreakPoint["md"]}$`
    },
    smUp: {
      "col-1": "",
      "col-2": isConciergeRHRImage
        ? `$CON_IS_375$`
        : isRHRImage
        ? `${imagePresetNPCommon + imageBreakPoint["sm"]}$`
        : `${imagePresetNPCommon + imageBreakPoint["md"]}$`,
      "col-3": isConciergeRHRImage
        ? `$CON_IS_375$`
        : isRHRImage
        ? `${imagePresetNPCommon + imageBreakPoint["xs"]}$`
        : `${imagePresetNPCommon + imageBreakPoint["sm"]}$`
    },
    xsUp: {
      "col-1": isConciergeRHRImage
        ? `$CON_IS_375$`
        : isRHRImage
        ? `${imagePresetNPCommon + imageBreakPoint["xs"]}$`
        : `${imagePresetNPCommon + imageBreakPoint["sm"]}$`,
      "col-2": isConciergeRHRImage
        ? `$CON_IS_375$`
        : isRHRImage
        ? `${imagePresetNPCommon + imageBreakPoint["xs"]}$`
        : `${imagePresetNPCommon + imageBreakPoint["xs"]}$`,
      "col-3": ""
    },
    rhrThreeK: `$highres-zoom-3K-WRH$`,
    threeK: `${imagePresetNP + imageBreakPoint["xl"]}$`
  };
};
