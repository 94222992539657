import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";

export const usePDPSwatchCarouselData = props => {
  const {
    swatchGroup,
    finishSwatchGroups,
    height = "171px",
    width = "159px"
  } = props;

  const carouselRef = useRef<HTMLDivElement>(null);
  const [selectedSwatch, setSelectedSwatch] = useState<{
    [key: string]: ProductSwatch;
  } | null>(null);
  const [isPaused, setIsPaused] = useState<boolean>(false);
  const [selectedMaterialName, setSelectedMaterialName] =
    useState<string>("fabric");
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [startX, setStartX] = useState<number>(0);
  const [scrollLeft, setScrollLeft] = useState<number>(0);
  const [itemsToShow, setItemsToShow] = useState<number>(10);
  const [SwatchDetail, setSwatchDetail] = useState<ProductSwatch | null>(null);

  const processSwatches = (groups: ProductSwatchGroup[], types: string[]) => {
    const swatches: ProductSwatchGroup[] = [];
    groups?.forEach(group => {
      types.forEach(type => {
        group[type]?.forEach(swatch => {
          swatches.push(swatch);
        });
      });
    });
    return swatches;
  };

  const processedSwatches = useMemo(
    () => ({
      swatches: processSwatches(swatchGroup, [
        "customSwatches",
        "stockedSwatches"
      ]),
      materialName: "fabric"
    }),
    [swatchGroup]
  );

  const processedFinishSwatches = useMemo(() => {
    const swatches = processSwatches(finishSwatchGroups, ["stockedSwatches"]);
    return swatches.length
      ? { swatches, materialName: finishSwatchGroups[0]?.groupMaterial }
      : {};
  }, [finishSwatchGroups]);

  const currentSwatches = useMemo(
    () =>
      selectedMaterialName === "fabric" && processedSwatches?.swatches?.length
        ? processedSwatches
        : processedFinishSwatches,
    [processedSwatches, processedFinishSwatches, selectedMaterialName]
  );

  const { ref: endRef, inView } = useInView({
    threshold: 0.5,
    triggerOnce: false
  });

  useEffect(() => {
    if (
      !processedSwatches?.swatches?.length &&
      processedFinishSwatches?.materialName
    ) {
      setSelectedMaterialName(processedFinishSwatches?.materialName);
    }
  }, [processedSwatches, processedFinishSwatches]);

  useEffect(() => {
    if (inView && currentSwatches?.swatches) {
      setItemsToShow(prev =>
        Math.min(prev + 10, currentSwatches?.swatches?.length)
      );
    }
  }, [inView, currentSwatches?.swatches?.length]);

  useEffect(() => {
    const carousel = carouselRef.current;
    let animationFrame: number;

    const scrollMarquee = () => {
      if (!isDragging && carousel) {
        carousel.scrollLeft += 1;
        if (
          carousel.scrollLeft >= carousel.scrollWidth / 2 &&
          itemsToShow >= currentSwatches?.swatches?.length
        ) {
          carousel.scrollLeft = 0;
        }
      }
      animationFrame = requestAnimationFrame(scrollMarquee);
    };

    if (!isPaused && carousel) {
      animationFrame = requestAnimationFrame(scrollMarquee);
    }

    return () => cancelAnimationFrame(animationFrame);
  }, [isPaused, isDragging, itemsToShow, currentSwatches?.swatches?.length]);

  const handleMouseDown = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    const carousel = carouselRef.current;
    if (carousel) {
      setIsDragging(true);
      setStartX(e.pageX - carousel.offsetLeft);
      setScrollLeft(carousel.scrollLeft);
    }
  }, []);

  const handleMouseMove = useCallback(
    (e: React.MouseEvent) => {
      if (!isDragging) return;
      const carousel = carouselRef.current;
      if (carousel) {
        const walk = e.pageX - carousel.offsetLeft - startX;
        carousel.scrollLeft = scrollLeft - walk;
      }
    },
    [isDragging, startX, scrollLeft]
  );

  const handleMouseUp = useCallback(() => setIsDragging(false), []);

  const handleMouseLeave = useCallback(() => {
    setIsDragging(false);
    setIsPaused(false);
  }, []);

  const handleImageClick = useCallback(
    (swatch: ProductSwatch) => {
      setSelectedSwatch(prev => ({ ...prev, [selectedMaterialName]: swatch }));
      setIsPaused(true);
    },
    [selectedMaterialName]
  );

  const handleShowInfo = useCallback((swatch: ProductSwatch) => {
    setSwatchDetail(swatch);
    setIsPaused(true);
  }, []);

  const handleImageDragStart = useCallback(
    (e: React.DragEvent) => e.preventDefault(),
    []
  );

  const getBorderClass = useCallback(
    (materialName?: string) => {
      return selectedMaterialName === materialName
        ? "border-b border-black"
        : "";
    },
    [selectedMaterialName]
  );

  const currentSelectedSwatch = selectedSwatch?.[selectedMaterialName];

  return {
    height,
    width,
    carouselRef,
    selectedSwatch,
    isPaused,
    selectedMaterialName,
    isDragging,
    startX,
    scrollLeft,
    itemsToShow,
    SwatchDetail,
    currentSelectedSwatch,
    currentSwatches,
    processedSwatches,
    processedFinishSwatches,
    setSelectedSwatch,
    setIsPaused,
    setSelectedMaterialName,
    setIsDragging,
    setStartX,
    setScrollLeft,
    setItemsToShow,
    setSwatchDetail,
    endRef,
    handleMouseDown,
    handleMouseLeave,
    handleMouseMove,
    handleMouseUp,
    handleImageClick,
    handleShowInfo,
    handleImageDragStart,
    getBorderClass
  };
};
