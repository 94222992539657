import React from "react";
import { TailwindTypography } from "@RHCommerceDev/component-tailwind-typography";
import memoize from "utils/memoize";
import { PDPTypographyProps } from "./type";
import { clsx as cn } from "clsx";

const PDPTypography: React.FC<PDPTypographyProps> = ({
  name,
  className,
  component = "p",
  variant = "inherit"
}) => {
  return (
    <TailwindTypography
      id={"component-pdp-product-details_product"}
      data-testid={"component-pdp-product-details_product"}
      variant={variant}
      component={component}
      className={cn("pdp-product-details-typography", className)}
      noWrap
      dangerouslySetInnerHTML={{
        __html: name || ""
      }}
    />
  );
};

export default memoize(PDPTypography);
