import { PriceRange } from "..";

// Filter the type down to the only ones we care about
export type PickProductPriceRangeDisplay = Pick<
  ProductPriceRangeDisplay,
  | "isSale"
  | "salePriceLabel"
  | "salePrices"
  | "listPrices"
  | "listPriceLabel"
  | "overrideLowestSkuListPrice"
  | "overrideLowestSkuSalePrice"
  | "strikePriceLabel"
  | "memberPriceLabel"
  | "overrideLowestSkuMemberPrice"
  | "memberPrices"
  | "showMemberPrice"
  | "priceMessage"
  | "skulowestListPrice"
  | "skulowestMemberPrice"
  | "skulowestSalePrice"
  | "nextGenDrivenOnSale"
  | "nextGenDriven"
  | "originalMemberPrice"
>;

export type PriceType = {
  isStockedFilterActive?: boolean;
  regular?: {
    label?: string;
    amount?: number;
    strikethrough?: boolean;
  };
  params: {
    sale?: string;
  };
  lowestSkuMemberPrice?: {
    amount?: number;
    label?: string;
  };
  lowestSkuRegularPrice?: {
    amount?: number;
    label?: string;
  };
  lowestSkuSalePrice?: {
    amount?: number;
    label?: string;
  };
  member?: {
    label?: string;
    amount?: number;
    strikethrough?: boolean;
    showMemberPrice?: boolean;
  };
  memberPrice?: number;
  nextGenDrivenOnSale?: boolean;
  showSaleMessage?: boolean;
  skuOnSalePercentage?: number;
  inStockPrice?: SkuResponsePriceInfo;
  sale?: any;
  tradePrice?: number;
  tradeSalePrice?: number;
  contractPrice?: number;
  isMetaProduct?: boolean;
};

export const convertProductPriceRangeDisplayToPriceRange = (
  data: PickProductPriceRangeDisplay,
  userType: string = "",
  isActiveSale?: boolean,
  onSale?: boolean
): PriceRange => {
  const isFinalSale =
    isActiveSale && data?.nextGenDrivenOnSale
      ? false
      : (data?.salePriceLabel || "").toLowerCase().includes("final sale") &&
        data?.nextGenDrivenOnSale;

  const isSaleRegularSame = !(data?.salePrices?.[0]! >= data?.listPrices?.[0]!);

  const isSale =
    (isActiveSale && data?.nextGenDrivenOnSale) || data?.isSale || onSale
      ? true
      : (!!data?.isSale ||
          (!!data?.salePriceLabel && data?.salePriceLabel !== "false")) &&
        isSaleRegularSame &&
        data?.nextGenDrivenOnSale;

  const priceMessage = isActiveSale ? "" : data?.priceMessage;

  return {
    regular: {
      label: data?.listPriceLabel || "Regular",
      amount: data?.listPrices?.[0],
      strikethrough: isSale && !data?.priceMessage // strike through looks funny when the sale/final-sale price is hidden KRAK-2701
    },
    ...(!isFinalSale &&
    isSale &&
    /** KRAK-2701 if PriceMessage is present; don't show sale prices. This is what they wanted. */
    !priceMessage
      ? {
          sale: {
            amount: data?.salePrices?.[0],
            label: data?.salePriceLabel || "Sale",
            strikethrough: data?.strikePriceLabel === data?.salePriceLabel
          }
        }
      : null),
    ...(isFinalSale &&
    /** KRAK-2701 if PriceMessage is present; don't show sale prices. This is what they wanted. */
    !data?.priceMessage
      ? {
          finalSale: {
            label: "Final Sale",
            amount: data?.salePrices?.[0],
            strikethrough: data?.strikePriceLabel === data?.salePriceLabel
          }
        }
      : null),
    ...(userType?.toUpperCase() !== "TRADE" &&
    userType?.toUpperCase() !== "CONTRACT"
      ? {
          member: {
            label: data?.memberPriceLabel || "Member",
            amount: data?.memberPrices?.[0],
            strikethrough: data?.strikePriceLabel === data?.memberPriceLabel,
            showMemberPrice: data?.showMemberPrice,
            originalMemberPrice: data?.originalMemberPrice
          }
        }
      : null),
    ...(userType?.toUpperCase() === "TRADE"
      ? {
          trade: {
            label: "Trade",
            amount: data?.memberPrices?.[0],
            strikethrough: data?.strikePriceLabel === data?.memberPriceLabel
          }
        }
      : null),
    ...(userType?.toUpperCase() === "CONTRACT"
      ? {
          contract: {
            label: "Contract",
            amount: data?.memberPrices?.[0],
            strikethrough: data?.strikePriceLabel === data?.memberPriceLabel
          }
        }
      : null),
    ...(data?.skulowestMemberPrice
      ? {
          lowestSkuMemberPrice: {
            amount: data?.skulowestMemberPrice,
            label: "lowestSkuMemberPrice"
          }
        }
      : null),
    ...(data?.skulowestListPrice
      ? {
          lowestSkuRegularPrice: {
            amount: data?.skulowestListPrice,
            label: "lowestSkuRegularPrice"
          }
        }
      : null),
    ...(data?.skulowestSalePrice
      ? {
          lowestSkuSalePrice: {
            amount: data?.skulowestSalePrice,
            label: "lowestSkuSalePrice"
          }
        }
      : null)
  };
};

export const calculatePrices = (priceRange: PriceType) => {
  const getSalePrice = (isSale: boolean) => {
    if (priceRange?.isStockedFilterActive && !isSale) {
      return priceRange?.inStockPrice?.listPrice;
    }
    if (isSale) {
      if (priceRange?.isStockedFilterActive) {
        return priceRange?.inStockPrice?.salePrice;
      }

      const isAllProductOnSale =
        priceRange?.skuOnSalePercentage === 100 &&
        !priceRange?.showSaleMessage &&
        !priceRange?.params?.sale &&
        priceRange?.nextGenDrivenOnSale;

      if (isAllProductOnSale) {
        return (
          priceRange?.lowestSkuSalePrice?.amount || priceRange?.sale?.amount
        );
      }

      return priceRange?.sale?.amount;
    }

    return priceRange?.regular?.amount;
  };

  const getMemberPrice = (isSale: boolean) => {
    if (isSale && !priceRange?.isStockedFilterActive) {
      return (
        priceRange?.lowestSkuMemberPrice?.amount || priceRange?.member?.amount
      );
    }
    if (priceRange?.isStockedFilterActive) {
      return priceRange?.inStockPrice?.memberPrice;
    }
    if (priceRange?.isMetaProduct) {
      return priceRange?.member?.amount;
    }
    return priceRange?.memberPrice || priceRange?.member?.amount;
  };

  const getTradePrice = () => {
    if (priceRange?.isStockedFilterActive) {
      return priceRange?.inStockPrice?.tradePrice;
    }
    return priceRange?.tradePrice;
  };

  const getContractPrice = () => {
    if (priceRange?.isStockedFilterActive) {
      return priceRange?.inStockPrice?.contractPrice;
    }
    return priceRange.contractPrice;
  };

  const regularPriceValue = getSalePrice(false);
  const salePriceValue = getSalePrice(true);
  const memberPriceValue = getMemberPrice(false);
  const memberSalePriceValue = getMemberPrice(true);
  const tradePrice = getTradePrice();
  const contractPrice = getContractPrice();
  const tradeSalePrice = memberSalePriceValue || tradePrice;
  const contractSalePrice = memberSalePriceValue || contractPrice;

  return {
    regularPriceValue,
    salePriceValue,
    memberPriceValue,
    memberSalePriceValue,
    tradePrice,
    contractPrice,
    tradeSalePrice,
    contractSalePrice
  };
};

interface UserTypePrice {
  tradePrice?: number;
  contractPrice?: number;
  memberPrice?: number;
}

interface UserTypeLabel {
  trade: string;
  contract: string;
  member: string;
}

export const getUserTypePrice = (userType: string, price: UserTypePrice) => {
  switch (userType?.toLowerCase()) {
    case "trade":
      return price?.tradePrice;

    case "contract":
      return price?.contractPrice;

    default:
      return price?.memberPrice;
  }
};

export const getUserTypeLabel = (userType: string, label: UserTypeLabel) => {
  switch (userType?.toLowerCase()) {
    case "trade":
      return label?.trade;

    case "contract":
      return label?.contract;

    default:
      return label?.member;
  }
};
