import AEMWrapper from "aem/ComponentWrapper";
import loadable from "@loadable/component";
import React from "react";

export default AEMWrapper(
  {
    type: "rh/components/content/geoexperienceloader",
    config: {
      emptyLabel: "GeoExperienceLoader",
      isEmpty: _ => false
    }
  },
  loadable(
    () =>
      import(/* webpackChunkName: "component-rh-geoexperienceloader" */ "."),
    {
      fallback: <></>
    }
  )
);
