import React, { FC } from "react";
import { useTheme, DialogContent } from "utils/material-ui-core";
import { SwipeableDrawerProps } from "@material-ui/core/SwipeableDrawer";
import RHDialogTitle from "component-rh-dialog-title";
import Drawer from "component-drawer";
import { useEnv } from "hooks/useEnv";
import TailwindDrawer from "@RHCommerceDev/component-tailwind-drawer";
import yn from "yn";

export interface RHMenuDrawerProps
  extends Omit<SwipeableDrawerProps, "onOpen" | "onClose"> {
  title?: string;
  closeIconClassnames?: string;
  closeButtonRootClassnames?: string;
  titlePaddingClassNames?: string;
  onClose?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
  onBack?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
  contentStyle?: { [key: string]: any };
  subTitle?: string;
  isDark?: boolean;
}

export const RHMenuDrawer: FC<RHMenuDrawerProps> = ({
  title,
  onClose,
  onBack,
  children,
  contentStyle,
  isDark = false,
  id,
  ...rest
}) => {
  const theme = useTheme();
  const env = useEnv();
  const FEATURE_TAILWIND_COMPONENTS = yn(env.FEATURE_TAILWIND_COMPONENTS);
  const DrawerComponent = FEATURE_TAILWIND_COMPONENTS ? TailwindDrawer : Drawer;
  return (
    <DrawerComponent
      id={id || "component-rh-menu-drawer"}
      PaperProps={{
        className: "absolute"
      }}
      {...rest}
      onClose={onClose}
      ModalProps={{
        style: {
          zIndex: theme.zIndex.appBar + 1
        }
      }}
    >
      <RHDialogTitle
        title={title}
        onBack={onBack}
        titlePaddingClassNames={rest.titlePaddingClassNames}
        closeButtonRootClassnames={rest.closeButtonRootClassnames}
        onClose={onClose}
        subTitle={rest?.subTitle}
        solidWhite={true}
        anchor={rest.anchor}
        closeIconClassnames={rest.closeIconClassnames}
        isDark={isDark}
        style={{
          backgroundColor: theme.palette.background.default
        }}
      />
      <DialogContent
        className={"!pt-0"}
        style={{
          ...contentStyle
        }}
      >
        {children}
      </DialogContent>
    </DrawerComponent>
  );
};

RHMenuDrawer.defaultProps = {
  anchor: "left",
  open: false
};

export default RHMenuDrawer;
