import React, { FC } from "react";
import LiveTextLayer from "component-promo/MobileLayers/LiveTextLayer";
import ButtonLayer from "component-promo/MobileLayers/ButtonLayer";
import { useTheme } from "utils/material-ui-core";

export interface MobilePromoLayerComponentMap {
  [K: string]: FC<MobilePromoLayerProps>;
}

export interface MobilePromoLayerProps {
  layer: PromoDisplayLayer;
}

export const MobilePromoLayer: FC<MobilePromoLayerProps> = props => {
  const { layer } = props;
  const theme = useTheme();

  const MobilePromoLayerComponent = (
    {
      liveText: LiveTextLayer,
      button: ButtonLayer
    } as MobilePromoLayerComponentMap
  )[layer.layerType];

  if (!MobilePromoLayerComponent) {
    return null;
  }

  let left = "";
  let right = "";
  let top = "";
  let bottom = "";
  let transform = "";

  if (layer.dynamicText.horizontalAlign === "left") {
    left = "0";
  } else if (layer.dynamicText.horizontalAlign === "right") {
    right = "0";
  } else if (layer.dynamicText.horizontalAlign === "center") {
    left = "50%";
    transform = "translate(-50%, 0)";
  }

  if (layer.dynamicText.verticalAlign === "top") {
    top = "0";
  } else if (layer.dynamicText.verticalAlign === "bottom") {
    bottom = "0";
  } else if (layer.dynamicText.verticalAlign === "center") {
    top = "50%";
    transform = "translate(0, -50%)";
  }

  if (
    layer.dynamicText.horizontalAlign === "center" &&
    layer.dynamicText.verticalAlign === "center"
  ) {
    left = "50%";
    top = "50%";
    transform = "translate(-50%, -50%)";
  }

  return (
    <div
      style={{
        position: "absolute",
        zIndex: theme.zIndex.mobileStepper,
        width: layer.dynamicText.width || "",
        top: layer.dynamicText.topCoordinate || top,
        bottom: layer.dynamicText.bottomCoordinate || bottom,
        left: layer.dynamicText.leftCoordinate || left,
        right: layer.dynamicText.rightCoordinate || right,
        transform: transform,
        color: (layer.dynamicText.classes || "").includes("black-outline")
          ? theme.palette.common.black
          : theme.palette.common.white,
        borderColor: (layer.dynamicText.classes || "").includes("black-outline")
          ? theme.palette.common.black
          : theme.palette.common.white
      }}
    >
      <MobilePromoLayerComponent {...props} />
    </div>
  );
};

MobilePromoLayer.defaultProps = {};

export default MobilePromoLayer;
