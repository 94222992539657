import AEMWrapper from "aem/ComponentWrapper";
import loadable from "@loadable/component";
import React, { FC } from "react";
import { splitFont } from "aem/ui/authoring/rh/FontPicker2";
import { RHReferenceableComponentProps } from "types";
import { RHAnimatableComponentProps } from "utils/animation/types";
import { TextContent } from "component-text";

interface RHMultifieldMessage {
  text?: string;
}

export type AEMAlertProps = React.HTMLAttributes<HTMLDivElement> &
  RHAnimatableComponentProps &
  RHReferenceableComponentProps & {
    textColorXS?: string;
    textColorSM?: string;
    alignmentXS?: string;
    alignmentSM?: string;
    lineHeightXS?: string;
    lineHeightSM?: string;
    styleXS?: string;
    styleSM?: string;
    transformXS?: string;
    transformSM?: string;
    fontXS?: string;
    fontSM?: string;
    spacingXS?: string;
    spacingSM?: string;
    sizeXS?: string;
    sizeSM?: string;
    hideXS?: boolean;
    hideSM?: boolean;
    textXS?: TextContent | Array<RHMultifieldMessage>;
    textSM?: TextContent | Array<RHMultifieldMessage>;
    closeIconColor?: string;
    backgroundColor?: string;
  };

const RHAlert = loadable(
  () => import(/* webpackChunkName: "component-rh-alert" */ "."),
  {
    fallback: <></>
  }
);

export const AEMAlert: FC<AEMAlertProps> = props => {
  const [fontXS, styleXS] = splitFont(props?.fontXS || "");
  const [fontSM, styleSM] = splitFont(props?.fontSM || "");
  return <RHAlert {...{ ...props }} />;
};
export default AEMWrapper(
  {
    type: "rh/components/content/alert",
    config: {
      emptyLabel: "Alert (Eyebrow) - Shows banner at the top of page",

      isEmpty: function (props: any) {
        return !props || !props?.textXS || props?.textXS?.trim
          ? props?.textXS?.trim()?.length < 1
          : true;
      }
    }
  },
  AEMAlert
);
