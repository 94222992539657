import { WrapperOptions } from "aem/ComponentWrapper";

const meta: WrapperOptions = {
  group: "Animation",
  title: "Animation Timelines",
  type: "rh/components/animation/timelines",
  config: {
    isEmpty: () => false
  }
};

export default meta;
