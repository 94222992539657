import React from "react";
import { formatCurrencyPrice } from "@RHCommerceDev/component-rh-price-range-display/helpers";
import { PriceRangeProps } from "../type";
import memoize from "@RHCommerceDev/utils/memoize";
import { usePageContent } from "@RHCommerceDev/custom-providers/LocationProvider";

const PriceRange: React.FC<PriceRangeProps> = ({
  memberPrice,
  regularPrice,
  className
}) => {
  const { pageContent } = usePageContent();

  const commonStyle =
    "text-lg text-black capitalize font-primary-rhthin leading-[100%]";
  return (
    <div
      id={"component-pdp-cta_price_range"}
      data-testid={"component-pdp-cta_price_range"}
      className={`flex justify-center items-end ${className}`}
    >
      <span
        className={`!text-[22px] ${commonStyle}`}
        aria-label={`${pageContent?.Member_price} ${formatCurrencyPrice(
          memberPrice
        )}`}
      >
        {formatCurrencyPrice(memberPrice)} <span>{pageContent?.MEMBER}</span>
      </span>
      <span className={commonStyle}>&nbsp;/&nbsp;</span>
      <span
        className={commonStyle}
        aria-label={`${pageContent?.Regular_price} ${formatCurrencyPrice(
          regularPrice
        )}`}
      >
        {formatCurrencyPrice(regularPrice)} <span>{pageContent?.REGULAR} </span>
      </span>
    </div>
  );
};

export default memoize(PriceRange);
