import loadable from "@loadable/component";
import AEMWrapper from "aem/ComponentWrapper";
import React, { memo } from "react";
import meta from "./aem-meta";

export const FallbackComponent = props => {
  return (
    <div
      style={{
        height: props.placeholderHeight || "1000px",
        background: "black"
      }}
    ></div>
  );
};

const Component = loadable(
  () =>
    import(
      /* webpackChunkName: "component-media-player/aem-component" */ "./index"
    ),
  {
    fallback: <FallbackComponent />
    //we're going to test this with SSR now with the new configuration
    //ssr: false /* this component throws  a lot of errors on server side  */
  }
);

export default memo(AEMWrapper(meta, Component));
