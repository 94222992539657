/**
 * Removes all react-slick built-in classes.
 */
export const sanitizeArrowClassName = (
  className: any,
  allowDisabledClass = false
) => {
  if (typeof className !== "string") {
    return "";
  }
  const regex = allowDisabledClass
    ? /\bslick(?:-((?!disabled))\w+\b)+/g
    : /\bslick(?:-\w+\b)+/g;

  return className.replace(regex, "");
};
