import { PropTypes } from "aem/ComponentWrapper";

export default {
  title: "Wistia",
  type: "rh/components/content/wistia",
  isContainer: false,
  config: {
    emptyLabel: "Wistia",
    isEmpty: (props: PropTypes) => !!!props?.data?.dynamicText?.wistia
  }
};
